<script setup>
     import { onMounted, onUnmounted } from 'vue';
     import { useRouter,useRoute } from 'vue-router'
     import { getEntrepreneurDoorDtoPerson  } from '@/api/applyList.js'
     import { deleteEntrepreneurDoorDtoPerson, deletePersonalPartnerInfo, submit, downloadByFileId } from '@/api/index.js';
     import { ElMessageBox, ElMessage } from "element-plus";
     import axios from "axios";
     import {
        idTypeList,
        getDictList,
        politicaltList,
        getPoliticaltList,
        personCateList,
        getPersonCateList,
        maritalStatus,
        getMaritalStatusList,
        getEntreRelationList,
        entrepreneurList,
        getEntrepreneurList,
        guaranWayList,
        getGuaranWayList,
        categoryList,
        geCategoryList,
        guarantorsList,
        getGuarantorsList1,
        getGuarantorsList,
        bankList,
        getBankList,
        getBankList1,
        entreRelationList,
        deptList,
        getDeptList
    } from '@/api/certificateList.js'

    import ImgOne from "@/assets/stepTwo/id-1.png";
    import ImgTwo from "@/assets/stepTwo/id-2.png";
    import { uploadImgUrlPost, headers } from "@/api/upload.js";
    import { getToken } from "@/utils/auth";
    const uploadHeaders = {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "multipart/form-data",
    };

     const router = useRouter()
     const route = useRoute()
     const loading = ref(true);

     const currentidType = ref('') // 证件类型
     const currentPoliticalt = ref('') // 政治面貌
     const currentPersonCate = ref('') //  创业担保贷款人员类别
     const currentEntrepreneur = ref('') //创业扶持人员类别
     const currentguaranWay = ref('') //担保增信方式
     const currentguarantors = ref('') // 担保机构
     const curentbank = ref('') // 贷款银行
     const sopseIdType = ref('') // 配偶证件类型
     const currentEntrepreneurRelation = ref('') // 与借款人关系
     const currentResidenceAddress = ref('') // 户籍地址
     const currentPermanentAddress = ref('') // 常住地址
     const currentOperatingAddress = ref('') // 经营地址
     const documentClassArr = ref([]);

    // 获取详情--个人
    const detail = ref({})
    const getDetailPersonall = async () => {
        const id = route.query.id;
        try {
            const res = await getEntrepreneurDoorDtoPerson(id)
            if (res.code === 200) {
                detail.value = res.data

                //2024-06-20 所有图片入口已经改成由通过id调用后台接口获取
              if (detail.value.idNumberUrl) {
                const response = await downloadByFileId(detail.value.idNumberUrl);
                detail.value.idNumberUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.idNumberReverseUrl) {
                const response = await downloadByFileId(detail.value.idNumberReverseUrl);
                detail.value.idNumberReverseUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.businessUrl) {
                const response = await downloadByFileId(detail.value.businessUrl);
                detail.value.businessUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.guaranteeCertificateUrl) {
                const response = await downloadByFileId(detail.value.guaranteeCertificateUrl);
                detail.value.guaranteeCertificateUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.householdPhoto) {
                const response = await downloadByFileId(detail.value.householdPhoto);
                detail.value.householdPhotoRead = URL.createObjectURL(response);
              }
              if (detail.value.spouseIdNumberUrl) {
                const response = await downloadByFileId(detail.value.spouseIdNumberUrl);
                detail.value.spouseIdNumberUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.spouseIdNumberReverseUrl) {
                const response = await downloadByFileId(detail.value.spouseIdNumberReverseUrl);
                detail.value.spouseIdNumberReverseUrlRead = URL.createObjectURL(response);
              }
              if (detail.value.marriageCertificateUrl) {
                const response = await downloadByFileId(detail.value.marriageCertificateUrl);
                detail.value.marriageCertificateUrlRead = URL.createObjectURL(response);
              }
              //员工需要遍历
              if (detail.value.employees) {
                detail.value.employees.forEach(async item => {
                  if (item.idNumberUrl) {
                    const response = await downloadByFileId(item.idNumberUrl);
                    item.idNumberUrlRead = URL.createObjectURL(response);
                  }
                  if (item.idNumberOpposite) {
                    const response = await downloadByFileId(item.idNumberOpposite);
                    item.idNumberOppositeRead = URL.createObjectURL(response);
                  }
                })
              }

                currentidType.value = idTypeList.value.filter(item =>{ return item.dictValue == detail.value.idType?item.dictLabel:''})
                sopseIdType.value = idTypeList.value.filter(item =>{ return item.dictValue == detail.value.spouseIdType?item.dictLabel:''})
                currentPoliticalt.value = politicaltList.value.filter(item =>{ return item.dictValue == detail.value.politicalOutlook?item.dictLabel:''})
                currentPersonCate.value = personCateList.value.filter(item =>{ return item.dictValue == detail.value.loanPersonCategory?item.dictLabel:''})
                currentEntrepreneur.value =  entrepreneurList.value.filter(item =>{ return item.dictValue == detail.value.entrepreneurCategory?item.dictLabel:''})
                currentguaranWay.value = guaranWayList.value.filter(item =>{ return item.dictValue == detail.value.guaranteeWay?item.dictLabel:''})
                currentEntrepreneurRelation.value = entreRelationList.value.filter(item =>{ return item.dictValue == detail.value.entrepreneurRelation?item.dictLabel:''})

                currentResidenceAddress.value = getAddressForperson(detail.value.residenceAddress) // 户籍地址
                currentPermanentAddress.value = getAddressForperson(detail.value.permanentAddress) // 常住地址
                currentOperatingAddress.value = getAddressForperson(detail.value.operatingAddress) // 经营地址
                if (detail.value.politicalOutlook) {
                    detail.value.politicalOutlook = (detail.value.politicalOutlook).toString();
                };
                if (detail.value.maritalStatus) {
                    detail.value.maritalStatus = (detail.value.maritalStatus).toString();
                }
                if (detail.value.entrepreneurRelation) {
                    detail.value.entrepreneurRelation = (detail.value.entrepreneurRelation).toString();
                }
                if (detail.value.entrepreneurCategory) {
                    detail.value.entrepreneurCategory = (detail.value.entrepreneurCategory).toString();
                }
                if (detail.value.guaranteeWay) {
                    detail.value.guaranteeWay = (detail.value.guaranteeWay).toString();
                }
                let documentClass = detail.value.documentClass;
                if (!!documentClass) {
                    detail.value.documentClass = documentClass.split(',');
                    detail.value.documentClass.forEach(async item => {
                      const response = await downloadByFileId(item);
                      documentClassArr.value.push(URL.createObjectURL(response));
                    })
                }
                let residenceAddress = detail.value.residenceAddress;
                if (residenceAddress != null) {
                    residenceAddress = detail.value.residenceAddress.split(',');
                    getGuarantorsList1(residenceAddress[2]).then(() => {
                        for(let i in guarantorsList.value){
                            if(i == res.data.guaranteeOrganizationName){
                                currentguarantors.value = guarantorsList.value[i]
                            }
                        }
                    });
                    getBankList1(residenceAddress[2]).then(() => {
                        for(let item in bankList.value){
                            if(item == res.data.bankId){
                                curentbank.value = bankList.value[item]
                            }
                        }
                    });
                };
                localStorage.setItem("APPLY_DETAIL", JSON.stringify(detail.value));
                loading.value = false;
            }
        } catch (e) {
            console.log(e, 'this is error~')
        }
    }

    // 获取地址
    const getAddressForperson = (idList) =>{
        if (!idList) return;
        idList = idList.split(',')
        let dList = deptList.value
        let address = '';

        for(let i=0;i<dList.length;i++){
            if(idList[0] == dList[i].id){
                address += dList[i].label

                if(dList[i].children){
                    let c1 = dList[i].children

                    for(let i =0;i<c1.length;i++){
                        if(idList[1] == c1[i].id){
                            address += c1[i].label
                            if(c1[i].children){
                                let c2 = c1[i].children
                                for(let i=0;i<c2.length;i++){
                                    if(idList[2] == c2[i].id){
                                        address += c2[i].label
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return address
    }

    const toPartnerLoanDetail = (itm) => {
        router.push({
            path: './pDetail',
            query: {
                id: itm.id,
                type: route.query.type,
                isPartner: 1
            }
        })
    }

    const canEdit = ref(false);
    const isEditing = ref(false);

    const toEdit = () => {
    isEditing.value = !isEditing.value;
    };

    const toCancel = () => {
        ElMessageBox.confirm("当前修改的内容将不会保存，确认取消吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then((ret) => {
        if (ret == "confirm") {
            isEditing.value = false;
            let APPLY_DETAIL = JSON.parse(localStorage.getItem("APPLY_DETAIL"));
            detail.value = APPLY_DETAIL;
        }
        }).catch((err) => {
        console.log("err:", err);
        });
    };

    const changeMaritalStatus = (e) => {
        if (e == 0) {
            detail.value.spouseSex = null;
            detail.value.spouseName = null;
            detail.value.spouseIdType = null;
            detail.value.spouseIdNumber = null;
            detail.value.withSpouseIncomplete = null;
        }
    };

    /** 添加员工信息 */
    const addEmployee = () => {
        detail.value.employees.push({
            employeeName:null,
            employeeIdNumber:null,
            idType:null,
            idNumberUrl:null,
            idNumberUrlRead:null,
            idNumberOpposite:null,
            idNumberOppositeRead:null
        });
    };

    /** 添加合伙人信息 */
    const addPartner = () => {
        detail.value.partnerDtos.push({
            partnerName: null,
            idNumber: null,
            partnerPhoneNumber: null,
            partnerCategory:null,
            partnerAddress:null,
            idType:null,
            partnerLoanAmount: null,
            partnerLoanTerm: null,
        });
    };

    const delEmployee = (item) => {
        ElMessageBox.confirm("确认删除该员工信息吗?一经删除，该条数据将无法恢复，请谨慎操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then((ret) => {
            if (ret == "confirm") {
                detail.value.employees.map((v, i) => {
                    if (v.id == item.id) {
                        if (item.id) {
                        deleteEntrepreneurDoorDtoPerson(item.id).then(res => {
                            if (res.code == '200') {
                            ElMessage.success("员工信息删除成功!");
                            detail.value.employees.splice(i, 1);
                            getDetailPersonall();
                            };
                        });
                        } else {
                        ElMessage.success("员工信息删除成功!");
                        detail.value.employees.splice(i, 1);
                        }
                    };
                });
            }
        }).catch((err) => {
            console.log("err:", err);
        });
    };

    const delPartner = (item) => {
        ElMessageBox.confirm("确认删除该合伙人信息吗?一经删除，该条数据将无法恢复，请谨慎操作!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then((ret) => {
            if (ret == "confirm") {
                detail.value.partnerDtos.map((v, i) => {
                    if (v.id == item.id) {
                        if (item.id) {
                            deletePersonalPartnerInfo(item.id).then(res => {
                                if (res.code == '200') {
                                    ElMessage.success("合伙人信息删除成功!");
                                    detail.value.partnerDtos.splice(i, 1);
                                    getDetailPersonall();
                                };
                            });
                        } else {
                            ElMessage.success("合伙人信息删除成功!");
                            detail.value.partnerDtos.splice(i, 1);
                        }
                    };
                });
            }
        }).catch((err) => {
            console.log("err:", err);
        });
    };

    // 图片上传
    const changeImge = (res, f, item) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        item.idNumberUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                item.idNumberUrl = res.data.msg;
            }
        });
    };

    // 图片上传 身份证反面
    const changeImgeRever = (res, f, item) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        item.idNumberOppositeRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                item.idNumberOpposite = res.data.msg;
            }
        });
    };

    /** 营业执照上传 */
    const changeBusiImge = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.businessUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.businessUrl = res.data.msg;
            }
        });
    };

    /** 本人身份证正面上传 */
    const changeImgeIdm = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.idNumberUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.idNumberUrl = res.data.msg;
            }
        });
    };

    /** 本人身份证反面上传 */
    const changeImgeOpposite = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.idNumberReverseUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.idNumberReverseUrl = res.data.msg;
            }
        });
    };

    /** 担保证明上传 */
    const changeGuaImge = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.guaranteeCertificateUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.guaranteeCertificateUrl = res.data.msg;
            }
        });
    }

     /** 本人户口本上传 */
     const changeHouseImge = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.householdPhotoRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.householdPhoto = res.data.msg;
            }
        });
    }

    /** 配偶身份证正面上传 */
    const changeSpouseImg = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.spouseIdNumberUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.spouseIdNumberUrl = res.data.msg;
            }
        });
    }

    /** 配偶身份证反面上传 */
    const changeSpouseResImg = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.spouseIdNumberReverseUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.spouseIdNumberReverseUrl = res.data.url;
            }
        });
    }

    /** 配偶身份证反面上传 */
    const changeMarryImg = (res, f) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        detail.value.marriageCertificateUrlRead = aimg
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.marriageCertificateUrl = res.data.msg;
            }
        });
    };

    /** 人员类别相关证件照片照片上传 */
    const changeImgePerType = (res) => {
        let formData = new FormData();
        let aimg = URL.createObjectURL(res.raw)
        documentClassArr.value.push(aimg)
        formData.append("file", res.raw);
        axios.post(uploadImgUrlPost, formData, { headers: uploadHeaders }).then((res) => {
            if (res.data.code == 200) {
                detail.value.documentClass.push(res.data.msg);
                // documentClassArr.value.push(res.data.url);
            }
        });
    };

    /** 人员类别相关证件照片照片删除 */
    const delImg = (index) => {
        documentClassArr.value.splice(index, 1)
        detail.value.documentClass.splice(index, 1);
    };

    const submitFlag = ref(false);
    const validateData = (params) => {
        if (!params.name.trim()) {
            ElMessage.error("请输入姓名");
            return;
        };
        let idNumbertest = /^([a-zA-Z0-9-]{1,18})$/;
        if (!params.idNumber.trim()) {
            ElMessage.error("请输入证件号码");
            return;
        };
        if (!idNumbertest.test(params.idNumber)) {
            ElMessage.error("证件号码格式不正确");
            return;
        }
        let telTest = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
        if (!params.phone.trim()) {
            ElMessage.error("请输入联系电话");
            return;
        };
        if (!telTest.test(params.phone)) {
            ElMessage.error("联系电话格式不正确");
            return;
        }
        if (!params.emergencyContact.trim()) {
            ElMessage.error("请输入紧急联系人姓名");
            return;
        };
        if (!params.emergencyContactPhone.trim()) {
            ElMessage.error("请输入紧急联系人电话");
            return;
        };
        if (!telTest.test(params.emergencyContactPhone)) {
            ElMessage.error("紧急联系人电话格式不正确");
            return;
        }
        if (!params.unitName.trim()) {
            ElMessage.error("请输入营业执照全称");
            return;
        };
        if (!params.socialCode.trim()) {
            ElMessage.error("请输入统一社会信用代码");
            return;
        };
        let codeTest = /^([a-zA-Z0-9]{18})$/;
        if (!codeTest.test(params.socialCode)) {
            ElMessage.error("统一社会信用代码格式不正确");
            return;
        };
        if (!params.businessScope.trim()) {
            ElMessage.error("请输入经营范围及项目");
            return;
        };
        if (!params.loanAmount) {
            ElMessage.error("请输入申请贷款金额");
            return;
        };
        if (params.loanAmount > 30) {
            ElMessage.error("申请贷款金额不能大于30万元");
            return;
        }
        if (!params.loanTerm) {
            ElMessage.error("请输入贷款期限");
            return;
        };
        if (params.loanTerm > 36 || params.loanTerm < 1) {
            ElMessage.error("贷款期限在1-36个月之间");
            return;
        };
        let employeeFlag = true;
        if (params?.employees?.length > 0) {
            let arr = params.employees;
            arr.map((v, i) => {
                if (!v.employeeName || !v.employeeIdNumber || !v.idType) {
                    ElMessage.error("请完善员工信息");
                    employeeFlag = false;
                    return;
                };
                if (!idNumbertest.test(v.employeeIdNumber)) {
                    ElMessage.error("员工证件号码格式有误，请核查");
                    employeeFlag = false;
                    return;
                }
                if (v.employeeIdNumber == params.idNumber) {
                    ElMessage.error('员工身份证号码不能与主体身份证号码相同，请检查');
                    employeeFlag = false;
                    return;
                }
                for (let j = 0; j < arr.length; j++) {
                    if (v.employeeIdNumber == arr[j].employeeIdNumber && i != j) {
                        ElMessage.error('已有相同的员工身份证号码，请检查');
                        employeeFlag = false;
                        return;
                    };
                };
            });
        };
        if (!employeeFlag) return;
        let partnerFlag = true;
        if (params?.partnerDtos?.length > 0) {
            let arr = params?.partnerDtos;
            arr.map((v, i) => {
                if (!v.partnerName || !v.idNumber || !v.partnerPhoneNumber || !v.partnerCategory || !v.partnerAddress ||
                    !v.idType || !v.partnerLoanAmount || !v.partnerLoanTerm) {
                    ElMessage.error("请完善合伙人信息");
                    partnerFlag = false;
                    return;
                };
                if (!idNumbertest.test(v.idNumber)) {
                    ElMessage.error("合伙人证件号码格式有误，请核查");
                    partnerFlag = false;
                    return;
                };
                if (v.idNumber == params.idNumber) {
                    ElMessage.error('合伙人身份证不能与主体身份证相同，请核查');
                    partnerFlag = false;
                    return;
                };
                for (let j = 0; j < arr.length; j++) {
                    if (v.idNumber == arr[j].idNumber && i != j) {
                        console.log(i, j);
                        ElMessage.error('已有相同的合伙人身份证号，请核查');
                        partnerFlag = false;
                        return;
                    };
                };
                if (v.partnerLoanAmount > 30) {
                    ElMessage.error('合伙人申请贷款金额不能大于30万元，请核查');
                    return;
                };
                if (!telTest.test(v.partnerPhoneNumber)) {
                    ElMessage.error('合伙人电话格式有误，请核查');
                    return;
                };
                if (v.partnerLoanTerm > 36 || v.partnerLoanTerm < 1) {
                    ElMessage.error('合伙人贷款期限在1-36个月之间，请核查');
                    return;
                }
            })
        }
        let urlFlag = true;
        if (params.maritalStatus == 1 ) {
            console.log(params.documentClass);
            if (!params.businessUrl || !params.idNumberUrl || !params.idNumberReverseUrl ||
            !params.guaranteeCertificateUrl || !params.householdPhoto || !params.marriageCertificateUrl ||
            !params.spouseIdNumberUrl || !params.spouseIdNumberReverseUrl) {
                ElMessage.error("相关证件材料有遗漏，请核查");
                urlFlag = false;
            }
        } else {
            if (!params.businessUrl || !params.idNumberUrl || !params.idNumberReverseUrl ||
            !params.guaranteeCertificateUrl || !params.householdPhoto) {
                ElMessage.error("相关证件材料有遗漏，请核查");
                urlFlag = false;
            }
        }
        if (!urlFlag) return;
        submitFlag.value = true;
    };

    const submitFun = () => {
        let params = detail.value;
        if (params.map) {
            delete params.map;
        }
        validateData(params);
        if (!submitFlag.value) return;
        if (!!params.documentClass) {
            params.documentClass = params.documentClass.join();
        }
        submit(params).then(res => {
            if (res.code == '200') {
                ElMessage.success('修改成功');
                router.go(-1);
            } else {
                ElMessage.error(res.msg);
                isEditing.value = false;
                getDetailPersonall();
            }
        })
    };

    onMounted(() => {
        getDictList()
        getPoliticaltList()
        getPersonCateList()
        getMaritalStatusList()
        getEntreRelationList()
        getEntrepreneurList()
        getGuaranWayList()
        getBankList()
        getGuarantorsList()
        getDeptList()
        geCategoryList()

        if(route.query.id && route.query.type){
            getDetailPersonall()
        }
        if (route.query.canEdit == "1") {
            canEdit.value = true;
        }
    });

    onUnmounted(() => {
        localStorage.removeItem("APPLY_DETAIL");
    });

</script>
<template>
    <div class="layout">
        <div class="banner"><img src="/src/assets/personalCenter/banner2.png" /></div>

        <div class="all">
            <div class="position">您当前的位置：<label @click="router.push('/')">首页</label> / <label @click="router.push('/baseInfo')">个人中心</label> / <i @click="router.push('/applyList')">我的申请</i> / <i> 贷款申请数据</i></div>

            <div class="cont" v-loading="loading">
                <h2 class="page-title">
                    <span></span>创业人员基本信息
                    <el-button
                        v-if="canEdit && !isEditing"
                        class="btn-edit"
                        type="primary"
                        @click="toEdit"
                        >修改</el-button
                    >
                </h2>
                <table>
                    <tr>
                        <td width="25%">姓名</td>
                        <td width="25%">
                            <el-input
                                v-if="isEditing"
                                v-model="detail.name"
                                placeholder="请输入姓名"
                            ></el-input>
                            <div v-else>{{detail.name}}</div>
                        </td>
                        <td width="25%">性别</td>
                        <td width="25%">
                            <el-select
                                v-if="isEditing"
                                v-model="detail.sex"
                                placeholder="请选择"
                                >
                                <el-option label="男" :value="0" />
                                <el-option label="女" :value="1" />
                            </el-select>
                            <div v-else>{{ detail.sex == 0 ? '男' : '女' }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>证件类型</td>
                        <td>
                            <el-select
                                v-if="isEditing"
                                v-model="detail.idType"
                                placeholder="请选择证件类型"
                                >
                                <el-option
                                    v-for="item in idTypeList"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                    :key="item.id"
                                />
                            </el-select>
                            <div v-else>{{ currentidType[0]?.dictLabel}}</div>
                        </td>
                        <td>证件号码</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                v-model="detail.idNumber"
                                placeholder="请输入证件号码"
                            ></el-input>
                            <div v-else>{{ detail.idNumber}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>政治面貌</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.politicalOutlook" placeholder="请选择政治面貌">
                                <el-option v-for="(item, index) in politicaltList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                            </el-select>
                            <div v-else>{{ currentPoliticalt[0]?.dictLabel || '-' }}</div>
                        </td>
                        <td>联系电话</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                v-model="detail.phone"
                                placeholder="请输入联系电话"
                            ></el-input>
                            <div v-else>{{ detail.phone}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>户籍地址</td>
                        <td colspan="3">
                            <template v-if="isEditing">
                                {{ currentResidenceAddress }}
                                <el-input
                                style="width: 44rem !important;"
                                    v-model="detail.residenceAddressDetails"
                                    placeholder="请输入详细户籍地址"
                                ></el-input>
                            </template>
                            <div v-else>{{ currentResidenceAddress }}{{ detail.residenceAddressDetails }}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>常住地址</td>
                        <td colspan="3">
                            <template v-if="isEditing">
                                {{ currentPermanentAddress }}
                                <el-input
                                    style="width: 44rem !important;"
                                    v-model="detail.permanentAddressDetails"
                                    placeholder="请输入详细常住地址"
                                ></el-input>
                            </template>
                            <div v-else>{{ currentPermanentAddress }}{{ detail.permanentAddressDetails }}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>创业担保贷款人员类别</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.loanPersonCategory" placeholder="请选择创业担保贷款人员类别" @change="documentClass">
                                <el-option  v-for="(item, index) in personCateList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                            </el-select>
                            <div v-else>{{ currentPersonCate[0]?.dictLabel }}</div>
                        </td>
                        <td>是否首次创业</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.firstEntrepreneurFlag" placeholder="请选择是否首次创业">
                                <el-option label="是" :value="1" />
                                <el-option label="否" :value="0" />
                            </el-select>
                            <div v-else>{{ detail.firstEntrepreneurFlag == 1 ? '是':'否' }}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>婚姻状况</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.maritalStatus" placeholder="请选择婚姻状况"  @change="changeMaritalStatus">
                                <el-option v-for="(item, index) in maritalStatus" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                            </el-select>
                            <template v-else>
                                <template v-if="detail.maritalStatus == 0"> 未婚 </template>
                                <template v-if="detail.maritalStatus == 1"> 已婚 </template>
                                <template v-if="detail.maritalStatus == 2"> 离异 </template>
                                <template v-if="detail.maritalStatus == 3"> 丧偶 </template>
                            </template>
                        </td>
                        <td>配偶姓名</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                :disabled="detail.maritalStatus != 1 ? true : false"
                                v-model="detail.spouseName"
                                placeholder="请输入配偶姓名"
                            ></el-input>
                            <div v-else>{{detail.spouseName || '-'}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>性别</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.spouseSex" placeholder="请选择配偶性别" :disabled="detail.maritalStatus != 1">
                                <el-option label="男" :value="0" />
                                <el-option label="女" :value="1" />
                            </el-select>
                            <template v-else>
                                <template v-if="detail.spouseSex == 0 ">男</template>
                                <template v-else-if="detail.spouseSex == 1 ">女</template>
                                <template v-else>-</template>
                            </template>
                        </td>
                        <td>配偶证件类型</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.spouseIdType" placeholder="请选择证件类型" :disabled="detail.maritalStatus != 1">
                                <el-option v-for="(item, index) in idTypeList" :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                            </el-select>
                            <div v-else>{{ sopseIdType[0]?.dictLabel || '-'}}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>配偶证件号码</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                :disabled="detail.maritalStatus != 1 ? true : false"
                                v-model="detail.spouseIdNumber"
                                placeholder="请输入配偶证件号码"
                            ></el-input>
                            <div v-else>{{detail.spouseIdNumber || '-'}}</div>
                        </td>
                        <td>申请人及配偶当前已获得的贷款项目和未尝还余额</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                :disabled="detail.maritalStatus != 1 ? true : false"
                                v-model="detail.withSpouseIncomplete"
                                placeholder="请输入申请人及配偶当前已获得的贷款项目和未尝还余额"
                            >
                                <template #append>万元</template>
                            </el-input>
                            <div v-else>{{ detail.withSpouseIncomplete ? detail.withSpouseIncomplete + '万元' : '-' }}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>紧急联系人</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                v-model="detail.emergencyContact"
                                placeholder="请输入紧急联系人姓名"
                            ></el-input>
                            <div v-else>{{detail.emergencyContact}}</div>
                        </td>
                        <td>紧急联系人电话</td>
                        <td>
                            <el-input
                                v-if="isEditing"
                                v-model="detail.emergencyContactPhone"
                                placeholder="请输入紧急联系人电话"
                            ></el-input>
                            <div v-else>{{detail.emergencyContactPhone}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>与借款人关系</td>
                        <td colspan="3">
                            <el-select v-if="isEditing" v-model="detail.entrepreneurRelation" placeholder="请选择与借款人关系">
                                <el-option v-for="(item, index) in entreRelationList" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                            </el-select>
                            <div v-else>{{ currentEntrepreneurRelation[0]?.dictLabel }}</div>
                        </td>
                    </tr>

                    <tr>
                        <td>申请贷款用途</td>
                        <td colspan="3">
                            <el-input
                                v-if="isEditing"
                                type="textarea"
                                v-model="detail.loanPurpose"
                                placeholder="请输入申请贷款用途"
                                style="width: 98% !important; margin: 10px 0"
                            ></el-input>
                            <div v-else>{{ detail.loanPurpose }}</div>
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <td width="25%">创业扶持人员类别</td>
                        <td width="25%">
                            <el-select v-if="isEditing" v-model="detail.entrepreneurCategory" placeholder="请选择">
                                <el-option v-for="(item, index) in  entrepreneurList " :label="item.dictLabel" :value="item.dictValue" :key="index"/>
                            </el-select>
                            <div v-else>{{currentEntrepreneur[0]?.dictLabel}}</div>
                        </td>
                        <td width="25%">营业执照全称</td>
                        <td width="25%">
                            <el-input
                                v-if="isEditing"
                                v-model="detail.unitName"
                                placeholder="请输入营业执照全称"
                            ></el-input>
                            <div v-else>{{ detail.unitName}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>统一社会信用代码</td>
                        <td colspan="3">
                            <el-input
                                v-if="isEditing"
                                v-model="detail.socialCode"
                                placeholder="请输入统一社会信用代码"
                            ></el-input>
                            <div v-else>{{ detail.socialCode}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>经营地址</td>
                        <td colspan="3">
                            <template v-if="isEditing">
                                {{ currentOperatingAddress }}
                                <el-input
                                    style="width: 44rem !important;"
                                    v-model="detail.operatingAddressDetails"
                                    placeholder="请输入详细经营地址"
                                ></el-input>
                            </template>
                            <div v-else>{{ currentOperatingAddress }}{{ detail.operatingAddressDetails }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>推荐单位名称</td>
                        <td colspan="3">
                            <el-input
                                v-if="isEditing"
                                style="width: 98% !important;"
                                v-model="detail.recommendUnit"
                                placeholder="请输入推荐单位名称"
                            ></el-input>
                            <div v-else>{{ detail.recommendUnit || '-' }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>经营范围及项目</td>
                        <td colspan="3">
                            <el-input
                                v-if="isEditing"
                                type="textarea"
                                v-model="detail.businessScope"
                                placeholder="请输入经营范围及项目"
                                style="width: 98% !important; margin: 10px 0"
                            ></el-input>
                            <div v-else>{{ detail.businessScope }}</div>
                        </td>
                    </tr>
                </table>

                <table>
                    <tr>
                        <td width="25%">申请贷款金额</td>
                        <td width="25%">
                            <el-input
                                v-if="isEditing"
                                v-model="detail.loanAmount"
                                placeholder="请输入申请贷款金额"
                            >
                                <template #append>万元</template>
                            </el-input>
                            <div v-else>{{detail.loanAmount}}万元</div>
                        </td>
                        <td width="25%">贷款期限</td>
                        <td width="25%">
                            <el-input
                                v-if="isEditing"
                                v-model="detail.loanTerm"
                                placeholder="请输入贷款期限"
                            >
                                <template #append>个月</template>
                            </el-input>
                            <div v-else>{{ detail.loanTerm }}个月</div>
                        </td>
                    </tr>

                    <tr>
                        <td>担保增信方式</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.guaranteeWay" placeholder="请选择担保增信方式">
                                <el-option v-for="(item, index) in guaranWayList" :label="item.dictLabel" :value="item.dictValue" :key="index" />
                            </el-select>
                            <div v-else>{{ currentguaranWay[0]?.dictLabel}}</div>
                        </td>
                        <td>担保机构</td>
                        <td>{{ currentguarantors}}</td>
                    </tr>

                    <tr>
                        <td>贷款银行</td>
                        <td>{{ curentbank}}</td>
                        <td>是否免除反担保</td>
                        <td>
                            <el-select v-if="isEditing" v-model="detail.counterGuarantee" placeholder="请选择是否免除反担保">
                                <el-option label="是" :value="0" />
                                <el-option label="否" :value="1" />
                            </el-select>
                            <div v-else>{{ detail.counterGuarantee == 0 ? '是' : '否' }}</div>
                        </td>
                    </tr>
                </table>

                <h2><span></span>员工信息</h2>
                <template v-for="(itm,index) in detail.employees" :key="index">
                    <table>
                        <tr class="del-emp" v-if="isEditing">
                            <div class="del-emp-btn" @click="delEmployee(itm)">
                                <img src="@/assets/ic_del.png" />
                                删除该员工信息
                            </div>
                        </tr>
                        <tr>
                            <td width="25%">员工姓名</td>
                            <td width="25%">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.employeeName"
                                    placeholder="请输入员工姓名"
                                ></el-input>
                                <div v-else>{{ itm.employeeName }}</div>
                            </td>
                            <td width="25%">证件类型</td>
                            <td width="25%">
                                <el-select
                                    v-if="isEditing"
                                    placeholder="请选择证件类型"
                                    v-model="itm.idType"
                                >
                                    <el-option
                                        v-for="itm in idTypeList"
                                        :label="itm.dictLabel"
                                        :value="itm.dictValue"
                                        :key="itm.dictValue"
                                    />
                                </el-select>
                                <div v-else>
                                    {{ (idTypeList.filter(item=>{ return item.dictValue == itm.idType?item.dictLabel:''}))[0]?.dictLabel }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>证件号码</td>
                            <td colspan="3">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.employeeIdNumber"
                                    placeholder="请输入员工证件号码"
                                ></el-input>
                                <div v-else>{{ itm.employeeIdNumber }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>员工身份证正反面照片</td>
                            <td colspan="3">
                                <div v-if="isEditing" style="display: flex">
                                    <el-upload
                                        class="avatar-uploader"
                                        :show-file-list="false"
                                        v-model="itm.idNumberUrl"
                                        :on-change="(res, f) => changeImge(res, f, itm)"
                                        >
                                        <img
                                            v-if="itm.idNumberUrlRead"
                                            :src="itm.idNumberUrlRead"
                                            class="avatar"
                                        />
                                        <img :src="ImgOne" v-else class="avatar" />
                                    </el-upload>

                                    <el-upload
                                        class="avatar-uploader"
                                        :show-file-list="false"
                                        v-model="itm.idNumberOpposite"
                                        :on-change="(res, f) => changeImgeRever(res, f, itm)"
                                        >
                                        <img
                                            v-if="itm.idNumberOppositeRead"
                                            :src="itm.idNumberOppositeRead"
                                            class="avatar c1"
                                        />
                                        <img :src="ImgTwo" v-else class="avatar c2" />
                                    </el-upload>
                                </div>
                                <div v-else>
                                    <span><img :src="itm.idNumberUrlRead" :onerror="() => {itm.idNumberUrlRead =''}"></span>
                                    <span><img :src="itm.idNumberOppositeRead" :onerror="() => {itm.idNumberOppositeRead =''}"></span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </template>
                <div class="add-emp" v-if="isEditing">
                    <span @click="addEmployee">+ 新增员工信息</span>
                </div>

                <h2><span></span>合伙人信息</h2>

                <div v-for="(itm,index) in detail.partnerDtos" :key="index">
                    <table>
                        <tr class="del-emp" v-if="isEditing">
                            <div class="del-emp-btn" @click="delPartner(itm)">
                                <img src="@/assets/ic_del.png" />
                                删除该合伙人信息
                            </div>
                        </tr>
                        <tr>
                            <td width="25%">合伙人姓名</td>
                            <td width="25%">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.partnerName"
                                    placeholder="请输入合伙人姓名"
                                ></el-input>
                                <div v-else>{{ itm.partnerName }}</div>
                            </td>
                            <td width="25%">证件类型</td>
                            <td width="25%">
                                <el-select
                                    v-if="isEditing"
                                    placeholder="请选择证件类型"
                                    v-model="itm.idType"
                                >
                                    <el-option
                                        v-for="itm in idTypeList"
                                        :label="itm.dictLabel"
                                        :value="itm.dictValue"
                                        :key="itm.dictValue"
                                    />
                                </el-select>
                                <div v-else>
                                    {{ (idTypeList.filter(item=>{ return item.dictValue == itm.idType?item.dictLabel:''}))[0]?.dictLabel }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>证件号码</td>
                            <td>
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.idNumber"
                                    placeholder="请输入员工证件号码"
                                ></el-input>
                                <div v-else>{{ itm.idNumber }}</div>
                            </td>
                            <td>创业担保贷款合伙人人员类别</td>
                            <td>
                                <el-select v-if="isEditing" placeholder="请选择创业担保贷款合伙人人员类别" v-model="itm.partnerCategory">
                                    <el-option  v-for="item in categoryList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
                                </el-select>
                                <div v-else>
                                    {{ (categoryList.filter(item=>{ return item.dictValue == itm.partnerCategory?item.dictLabel:''}))[0]?.dictLabel }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">申请贷款金额</td>
                            <td width="25%">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.partnerLoanAmount"
                                    placeholder="请输入申请贷款金额"
                                >
                                    <template #append>万元</template>
                                </el-input>
                                <div v-else>{{ itm.partnerLoanAmount ? itm.partnerLoanAmount + '万元' : '-' }}</div>
                            </td>
                            <td width="25%">贷款进度</td>
                            <td width="25%" style="color: #C30A05;">
                                <label v-if="!isEditing" style="cursor: pointer;" @click="toPartnerLoanDetail(itm)">点击查看贷款进度详情</label>
                                <label v-else>-</label>
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">合伙人电话</td>
                            <td width="25%">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.partnerPhoneNumber"
                                    placeholder="请输入合伙人电话"
                                ></el-input>
                                <div v-else>{{ itm.partnerPhoneNumber }}</div>
                            </td>
                            <td width="25%">合伙人地址</td>
                            <td width="25%">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.partnerAddress"
                                    placeholder="请输入合伙人地址"
                                ></el-input>
                                <div v-else>{{ itm.partnerAddress || '-' }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>贷款期限</td>
                            <td colspan="3">
                                <el-input
                                    v-if="isEditing"
                                    v-model="itm.partnerLoanTerm"
                                    placeholder="请输入贷款期限"
                                >
                                    <template #append>个月</template>
                                </el-input>
                                <div v-else>{{ itm.partnerLoanTerm ? itm.partnerLoanTerm + '个月' : '-' }}</div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="add-emp" v-if="isEditing">
                    <span @click="addPartner">+ 新增合伙人信息</span>
                </div>


                <h2><span></span>相关证件材料</h2>
                <table>
                    <tr>
                        <td width="25%">营业执照</td>
                        <td>
                            <el-upload
                                v-if="isEditing"
                                class="avatar-uploader"
                                :show-file-list="false"
                                v-model="detail.businessUrl"
                                :on-change="(res, f) => changeBusiImge(res, f, item)"
                                >
                                <img
                                    v-if="detail.businessUrlRead"
                                    :src="detail.businessUrlRead"
                                    class="avatar"
                                />
                                <img :src="ImgOne" v-else class="avatar" />
                            </el-upload>
                            <span v-else
                            ><img
                                :src="detail.businessUrlRead"
                                :onerror="
                                () => {
                                    detail.businessUrlRead = '';
                                }
                                "
                            /></span>
                        </td>
                    </tr>
                    <tr>
                        <td>本人身份证正反面照片</td>
                        <td>
                            <div v-if="isEditing" style="display: flex">
                                <el-upload
                                    class="avatar-uploader"
                                    :show-file-list="false"
                                    v-model="detail.idNumberUrl"
                                    :on-change="(res, f) => changeImgeIdm(res, f, item)"
                                >
                                    <img
                                    v-if="detail.idNumberUrlRead"
                                    :src="detail.idNumberUrlRead"
                                    class="avatar"
                                    />
                                    <img :src="ImgOne" v-else class="avatar" />
                                </el-upload>

                                <el-upload
                                    class="avatar-uploader"
                                    :show-file-list="false"
                                    v-model="detail.idNumberReverseUrl"
                                    :on-change="(res, f) => changeImgeOpposite(res, f, item)"
                                >
                                    <img
                                    v-if="detail.idNumberReverseUrlRead"
                                    :src="detail.idNumberReverseUrlRead"
                                    class="avatar"
                                    />
                                    <img :src="ImgTwo" v-else class="avatar" />
                                </el-upload>
                            </div>
                            <div v-else>
                                <span
                                    ><img
                                    :src="detail.idNumberUrlRead"
                                    :onerror="
                                        () => {
                                        detail.idNumberUrlRead = '';
                                        }
                                    "
                                /></span>
                                <span
                                    ><img
                                    :src="detail.idNumberReverseUrlRead"
                                    :onerror="
                                        () => {
                                        detail.idNumberReverseUrlRead = '';
                                        }
                                    "
                                /></span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>担保证明</td>
                        <td>
                            <el-upload
                                v-if="isEditing"
                                class="avatar-uploader"
                                :show-file-list="false"
                                v-model="detail.guaranteeCertificateUrl"
                                :on-change="(res, f) => changeGuaImge(res, f, item)"
                                >
                                <img
                                    v-if="detail.guaranteeCertificateUrlRead"
                                    :src="detail.guaranteeCertificateUrlRead"
                                    class="avatar"
                                />
                                <img :src="ImgOne" v-else class="avatar" />
                            </el-upload>
                            <span v-else
                            ><img
                                :src="detail.guaranteeCertificateUrlRead"
                                :onerror="
                                () => {
                                    detail.guaranteeCertificateUrlRead = '';
                                }
                                "
                            /></span>
                        </td>
                    </tr>
                    <tr>
                        <td>本人户口本照片</td>
                        <td>
                            <el-upload
                                v-if="isEditing"
                                class="avatar-uploader"
                                :show-file-list="false"
                                v-model="detail.householdPhoto"
                                :on-change="(res, f) => changeHouseImge(res, f, item)"
                                >
                                <img
                                    v-if="detail.householdPhotoRead"
                                    :src="detail.householdPhotoRead"
                                    class="avatar"
                                />
                                <img :src="ImgOne" v-else class="avatar" />
                            </el-upload>
                            <span v-else
                            ><img
                                :src="detail.householdPhotoRead"
                                :onerror="
                                () => {
                                    detail.householdPhotoRead = '';
                                }
                                "
                            /></span>
                        </td>
                    </tr>
                    <tr v-if="detail.maritalStatus == 1">
                        <td>配偶身份证正反面照片</td>
                        <td>
                            <div v-if="isEditing" style="display: flex">
                                <el-upload
                                    class="avatar-uploader"
                                    :show-file-list="false"
                                    v-model="detail.spouseIdNumberUrl"
                                    :on-change="(res, f) => changeSpouseImg(res, f, item)"
                                >
                                    <img
                                    v-if="detail.spouseIdNumberUrlRead"
                                    :src="detail.spouseIdNumberUrlRead"
                                    class="avatar"
                                    />
                                    <img :src="ImgOne" v-else class="avatar" />
                                </el-upload>

                                <el-upload
                                    class="avatar-uploader"
                                    :show-file-list="false"
                                    v-model="detail.spouseIdNumberReverseUrl"
                                    :on-change="(res, f) => changeSpouseResImg(res, f, item)"
                                >
                                    <img
                                    v-if="detail.spouseIdNumberReverseUrlRead"
                                    :src="detail.spouseIdNumberReverseUrlRead"
                                    class="avatar"
                                    />
                                    <img :src="ImgTwo" v-else class="avatar" />
                                </el-upload>
                            </div>
                            <div v-else>
                                <span
                                    ><img
                                    :src="detail.spouseIdNumberUrlRead"
                                    :onerror="
                                        () => {
                                        detail.spouseIdNumberUrlRead = '';
                                        }
                                    "
                                /></span>
                                <span
                                    ><img
                                    :src="detail.spouseIdNumberReverseUrlRead"
                                    :onerror="
                                        () => {
                                        detail.spouseIdNumberReverseUrlRead = '';
                                        }
                                    "
                                /></span>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="detail.maritalStatus == 1">
                        <td>配偶结婚证照片</td>
                        <td>
                            <el-upload
                                v-if="isEditing"
                                class="avatar-uploader"
                                :show-file-list="false"
                                v-model="detail.marriageCertificateUrl"
                                :on-change="(res, f) => changeMarryImg(res, f, item)"
                                >
                                <img
                                    v-if="detail.marriageCertificateUrlRead"
                                    :src="detail.marriageCertificateUrlRead"
                                    class="avatar"
                                />
                                <img :src="ImgOne" v-else class="avatar" />
                            </el-upload>
                            <span v-else
                            ><img
                                :src="detail.marriageCertificateUrlRead"
                                :onerror="
                                () => {
                                    detail.marriageCertificateUrlRead = '';
                                }
                                "
                            /></span>
                        </td>
                    </tr>

                    <tr>
                        <td>人员类别相关证件照片照片</td>
                        <td>
                            <div style="display: flex; flex-wrap: wrap; padding-bottom: 1rem;">
                                <el-upload
                                    v-if="isEditing"
                                    class="avatar-uploader"
                                    :show-file-list="false"
                                    :headers="headers"
                                    :on-change="changeImgePerType"
                                    :auto-upload="false"
                                    multiple
                                    accept=".jpg,.png">
                                    <img :src="ImgOne" class="avatar"/>
                                </el-upload>
                                <template v-if="documentClassArr && documentClassArr.length > 0">
                                    <div class="avatar img-avatar mt-1r" v-for="(item, index) in documentClassArr" :key="index">
                                        <el-image :src="item" :preview-src-list="documentClassArr" class="avatar img-avatar"></el-image>
                                        <div v-if="isEditing" class="img-del" @click="delImg(index)">×</div>
                                    </div>
                                </template>
                            </div>
                        </td>
                    </tr>
                </table>

                <div class="botton" v-if="!isEditing">
                    <span @click="router.push('/applyList')">返回上一页</span>
                </div>
            </div>
            <div class="submitBtn" v-if="isEditing">
                <el-button type="default" size="large" @click="toCancel">取消</el-button>
                <el-button type="primary" size="large" @click="submitFun">提交</el-button>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
:deep(.el-input) {
  width: 250px !important;
}
:deep(.el-select) {
  width: 250px !important;
}
:deep(.el-cascader) {
  width: 250px !important;
}
.all {
    position: relative;
    background-image: url('../../../assets/security/bg.png');
    background-repeat: no-repeat;
    margin-top: -0.3rem;
    background-size: 100% 50%;
    padding-top: 1.25rem;
    background-color: #F9FBFF;
    padding-bottom: 2rem;
}

.position label{
    cursor: pointer;
}
.cont{
    width: 73rem;
    margin:1rem auto 0 auto;
    background-color: #ffffff;
    padding:1rem 1rem 2rem 1rem;
}

// .cont h2 span {
//     border-left: 4px solid #065BFF;
//     display: inline-block;
//     height: 1rem;
//     vertical-align: middle;
//     margin-right: 0.87rem;

// }

.cont h2 {
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #E8EAED;
    padding-bottom: 1.125rem;
    padding-left: 0.625rem;
    font-size: 1rem;
    position: relative;
}
.cont .page-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 1.125rem;
  padding-left: 0.625rem;
  font-size: 1rem;
  border-bottom: 1px solid #ebebeb;
  .btn-edit {
    position: absolute;
    right: 1rem;
  }
}
.cont h2::before {
  content: '';
  width: 0.25rem;
  height: 1rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
  background: #C30A05;
  border-radius: 0.125rem;
}

table {
    border: 1px solid #E8EAED;
    border-collapse: collapse;
    margin: 2rem 0;
    width: 100%;
}

table td {
    border: 1px solid #E8EAED;
    color: #333333;
    font-size: 0.87rem;
    height: 3.43rem;
    line-height: 3.43rem;
    padding-left: 1.31rem;
}

table td span {
    background: #EEF1F5;
    width: 12.5rem;
    height: 7.87rem;
    display: block;
    margin: 1rem 0;
    display: inline-block;
    margin-right: 3rem;
}

table td span img{
    width: 100%;
    height: 100%;
}

table td:nth-child(odd) {
    background-color: #FAFAFA;
    font-weight: 600;
}

table tr td img[src=''],img:not([src]){
    opacity: 0;
}

.botton {
    text-align: center;
    border-top: 1px solid #E8EAED;
    padding: 2.5rem 0 0 0;
    margin: 3.75rem 0 0 0;
}

.botton span {
    display: block;
    border: 1px solid #E8EAED;
    width: 7.12rem;
    height: 2.12rem;
    line-height: 2.12rem;
    margin: 0 auto;
    cursor: pointer;
}

.idNumberBox {
    display: inline-flex;
    img {
        flex-shrink: 0;
    }
}

.submitBtn {
  width: 100vw;
  height: 2.5rem;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  box-shadow: 0px 6px 20px rgba(126, 119, 103, 0.15);
  .el-button {
    width: 10rem;
  }
}
.avatar-uploader {
  margin: 1rem 1rem 1rem 0;
  height: 7.68rem;
}
.avatar {
  width: 11.87rem;
  height: 7.68rem;
}
.del-emp {
  height: 57.88px;
  line-height: 57.88px;
  text-align: right;
  position: relative;
  .del-emp-btn {
    position: absolute;
    top: 0;
    right: 30px;
    cursor: pointer;
    color: #ff0000;
    display: flex;
    align-items: center;
    font-weight: 500;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
.add-emp {
  text-align: center;
  color: #C30A05;
  font-weight: 600;
  span {
    cursor: pointer;
  }
}

.img-avatar {
    margin-right: 1rem;
    position: relative;
}

.img-del {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-align: center;
    line-height: 1.5rem;
    color: #fff;
}
.mt-1r {
    margin-top: 1rem;
}
</style>
