<script setup>
 import  axios from 'axios'
    import { uploadImgUrlPost,headers } from '@/api/upload.js'

    import { getToken } from "@/utils/auth";

    const uploadHeaders =  {
        Authorization: "Bearer " + getToken(),
        'Content-Type':'multipart/form-data'
    }

    const formLabelAlign = reactive({
        businessUrl: null,
        businessUrlRead: null,
        idNumberUrl: null,
        idNumberUrlRead: null,
        yearWagesOutside: null,
        yearWagesOutsideRead: null,
        taxPaymentCertificate: null,
        taxPaymentCertificateRead: null,
        employeeRegistration: null,
        employeeRegistrationRead: null,
        idNumberOpposite: null,
        idNumberOppositeRead: null
    })

    import ImgOne from '../../../assets/stepTwo/id-1.png';
    import ImgTwo from '../../../assets/stepTwo/id-2.png'
    import {ref} from "vue";
    const businessUrl = ref('')

    // 图片上传 营业执照
    const businessUrlRead = ref('')
    const changeImge = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        businessUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.businessUrl = res.data.msg
                // },1000)

            }
        })
    }

    // 图片上传 本人身份证正
    const idNumberUrlRead = ref('')
    const changeImgeIdm = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        idNumberUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.idNumberUrl =  res.data.msg
                // },1000)

            }
        })
    }

    // 身份证反面
    const idNumberOppositeRead = ref('')
    const changeImgeOpposite = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        idNumberOppositeRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.idNumberOpposite =  res.data.msg
                // },1000)

            }
        })
    }

    // 图片上传 本年度工资表
    const yearWagesOutsideRead = ref('')
    const changeImgeYear = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        yearWagesOutsideRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.yearWagesOutside =  res.data.msg
                // },1000)

            }
        })
    }

    // 图片上传 完税证明支持格式
    const taxPaymentCertificateRead = ref('')
    const changeImgeTax = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        taxPaymentCertificateRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.taxPaymentCertificate =  res.data.msg
                // },1000)

            }
        })
    }

    // 员工登记
    const employeeRegistrationRead = ref('')
    const changeImgeEmp = (res,f) =>{
        let aimg = URL.createObjectURL(res.raw)
        employeeRegistrationRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                // setTimeout(() =>{
                    formLabelAlign.employeeRegistration =  res.data.msg
                // },1000)

            }
        })
    }

    defineExpose({
        formLabelAlign,
        businessUrl
    })



</script>

<template>
    <div class="box-right">
        <h2><span></span>相关证件材料</h2>
        <el-form
            label-position="top"
            label-width="100px"
            :model="formLabelAlign">

            <div class="title"><i class="red-tip">*</i>营业执照 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImge"
                    :auto-upload="true"
                    v-model="formLabelAlign.businessUrl">
                    <img v-if="businessUrlRead || formLabelAlign.businessUrlRead" :src="businessUrlRead?businessUrlRead:formLabelAlign.businessUrlRead" class="avatar"/>
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>


            <div class="title"><i class="red-tip">*</i>本人身份证正反面 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <div class="id-img">
                <el-form-item label="">
                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeIdm"
                        :auto-upload="true"
                        v-model="formLabelAlign.idNumberUrl">
                        <img v-if="idNumberUrlRead || formLabelAlign.idNumberUrlRead" :src="idNumberUrlRead?idNumberUrlRead:formLabelAlign.idNumberUrlRead" class="avatar"/>
                        <img :src="ImgOne" v-else class="avatar"/>
                    </el-upload>

                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeOpposite"
                        :auto-upload="true"
                        v-model="formLabelAlign.idNumberOpposite">
                        <img v-if="idNumberOppositeRead || formLabelAlign.idNumberOppositeRead" :src="idNumberOppositeRead?idNumberOppositeRead:formLabelAlign.idNumberOppositeRead" class="avatar"/>
                        <img :src="ImgTwo" v-else class="avatar"/>
                    </el-upload>
                </el-form-item>
            </div>

            <div class="title"><i class="red-tip">*</i>年度工资发放表 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :on-change="changeImgeYear"
                    v-model="formLabelAlign.yearWagesOutside"
                >
                    <img v-if="yearWagesOutsideRead || formLabelAlign.yearWagesOutsideRead" :src="yearWagesOutsideRead?yearWagesOutsideRead:formLabelAlign.yearWagesOutsideRead" class="avatar" />
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>


            <div class="title"><i class="red-tip">*</i>完税证明<span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImgeTax"
                    :auto-upload="true"
                    v-model="formLabelAlign.taxPaymentCertificate">
                    <img v-if="taxPaymentCertificateRead || formLabelAlign.taxPaymentCertificateRead" :src="taxPaymentCertificateRead?taxPaymentCertificateRead:formLabelAlign.taxPaymentCertificateRead" class="avatar"/>
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>

            <div class="title"><i class="red-tip">*</i>员工登记表<span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImgeEmp"
                    :auto-upload="true"
                    v-model="formLabelAlign.employeeRegistration">
                    <img v-if="employeeRegistrationRead || formLabelAlign.employeeRegistrationRead" :src="employeeRegistrationRead?employeeRegistrationRead:formLabelAlign.employeeRegistrationRead" class="avatar"/>
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>
        </el-form>
    </div>
</template>

<style scoped lang="scss">
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}

.line{
    border-top: 1px solid #E8EAED;
    margin: 3rem 0;
}

.avatar{
    width: 11.87rem;
    height: 7.68rem;
    margin-right: 3.68rem;
}
.normal-avatar {
    width: 7.68rem;
    height: 7.68rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8EAED;
    img {
        width: 3rem;
        height: 3rem;
    }
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 1rem 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}

.upload{
    width: 7.5rem;
    height: 7.5rem;
    text-align: center;
    line-height: 7.5rem;
    background-color: #E8EAED;
    font-size: 2rem;
}

.done{
    text-align: center;
    color: #333333;
    font-size: 0.87rem;
    padding-top: 5rem;
}

.done img{
    width: 7.5rem;
    height: 6.25rem;
}

.count{
    color: #999999;
    margin: 1.31rem 0 0 0;
}
.count i{
    font-style: normal;
    color: #0B7EFF;
    font-size: 1rem;

}
.red-tip{
    color: red;
    font-style: normal;
    margin-right: 0.2rem;
}
</style>
