<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()

</script>
<template>
    <div style="background-color: rgba(242, 245, 250, 1);">
        <div class="banner"><img src="/src/assets/security/img-banner.png"/></div>
        
        <div class="all">
            <div class="position">您当前的位置：<label @click="router.push('/')">首页</label> /<i> 创业担保贷款介绍</i></div>
            <div class="cont">
                <img src="/src/assets/security/security_inner.png"/>
                <div class="desc">
                    <h2>什么是创业担保贷款</h2>
                    <p>创业担保贷款是指以符合规定条件的创业者个人或小微企业为借款人，由创业担保贷款担保基金或政府性融资担保机构提供担保，由经办银行发放，
                        由政府部门给予贴息，用于支持个人创业或小微企业吸纳就业的贷款业务。</p>
                    <p class="line2">
                        创业担保贷款是指以符合规定条件的创业者个人或小微企业为借款人，由创业担保贷款担保基金或政府性融资担保机构提供担保，
                        由经办银行发放，由政府部门给予贴息，用于支持个人创业或小微企业吸纳就业的贷款业务。
                    </p>
                    <span class="apply" @click="router.push('busInfo')"><img src="/src/assets/index/ic_edit.png" />个人及合伙人申请</span> 
                    <span class="apply" @click="router.push('company')"><img src="/src/assets/index/ic_edit.png" />小微企业申请</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.all{
    position: relative;
    background:url('../../assets/security/security_bg.png');
    background-repeat: no-repeat;
    margin-top: -1.5rem;
    background-size: 100% 25rem;
    padding: 1.3rem 0 3rem 0;
    background-position: bottom;
}

.position label{
    cursor: pointer;
}
.cont{
    display: flex;
    justify-content: center;
    width: 75rem;
    height: 33.625rem;
    background-color: #ffffff;
    margin: 0 auto;
    padding:5.81rem 2.5rem 0 2.75rem;
    border-radius: 5px;
    font-size: 1rem;
}
.cont img{
    width: 20rem;
    height: 23.375rem;
    margin-right: 0.5rem;
}
.desc h2{
    font-weight: 600;
    font-size: 2.25rem;
    color: #000000;
    position: relative;
    margin-bottom: 2.8rem;
    margin-top: 0;
}
.desc h2::after {
    content: '';
    position: absolute;
    bottom: -1.375rem;
    left: 0;
    width: 6.25rem;
    height: 0.25rem;
    background-color: #C30A05;
}

.desc p{
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 2rem;
    margin-top: 0;
}
.line2 {
    margin-top: 2.5rem !important;
}

.apply{
    background-color: #C30A05;
    color: #ffffff;
    height: 2.75rem;
    padding: 0 1.44rem;
    line-height: 2.75rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 5px;
    margin-top:2.31rem;
    display: inline-flex;
    margin-right: 3rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
}
</style>