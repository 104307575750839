<script setup>
import { useRouter } from 'vue-router'
import ImgFront from '../assets/img-front.png'
import AboutUs from '../assets/about-us.png'
import ImgEdit from '@/assets/index/ic_edit.png';
import Cookies from "js-cookie";
import { ElMessage } from 'element-plus'

const router = useRouter()
const goDetail = () =>{
    router.push({path:'/security'})
}

 const user = Cookies.get("user")
// 跳转 个人
const goApply = () =>{
    if(!user){
        ElMessage.error('请您先登录')
        return false
    }
    router.push('/busInfo')
}

// 跳转 小微企
const goCompany = () =>{
    if(!user){
        ElMessage.error('请您先登录')
        return false
    }
    router.push('/company')
}

// 立即申请
const apply = () =>{
    var div = document.getElementById("boxCont");
    var rect = div.getBoundingClientRect();
    var distance = rect.top + window.scrollY;
    console.log(distance,'boxCont');

    window.scrollTo({
        top: distance,
        behavior: 'smooth' // 平滑滚动效果（可选）
    })
}
</script>

<template>
    <div class="common-layout">
      <div class="top">

        <el-carousel trigger="click" height="28.125rem" arrow="always">
            <el-carousel-item>
                <img src="/src/assets/index/index_banner_1.png"/>
                <div class="front-div">
                    <div>
                        <!-- <a class="apply" @click="apply">
                            <img :src="ImgEdit"/>
                            立即申请
                        </a> -->
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <img src="/src/assets/index/index_banner_2.png"/>
                <div class="front-div">
                    <div>
                        <!-- <a class="apply" @click="apply">
                            <img :src="ImgEdit"/>
                            立即申请
                        </a> -->
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <img src="/src/assets/index/index_banner_3.png"/>
                <div class="front-div">
                    <div>
                        <!-- <a class="apply" @click="apply">
                            <img :src="ImgEdit"/>
                            立即申请
                        </a> -->
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
      </div>

      <div class="box-3"> 
        <img src="/src/assets/index/index_center_bg.png">
        <div class="box-cont" id="boxCont">
            <h2>申请创业担保贷款</h2>
            <div class="box3-cards">
                <div class="card-left">
                    <div class="left-title">
                        <span class="title">
                            <img src="/src/assets/index/ic_tap.png" />
                            个人及合伙人创业担保贷款
                        </span>
                        <span class="b-3text-img"><img src="/src/assets/index/ic_price.png"/>财政贴息贷款</span>
                    </div>
                    <label class="b-3text-1">贷款额度</label>
                    <label class="b-3text-2">30 <i>万元</i></label>
                    <span class="b-3text-3" @click="goApply"><img src="/src/assets/index/ic_edit.png" />立即申请</span>
                    <img class="card-inner-img" src="/src/assets/index/index_center_inner_right.png" />
                </div>
                <div class="card-right"> 
                    <div class="left-title">
                        <span class="title">
                            <img src="/src/assets/index/ic_tap.png" />
                            小微企业创业担保贷款
                        </span>
                        <span class="b-3text-img"><img src="/src/assets/index/ic_price.png"/>财政贴息贷款</span>
                    </div>
                    <label class="b-3text-1">贷款额度</label>
                    <label class="b-3text-2"><i>最高</i>500<i>万元</i></label>
                    <span class="b-3text-3" @click="goCompany"><img src="/src/assets/index/ic_edit.png" />立即申请</span>
                    <img class="card-inner-img" src="/src/assets/index/index_center_inner_left.png" />
                </div>
            </div>
        </div>
      </div>

      <div class="box-4">
        <div class="box-4-1">
            <div class="box-4-1-cont">
                <img class="center-inner-img" src="/src/assets/index/index_center_inner.png" />
                <div class="center-inner-content">
                    <div class="title">什么是创业担保贷款？</div>
                    <div class="intro">创业担保贷款是指以符合规定条件的创业者个人或小微企业为借款人，
                        由创业担保贷款担保基金或政府性融资担保机构提供担保，由经办银行发放，由政府部门给予贴息，用于支持个人创业或小微企业吸纳就业的贷款业务。
                    </div>
                    <div class="applyBtn" @click="goDetail">查看详情</div>
                </div>
            </div>
        </div>
      </div>
      
      <div class="box-5">
        <div class="box-cont">
            <h2>创业贷款人员类别</h2>
            <div class="box-5-list">
                <div class="box-5-left">
                    <ul>
                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_1.png" class="bg"/>
                            <span class="box-5-span">城镇登记失业人员</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_1.png" />
                                <div class="txt">城镇登记失业人员</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_2.png" class="bg"/>
                            <span class="box-5-span">就业困难人员(含残疾人)</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_2.png" />
                                <div class="txt">就业困难人员(含残疾人)</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_3.png" class="bg"/>
                            <span class="box-5-span">复员转业退役军人</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_3.png" />
                                <div class="txt">复员转业退役军人</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_4.png" class="bg"/>
                            <span class="box-5-span">刑满释放人员</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_4.png" />
                                <div class="txt">刑满释放人员</div>
                            </div>
                        </li>
                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_5.png" class="bg"/>
                            <span class="box-5-span">高校毕业生(含大<br/>学生村官和留学回国学生)</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_5.png" />
                                <div class="txt">高校毕业生(含大<br/>学生村官和留学回国学生)</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_6.png" class="bg"/>
                            <span class="box-5-span">化解过剩产能<br/>企业职工和失业人员</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_6.png" />
                                <div class="txt">化解过剩产能<br/>企业职工和失业人员</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_7.png" class="bg"/>
                            <span class="box-5-span">返乡创业农名工</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_7.png" />
                                <div class="txt">返乡创业农名工</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_8.png" class="bg"/>
                            <span class="box-5-span">网络商户</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_8.png" />
                                <div class="txt">网络商户</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_9.png" class="bg"/>
                            <span class="box-5-span">脱贫人口</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_9.png" />
                                <div class="txt">脱贫人口</div>
                            </div>
                        </li>

                        <li class="box-5-li">
                            <img src="/src/assets/index/index_bottom_10.png" class="bg"/>
                            <span class="box-5-span">农村自主创业的农牧民</span>
                            <div class="box-5-content">
                                <img src="/src/assets/index/index_bottom_inner_10.png" />
                                <div class="txt">农村自主创业的农牧民</div>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- <div class="box-5-right"><img src="/src/assets/img-box-5-1.png"/></div> -->
            </div>
        </div>
        <img src="" alt="">
      </div>
  </div>
</template>
<style src="./index.css" scoped></style>
<style lang="scss" scoped>
    :deep(.el-carousel__indicators--horizontal) {
        bottom: 2.25rem;
        left: 18.125rem;
        transform: none;
    }
    :deep(.el-carousel__button) {
        background: rgba(0,0,0,0.5);
        width: 2.5rem;
        height: 0.375rem;
    }
    .common-layout {
        background-image: url('/src/assets/index/index_bottom-bg.png');
        background-position: left bottom;
        background-size: 100% 22.75rem;
        background-repeat: no-repeat;
    }
    .apply {
        width: 8.75rem !important;
        height: 2.5rem !important;
        font-size: 0.875rem !important;
        margin-top: 10rem !important;
        margin-left: 10rem !important;
        border-radius: 2px !important;
        background: #C30A05 !important;
        color: #fff !important;
        display: inline-flex !important;
        align-items: center !important;
        justify-content: center !important;
        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
        }
    }
    .left-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
            font-size: 1.25rem;
            font-weight: 600;
            img {
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
            }
        }
        .b-3text-img {
            width: 9rem;
            height: 1.75rem;
            background: rgba(195,10,5,0.1);
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.875rem;
            font-weight: 500;
            color: #C30A05;
            img {
                width: 1rem;
                height: 1rem;
                margin-right: 0.5rem;
            }
        }
    }
    .b-3text-1 {
        margin-top: 1.875rem;
        font-size: 0.875rem;
        color: #666666;
        font-weight: 400;
    }
    .b-3text-2 {
        color: #C30A05;
        font-size: 2.5rem;
        font-weight: bold;
        i {
            font-size: 0.875rem;
        }
    }
    .b-3text-3 {
        width: 7.5rem;
        height: 2.25rem;
        background: #C30A05;
        border-radius: 2px;
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.5rem;
        }
    }

    .card-inner-img {
        position: absolute;
        top: 5.8rem;
        right: 3rem;
        width: 10.25rem !important;
        height: 8.375rem !important;
    }

    .center-inner-img {
        width: 24.375rem !important;
        height: 18.75rem !important;
    }

    .center-inner-content {
        width: 40.31rem;
        .title {
            font-size: 2.5rem;
            font-weight: 600;
            color: #000;
            position: relative;
        }
        .title::after {
            content: '';
            position: absolute;
            bottom: -1.5rem;
            left: 0;
            width: 6.25rem;
            height: 0.25rem;
            background-color: #C30A05;
            border-radius: 0.125rem;
        }
        .intro {
            font-weight: 400;
            font-size: 0.875rem;
            color: #333333;
            line-height: 1.5rem;
            margin-top: 2.75rem;
        }
        .applyBtn {
            width: 7.5rem;
            height: 2.5rem;
            background: #C30A05;
            border-radius: 2px;
            color: #fff;
            font-weight: 500;
            font-size: 0.875rem;
            text-align: center;
            line-height: 2.5rem;
            margin-top: 2.875rem;
            cursor: pointer;
        }
    }

    .box-5 {
        h2 {
            margin: 0;
        }
        .box-cont {
            padding-top: 6.25rem !important;
            .box-5-list {
                margin-top: 7.875rem;
                .box-5-li {
                    margin-right: 1.25rem;
                    margin-bottom: 1.25rem;
                    .box-5-content {
                        width: 100%;
                        height: 100%;
                        background-color: #C30A05;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        img {
                            width: 3.75rem;
                            height: 3.75rem;
                        }
                        .txt {
                            font-weight: 500;
                            color: #fff;
                            font-size: 1rem;
                            margin-top: 0.875rem;
                            background-color: none;
                        }
                    }
                }
                .box-5-li:hover {
                    .box-5-span {
                        animation: hide 0.2s linear forwards;
                    }
                    .box-5-content {
                        animation: show 0.2s linear forwards;
                    }
                }
            }
        }
    }

    @keyframes hide {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 0.7;
        }
    }
</style>
