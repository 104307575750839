import request from '../utils/request'

// 我的申请
export function myApplicationList(data) {
	return request({
		url: '/manager/enterprisePortal/myApplication',
		method: "get",
		params:data
	})
}


// 小微企业-贷款进度明细 && 申请数据查看
export function enterprisePortalDetail(id) {
	return request({
		url: `/manager/enterprisePortal/${id}`,
		method: "get",
	})
}


// 小微企业-修改草稿件
export function getEnterprisePortalDetail(id) {
	return request({
		url: `/manager/enterprisePortal/applicationGetInfo/${id}`,
		method: "get",
	})
}

// 个人-贷款进度明细 
export function getentrepreneurInfoGatewayDetail(id, isPartner) {
	return request({
		url: `/manager/entrepreneurInfoGateway/${id}`,
		method: "get",
		params: {
			isPartner: isPartner
		}
	});
}


// 数据申请查看
export function getEntrepreneurDoorDtoPerson(id) {
	return request({
		url: `/manager/entrepreneurInfoGateway/getEntrepreneurDoorDto/${id}`,
		method: "get",
	})
}