<script></script>
<template>
    <footer>
        <div class="title">
            <img src="/src/assets/login/img-logo-1.png" alt="">
            湖北省创业担保贷款门户申请系统
        </div>
        <div class="copyright">©********股份有限公司 版权所有 鄂ICP备12345678号-1</div>
    </footer>
</template>
<style scoped lang="scss">
    footer{
        width: 100%;
        height: 5.25rem;
        line-height: 5.25rem;
        background: #141A21;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        .title {
            font-size: 1.25rem;
            color: #fff;
            text-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.5);
            font-family: Georgia, 'Times New Roman', Times, serif;
            margin-right: 29rem;
            position: relative;
            padding-left: 2.5rem;
        }
        .title img {
            width: 2.125rem;
            height: 2.125rem;
            // border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        .copyright {
            font-size: 0.875rem;
            color: #ddd;
            font-weight: 400;
        }
    }

</style>