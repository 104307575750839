
import axios from 'axios';
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from "@/utils/auth";
import { useRouter } from 'vue-router'

const router = useRouter()

const service = axios.create({
  baseURL: import.meta.env.VITE_TINTMCE_BASE_URL,
  timeout: 50000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' }
});

service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, error => {
  Promise.reject(error);
});

let isLoin = false;
service.interceptors.response.use(response => {
    if (response.data.code == 401 && !isLoin ) {
        isLoin = true;
        removeToken("token");
        removeToken("user");
        removeToken("roles");
        removeToken("Admin-Token_institution")
        ElMessage({
            type: 'error',
            message: "登录超时，请重新登录",
        })
        setTimeout(()=>{
            window.location.href = "/login"
        }, 1000)
        return;
    }
    if (response.data.code == 401) {
        removeToken("token");
        removeToken("user");
        removeToken("roles");
        removeToken("Admin-Token_institution")
        return;
    }
  //判断code码
  return response.data;
},error => {
    if(error && error.response){
        switch(error.response.data){
            case 403:
                error.message = '拒绝访问'
                break;
            case 502:
                error.message = '服务器访问错误'
                break;
            default:
                error.message = `连接错误${error.message}`

        }
    }else{
        error.message = '响应超时，请刷新当前页面'
    }

    ElMessage.error({message: error.message})
    return Promise.reject(error);
    // return Promise.resolve(error.response)
});

export default service;
