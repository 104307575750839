<script setup>
    import { useRouter } from 'vue-router'
    import { onMounted } from 'vue';
    import { getEntrepreneurDoorDtoPerson } from "@/api/applyList.js";
    import { deptList, getDeptList , personCateList, getPersonCateList, } from "@/api/certificateList.js";
    import { ElMessage } from "element-plus";
    const router = useRouter()
    const props = defineProps(['id']);
    const emit = defineEmits(['busNext', 'busSubmit'])
    const info = ref({});
    const currentPersonCate = ref('');
    const count = ref(5);
    const getDetailInfo = (id) => {
        getEntrepreneurDoorDtoPerson(id).then(res => {
            if (res.code == 200) {
                info.value = res.data;
                currentPersonCate.value = personCateList.value.filter(item =>{ return item.dictValue == info.value.loanPersonCategory?item.dictLabel:''})
            } else {
                ElMessage.error(res.msg);
            }
        })
    }
    // 获取地址
    const getAddressForperson = (idList) => {
        if (!idList) return;
        let dList = deptList.value;
        let address = "";
        idList = idList.split(',');
        for (let i = 0; i < dList.length; i++) {
            if (idList[0] == dList[i].id) {
                address += dList[i].label;

                if (dList[i].children) {
                    let c1 = dList[i].children;

                    for (let i = 0; i < c1.length; i++) {
                        if (idList[1] == c1[i].id) {
                            address += c1[i].label;
                            if (c1[i].children) {
                                let c2 = c1[i].children;
                                for (let i = 0; i < c2.length; i++) {
                                    if (idList[2] == c2[i].id) {
                                        address += c2[i].label;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return address;
    };
    const busNext = () => {
        emit('busNext')
    };
    const busSubmit = () => {
        if (count.value > 0) return;
        emit('busSubmit')
    };
    onMounted(() => {
        getDeptList();
        getPersonCateList();
        getDetailInfo(props.id);
        let timer = setInterval(() => {
            count.value--;
            if (count.value <= 0) {
                clearInterval(timer);
            }
        }, 1000);
     })
</script>

<template>
    <div class="layout">
        <div class="banner"><img src="/src/assets/businessPersonInfo/img-banner1.png"/></div>

        <div class="all">
            <div class="position">您当前的位置：<label @click="router.push('/')">首页</label> / <i>个人担保贷款承诺书</i></div>
            <div class="cont">
                <h2>个人担保贷款承诺书</h2>
                创业户姓名：{{ info.name }} ({{ info.sex == 0 ? '男' : '女' }})<span style="margin-left: 5rem;">身份证号：421003196109103276</span> <br>
                常住地址: {{ getAddressForperson(info.permanentAddress) }} {{ info.permanentAddressDetails }}<br>
                现就办理创业担保贷款事宜作出如下承诺:  <br>
                1、本人创业项目真实有效;  <br>
                2、本人身份为：{{ currentPersonCate[0]?.dictLabel }};  <br>
                3、本人贷款前夫妻双方无五万元以上个人经营性贷款;  <br>
                4、本人非公职人员。 以上承诺事项属实，如有隐瞒，本人承担一切法律责任。 <br>

                <div class="btn-list">
                    <span @click="busNext">上一页</span>
                    <span @click="busSubmit">我已阅读并承诺 <template v-if="count > 0">({{ count }})</template></span>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.all{
    min-height: 80vh;
}
.position{
    color: rgba(0,0,0,0.45);
    font-size: 0.875rem;
    margin: 1.25rem auto;
    width: 77rem;
    position: static;
    left: 0;
    transform: none;
}

.position label{
    cursor: pointer;
}
.cont{
    width: 73.75rem;
    height: 36.93rem;
    margin: 0 auto;
    color: #333333;
    font-size: 0.87rem;
    line-height: 2.5rem;
    border: 10px solid #E7EFFF;
    padding: 1.25rem;
}
.cont h2{
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}


.btn-list{
    text-align: center;
    margin: 3rem 0;
    white-space: nowrap;
}

.btn-list span:last-child{
    padding:0 2rem
}
</style>
