<script setup>
 import { onMounted } from 'vue';
 import { useRouter,useRoute } from 'vue-router'
 import { myApplicationList } from '@/api/applyList.js'
 import { personCateList,getPersonCateList,getEntrepreneurList,entrepreneurList} from '@/api/certificateList.js'

 const router = useRouter()
 const route = useRoute()
 const goDetail = (item, type) =>{
    // loanEntrepreneurType 1.个人及合伙人创业担保贷款2.小微企业
    if (item.loanEntrepreneurType == '小微企业') {
        router.push({
            path:'/pDetailEntri',
            query:{
                id:item.id,
                type:2,

            }
        })
    } else {
        router.push({
            path:'/pDetail',
            query:{
                id: item.id,
                type: 1,
                isPartner: type ? type : 0
            }
        })
   }
}

const goData = (item) =>{
    // type 1.个人及合伙人创业担保贷款2.小微企业
    let reviewStatus = item.reviewStatus;
    let statusArr = ['初审未通过', '人社复审未通过', '担保机构初审未通过', '担保机构复审未通过', '银行初审未通过', '银行复审未通过'];
    let index = statusArr.indexOf(reviewStatus);
    if(item.loanEntrepreneurType == '小微企业'){
        router.push({
            path:'/dataListCompany',
            query:{
                id: item.id,
                type: 2,
                canEdit: index >= 0 ? 1 : 2
            }
        })
    } else {
        router.push({
            path:'/dataList',
            query:{
                id: item.id,
                type: 1,
                canEdit: index >= 0 ? 1 : 2
            }
        })
    }
}

const tabId = ref(null)
const setTabId = (e) =>{
    tabId.value = e;
    loading.value = true;
    getMyApplicationList()
}

// 列表获取
const list = ref([])
const pageNumber = ref(1)
const total = ref(0);
const loading = ref(true);
const getMyApplicationList = async () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    let params = {
        status:tabId.value,
        entrepreneurType:1,
        pageNum:pageNumber.value,// 当前页数
        pageSize:10
    }
    try {
        const res = await myApplicationList(params)
        if (res.code === 200) {
            list.value = res.rows,
            total.value = res.total;
        }
        loading.value = false;
    } catch (e) {
        console.log(e, 'this is error~');
    }
}
// 显示红色字体的状态
const statusErrorList = ['初审未通过','人社复审未通过','担保机构初审未通过','担保机构复审未通过','银行初审未通过','银行复审未通过','财政初审未通过','财政复审未通过','待还款']
// 显示正常颜色的状态
const statusCommon = ['已提交待审核','人社复审待审核','担保机构待初审','担保机构复审待审核','银行初审待审核','银行复审待审核','财政初审待审核','银行待放款','财政复审待审核','担保机构/银行初审待审核','待贴息']
// 显示已经完整颜色的状态 --已经完成
const statusDone = ['已完结','草稿件']
// 颜色 list
const classList = ['error','done','success']

const currentColor = ref('')
const getStatusColor = (e) =>{
    if(e =='初审未通过'|| e == '人社复审未通过'|| e == '担保机构初审未通过'|| e =='担保机构复审未通过'|| e =='银行初审未通过'|| e =='银行复审未通过'|| e =='财政初审未通过'|| e == '财政复审未通过'|| e =='待还款'){
        return classList[0]
    }else if( e=='已提交待审核' || e =='人社复审待审核' || e =='担保机构待初审' || e =='担保机构复审待审核'|| e =='银行初审待审核'|| e =='银行复审待审核'|| e =='财政初审待审核'|| e =='银行待放款'|| e =='财政复审待审核'|| e =='担保机构/银行初审待审核'|| e =='待贴息'){
        currentColor.value = ''
    }else if(e=='已完结'|| e=='草稿件'){
        return classList[1]
    }else {
        return ''
    }
}

// 如果是草稿件，才跳转
const goEditDetail = (item) =>{
    if(item.loanEntrepreneurType == '小微企业'){
        router.push({
            path:'/company',
            query:{
                id:item.id,
                type:2
            }
        })
    }else{
        router.push({
            path:'/busInfo',
            query:{
                id:item.id,
                type:1
            }
        })
    }
}

// 分页
const handleChangeList = (e) =>{
    pageNumber.value = e;
    loading.value = true;
    getMyApplicationList()
}

onMounted(() =>{
    getPersonCateList()
    getEntrepreneurList()
    if(route.query.tabId){
        tabId.value = route.query.tabId
    }

    setTimeout(() =>{
        getMyApplicationList()
    },1000)

})
</script>
<template>
    <div>
        <h2 id="top"><span></span>我的申请</h2>
        <ul class="tab">
            <li :class="tabId == null?'avtive':''" @click="setTabId(null)">全部</li>
            <li :class="tabId == 1?'avtive':''" @click="setTabId(1)">办理中</li>
            <li :class="tabId == 2?'avtive':''" @click="setTabId(2)">已办结</li>
            <li :class="tabId == 0?'avtive':''" @click="setTabId(0)">草稿件</li>
        </ul>

        <div class="list-box" v-if="list.length > 0" v-loading="loading">
            <div class="list" v-for="(item, index) in list" :key="index"  @click="(tabId == 0 || item.reviewStatus=='草稿件')?goEditDetail(item):''">
                <div class="title">
                    <span>办件编码：{{item.serialNumber}}</span>
                    <span :class="`tip ${getStatusColor(item.reviewStatus)} ${currentColor}`">{{item.reviewStatus}}</span>
                </div>

                <div class="box">
                    <div class="text-1">申请贷款类型：<i>{{item.loanEntrepreneurType}}</i></div>
                    <template v-if="item.loanEntrepreneurType == '小微企业'">
                        <div class="text-1">创业扶持人员：<i>{{(entrepreneurList.filter(e =>{ return e.dictValue== item.personnelCategory?e.dictLabel:''}))[0]?.dictLabel }}</i></div>
                    </template>
                    <template v-else>
                        <!--  -->
                        <div class="text-1">创业担保贷款人员类别：<i>{{ (personCateList.filter(e =>{ return e.dictValue== item.loanPersonCategory?e.dictLabel:''}))[0]?.dictLabel }}</i></div>
                    </template>
                    <template v-if="item.loanEntrepreneurType == '小微企业'">
                        <div class="text-1">企业名称：<i>{{ item.names }}</i></div>
                        <div class="text-1">统一信用代码/组织机构代码：<i>{{ item.code }}</i></div>
                    </template>
                    <template v-else>
                        <div class="text-1">姓名：<i>{{ item.names }}</i></div>
                        <div class="text-1">身份证号：<i>{{ item.code }}</i></div>
                    </template>
                    <div class="text-1">申请金额：<i>{{ item.loanAmount }}万元</i></div>
                    <div class="text-1">申请时间：<i>{{ item.time }}</i></div>
                </div>
                <div class="bottom" v-if="tabId != 0 && item.reviewStatus!='草稿件'">
                    <span @click="goDetail(item)">贷款进度明细</span>
                    <label></label>
                    <span @click="goData(item)">申请数据查看</span>
                </div>
                <template v-if="item.loanEntrepreneurType == '个人及合伙人创业担保贷款' && tabId != 0 && item.reviewStatus!='草稿件'">
                    <div class="bottom" v-for="(itm, idx) in item.partnerDtos" :key="idx">
                        <span>合伙人：{{ itm.partnerName }}</span>
                        <label></label>
                        <span @click="goDetail(itm, '1')">贷款进度明细</span>
                    </div>
                </template>
            </div>
        </div>

        <div class="noData" v-if="list.length == 0">暂无数据</div>
        <div style="width: 20rem;margin: 5rem auto;" v-if="list.length > 0 && total > 10" hide-on-single-page="true">
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleChangeList"/>
        </div>
        
    </div>
</template>

<style scoped>
:deep(.el-pagination.is-background .el-pager li:hover) {
    color: #C30A05 !important;
}
:deep(.el-pagination.is-background .el-pager li.is-active) {
    background-color: #C30A05 !important;
}
:deep(.el-pagination.is-background .el-pager li.is-active:hover) {
    color: #fff !important;
}
ul{
    margin: 0;
    padding: 0;
}
h2 span{
    height: 4px;
    border-left: 4px solid #C30A05;
    display: inline-block;
    vertical-align: middle;
}

.tab{
    margin: 1.5rem 0;
}
.tab li{
    display: inline-block;
    width: 5rem;
    height: 2rem;
    line-height: 2rem;
    border: 1px solid #D9D9D9;
    color: #333333;
    text-align: center;
    font-size: 0.87rem;
    border-right: 0;
    cursor:pointer
}
.tab li:last-child{
    border-right: 1px solid #D9D9D9;
}
.tab li.avtive{
    color: #ffffff;
    background-color: #C30A05;
}

.list{
    border: 1px solid #E8EAED;
    font-size: 0.87rem;
    margin-bottom: 1.5rem;
}

.title{
    background-color: #E8EAED;
    padding: 0.5rem 1rem;
    color: #666666;
}

.tip{
    float: right;
    color: #333333
}
.error{
    color: #E60707;
    font-weight: 500;
}

.success{
    color: #09A68A;
}
.done{
    color: #999999;
}

.right h2 span{
    border-left: 4px solid #C30A05;
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
    margin-right: 0.87rem;
}
.right h2{
    font-weight: 600;
    color: #333333;
    border-bottom: 1px solid #E8EAED;
    padding-bottom: 1.5rem;
    font-size: 1rem;
}

.box{
    color: #666666;
}
.text-1 i{
    font-style: normal;
    color: #333333;
    font-weight: 600;
}
.text-1{
    width: 45%;
    display: inline-block;
    margin: 0.5rem 0;
    padding: 0.8rem 1rem;
}

.bottom{
    border-top: 1px solid #E8EAED;
    height: 3rem;
    line-height: 3rem;
}
.bottom span,.bottom label{
    display: inline-block;
    width: 39%;
    text-align: center;
    color: #C30A05;
    cursor: pointer;
    font-weight: 500;
}

.bottom label{
    width: 3px;
    height: 10px;
    border-left:3px solid #E8EAED ;
}

.noData{
    text-align:center;
    color:#666666;
    height: 25rem;
    line-height: 15rem;
}
</style>