<script setup>
    import shortLine from '@/assets/personalCenter/img-115.png'
    import { onMounted } from 'vue';
    import { useRoute } from 'vue-router'
    import { getentrepreneurInfoGatewayDetail } from '@/api/applyList.js'
   
    const route = useRoute();
    const isPartner = ref('');
    const loading = ref(true);
    // 获取详情--个人
    const detail = ref({});
    const bankStep = ref(0);
    const guaranteeStep = ref(0);
    const fiscalStep = ref(0);
    const getDetailPersonall = async () => {
        const id = route.query.id;
        const isPartner = route.query.isPartner;
        try {
            const res = await getentrepreneurInfoGatewayDetail(id,isPartner);
            if (res.code === 200) {
                detail.value = res.data;
                let bankProcess = res.data.bankProcess;
                let guaranteeProcess = res.data.guaranteeProcess;
                if (guaranteeProcess <= 4 && bankProcess == 0) {
                    guaranteeStep.value = 3;
                } else if (guaranteeProcess == 4 && bankProcess < 4) {
                    guaranteeStep.value = 3;
                } else if (guaranteeProcess < 4 && bankProcess == 4) {
                    guaranteeStep.value = 5;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine < res.data.guaranteeProcessTimeReexamine) {
                    guaranteeStep.value = 5;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine > res.data.guaranteeProcessTimeReexamine) {
                    guaranteeStep.value = 3;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine == res.data.guaranteeProcessTimeReexamine) {
                    guaranteeStep.value = 3;
                };

                if (bankProcess <= 4 && guaranteeProcess == 0) {
                    bankStep.value = 3;
                } else if (bankProcess == 4 && guaranteeProcess < 4) {
                    bankStep.value = 3;
                } else if (bankProcess < 4 && guaranteeProcess == 4) {
                    bankStep.value = 5;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine > res.data.guaranteeProcessTimeReexamine) {
                    bankStep.value = 5;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine < res.data.guaranteeProcessTimeReexamine) {
                    bankStep.value = 3;
                } else if (bankProcess == 4 && guaranteeProcess == 4 && res.data.bankProcessTimeReexamine == res.data.guaranteeProcessTimeReexamine) {
                    bankStep.value = 5;
                };

                if (guaranteeProcess == 4 && bankProcess == 4) {
                    fiscalStep.value = 7;
                };
                getStatusColor();
                loading.value = false;
            };
        } catch (e) {
            console.log(e, 'this is error~');
        };
    };

    // 人社 审核 
    const societyProcessList = { 0:'初审待审核', 1:'初审未通过', 2:'复审待审核', 3:'复审未通过', 4:'复审已通过' };
    //担保机构审核
    const guaranteeProcessList = { 0:'初审待审核', 1:'初审未通过', 2:'复审待审核', 3:'复审未通过', 4:'复审已通过' };
    //银行审核
    const bankProcessList = { 0:'初审待审核', 1:'初审未通过', 2:'复审待审核', 3:'复审未通过', 4:'复审已通过' };
    //银行放款状态
    const bankProcessLoanList = { 0:'默认值 审核未通过情况下', 1:'待放款', 2:'已放款', 3:'待还款', 4:'已结清' };
    //财政部门审核
    const fiscalProcessList = { 0:'初审待审核', 1:'初审未通过', 2:'复审待审核', 3:'复审未通过', 4:'待贴息（复审通过）', 5:'贴息完结' };

    
    // 显示红色字体的状态
    const statusErrorList = [ '初审未通过', '人社复审未通过', '担保机构初审未通过', '担保机构复审未通过', '银行初审未通过', '银行复审未通过', '财政初审未通过', '财政复审未通过', '待还款' ];
    // 显示正常颜色的状态
    const statusCommon = [ '已提交待审核', '人社复审待审核担保机构待初审', '担保机构复审待审核', '银行初审待审核', '银行复审待审核', '财政初审待审核', '银行待放款', '财政复审待审核', '担保机构/银行初审待审核', '待贴息'];
    // 显示已经完整颜色的状态 --已经完成
    const statusDone = [ '已完结' ];
    // 颜色 list
    const classList = ['error', 'done', 'success'];

    // 设置颜色
    const currentColor = ref('');
    const getStatusColor = () => {
        statusDone.map(itm => {
            if(itm == detail.value.reviewStatus) {
                currentColor.value = classList[1];
            };
        });
        statusErrorList.map(itm => {
            if(itm == detail.value.reviewStatus) {
                currentColor.value = classList[0];
            };
        });
        statusCommon.map(itm => {
            if(itm == detail.value.reviewStatus) {
                currentColor.value = classList[1];
            };
        });
        return currentColor.value;
    };

    onMounted(() => {
        if (route.query.isPartner) {
            isPartner.value = route.query.isPartner;
        };
        if(route.query.id && route.query.type){
            getDetailPersonall();
        };
    });
</script>
<template>
    <div v-loading="loading">
        <h2><span></span>{{ isPartner == 1 ? '合伙人' : '' }}贷款基本信息</h2>
        <ul class="top">
            <li><span>贷款类型</span>
                <label>个人及合伙人创业担保贷款申请</label>
            </li>
            <li><span>申请时间</span><label>{{ detail.applicationDate }}</label></li>
            <li><span>审批状态</span><label :class="currentColor">{{ detail.reviewStatus }}</label></li>
            <li><span>当前审批部门</span><label>{{detail.auditDepartment}}</label></li>
            <li><span>办件编码</span><label>{{ detail.serialNumber?detail.serialNumber:'' }}</label></li>
        </ul>

        <h2 class="h2-2"><span></span>贷款信息流程</h2>
        <div class="list-box">
            <div class="line-border"></div>
            <div class="list">
                <template  v-if="detail.bankProcess == 4 && detail.guaranteeProcess == 4">
                    <ul v-if="detail.fiscalProcess == 5">
                        <li v-if="(fiscalStep + 1) % 2 == 0">贴息完结<label></label><i  class="stepNum">{{ fiscalStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ fiscalStep + 1 }}</i><label></label>贴息完结</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.fiscalProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.fiscalProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：财政部门审核</li>
                        <li>处理人：{{ detail.fiscalProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.fiscalProcessTimeReexamine}}</li>
                    </ul>

                    <ul v-if="detail.fiscalProcess <= 1">
                        <li v-if="fiscalStep % 2 == 0">{{ fiscalProcessList[detail.fiscalProcess]}}<label></label><i class="stepNum">{{ fiscalStep }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ fiscalStep }}</i><label></label>{{ fiscalProcessList[detail.fiscalProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.fiscalProcessOpinion" placement="top">
                                <label class="blue">{{ detail.fiscalProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：财政部门审核</li>
                        <li>处理人：{{ detail.fiscalProcessPeople}}</li>
                        <li>处理时间：{{ detail.fiscalProcessTime}}</li>
                    </ul>

                    <ul v-if="detail.fiscalProcess >= 2 && detail.fiscalProcess < 5">
                        <li v-if="(fiscalStep + 1) % 2 == 0">{{ fiscalProcessList[detail.fiscalProcess]}}<label></label><i class="stepNum">{{ fiscalStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ fiscalStep + 1 }}</i><label></label>{{ fiscalProcessList[detail.fiscalProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.fiscalProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.fiscalProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：财政部门审核</li>
                        <li>处理人：{{ detail.fiscalProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.fiscalProcessTimeReexamine}}</li>
                    </ul>

                    <ul v-if="detail.fiscalProcess >=2 ">
                        <li v-if="fiscalStep % 2 == 0">银行初审通过<label></label><i  class="stepNum">{{ fiscalStep }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ fiscalStep }}</i><label></label>财政部门初审通过</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.fiscalProcessOpinion" placement="top">
                                <label class="blue">{{ detail.fiscalProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：财政部门审核</li>
                        <li>处理人：{{ detail.fiscalProcessPeople}}</li>
                        <li>处理时间：{{ detail.fiscalProcessTime}}</li>
                    </ul>
                </template>

                <!-- 担保机构 -->
                <template v-if="detail.societyProcess == 4 && (detail.guaranteeProcess < 4 && detail.bankProcess == 4 ||
                    detail.guaranteeProcessTimeReexamine > detail.bankProcessTimeReexamine && detail.guaranteeProcess == 4 && detail.bankProcess == 4)">
                    <ul v-if="detail.guaranteeProcess == 4">
                        <li v-if="(guaranteeStep + 1) % 2 == 0">担保机构复审通过<label></label><i class="stepNum">{{ guaranteeStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ guaranteeStep }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTimeReexamine }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess <= 1">
                        <li v-if="guaranteeStep % 2 != 0"><img :src="shortLine" class="short-line"/><i class="stepNum">{{ guaranteeStep }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li v-else>{{guaranteeProcessList[detail.guaranteeProcess]}}<label></label><i class="stepNum">{{ guaranteeStep }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinion" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeople}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTime }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess >= 2 && detail.guaranteeProcess < 4">
                        <li v-if="(guaranteeStep + 1) % 2 != 0"><img :src="shortLine" class="short-line"/><i  class="stepNum">{{ guaranteeStep + 1 }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li v-else>{{guaranteeProcessList[detail.guaranteeProcess]}}<label></label><i class="stepNum">{{ guaranteeStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTimeReexamine }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess >=2">
                        <li v-if="guaranteeStep % 2 != 0"><img :src="shortLine" class="short-line"/><i class="stepNum">{{ guaranteeStep }}</i><label></label>担保机构初审通过</li>
                        <li v-else>{{ guaranteeProcessList[detail.guaranteeProcess] }}<label></label><img :src="shortLine" class="short-line rotate"/><i class="stepNum">{{ guaranteeStep }}</i></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinion" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeople}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTime }}</li>
                    </ul>
                </template>

                <!-- 银行审核 -->
                <!-- 
                    1.担保 = 0，银行 != 0，
                    2.担保 = 4，银行 <= 4，
                    3.银行 = 4，担保 <= 4
                 -->
                <template v-if="detail.societyProcess == 4 && (detail.guaranteeProcess == 0 && detail.bankProcess != 0 || detail.guaranteeProcess == 4 && detail.bankProcess <= 4 || detail.guaranteeProcess <= 4 && detail.bankProcess == 4)">
                    <ul v-if="detail.bankProcess == 4">
                        <li v-if="(bankStep + 1) % 2 == 0"><span style="white-space: nowrap;">银行复审通过({{ detail.bankProcessLoan == 1 ? '待放款' : '已放款' }})</span><label></label><i class="stepNum">{{ bankStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ bankStep + 1 }}</i><label></label>银行复审通过</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.bankProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.bankProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：银行审核</li>
                        <li>处理人：{{detail.bankProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.bankProcessTimeReexamine}}</li>
                    </ul>

                    <ul v-if="detail.bankProcess <= 1">
                        <li v-if="bankStep % 2 == 0">{{bankProcessList[detail.bankProcess]}}<label></label><i class="stepNum">{{ bankStep }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ bankStep }}</i><label></label>{{bankProcessList[detail.bankProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.bankProcessOpinion" placement="top">
                                <label class="blue">{{ detail.bankProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：银行审核</li>
                        <li>处理人：{{detail.bankProcessPeople}}</li>
                        <li>处理时间：{{ detail.bankProcessTime}}</li>
                    </ul>

                    <ul v-if="detail.bankProcess >= 2 && detail.bankProcess < 4">
                        <li v-if="(bankStep + 1) % 2 == 0">{{bankProcessList[detail.bankProcess]}}<label></label><i class="stepNum">{{ bankStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ bankStep + 1 }}</i><label></label>{{bankProcessList[detail.bankProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.bankProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.bankProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：银行审核</li>
                        <li>处理人：{{detail.bankProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.bankProcessTimeReexamine}}</li>
                    </ul>

                    <ul v-if="detail.bankProcess >=2 ">
                        <li v-if="bankStep % 2 == 0">银行初审通过<label></label><i  class="stepNum">{{ bankStep }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ bankStep }}</i><label></label>银行初审通过</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.bankProcessOpinion" placement="top">
                                <label class="blue">{{ detail.bankProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：银行审核</li>
                        <li>处理人：{{detail.bankProcessPeople}}</li>
                        <li>处理时间：{{ detail.bankProcessTime}}</li>
                    </ul>
                </template>

                <!-- 担保机构 -->
                <template v-if="detail.societyProcess == 4 && (detail.guaranteeProcess < 4 && detail.bankProcess == 0 || detail.guaranteeProcess == 4 && detail.bankProcess < 4 ||
                    detail.guaranteeProcessTimeReexamine <= detail.bankProcessTimeReexamine && detail.guaranteeProcess == 4 && detail.bankProcess == 4)">
                    <ul v-if="detail.guaranteeProcess == 4">
                        <li v-if="(guaranteeStep + 1) % 2 == 0">担保机构复审通过<label></label><i  class="stepNum">{{ guaranteeStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li v-else><img :src="shortLine" class="short-line"/><i class="stepNum">{{ guaranteeStep }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTimeReexamine }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess <= 1">
                        <li v-if="guaranteeStep % 2 != 0"><img :src="shortLine" class="short-line"/><i  class="stepNum">{{ guaranteeStep }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li v-else>{{guaranteeProcessList[detail.guaranteeProcess]}}<label></label><i  class="stepNum">{{ guaranteeStep }}<img :src="shortLine" class="short-line rotate"/></i></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinion" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeople}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTime }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess >= 2 && detail.guaranteeProcess < 4">
                        <li v-if="(guaranteeStep + 1) % 2 != 0"><img :src="shortLine" class="short-line"/><i  class="stepNum">{{ guaranteeStep + 1 }}</i><label></label>{{guaranteeProcessList[detail.guaranteeProcess]}}</li>
                        <li v-else>{{guaranteeProcessList[detail.guaranteeProcess]}}<label></label><i  class="stepNum">{{ guaranteeStep + 1 }}</i><img :src="shortLine" class="short-line rotate"/></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinionReexamine" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinionReexamine }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeopleReexamine}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTimeReexamine }}</li>
                    </ul>

                    <ul v-if="detail.guaranteeProcess >=2">
                        <li v-if="guaranteeStep % 2 != 0"><img :src="shortLine" class="short-line"/><i  class="stepNum">{{ guaranteeStep }}</i><label></label>担保机构初审通过</li>
                        <li v-else>{{ guaranteeProcessList[detail.guaranteeProcess] }}<label></label><img :src="shortLine" class="short-line rotate"/><i class="stepNum">{{ guaranteeStep }}</i></li>
                        <li>处理意见：
                            <el-tooltip class="item" effect="dark" :content="detail.guaranteeProcessOpinion" placement="top">
                                <label class="blue">{{ detail.guaranteeProcessOpinion }}</label>
                            </el-tooltip>
                        </li>
                        <li>处理部门：担保机构审核</li>
                        <li>处理人：{{detail.guaranteeProcessPeople}}</li>
                        <li>处理时间：{{ detail.guaranteeProcessTime }}</li>
                    </ul>
                </template>
                
                <!-- 人社 -->
                <ul v-if="detail.societyProcess >= 2 && detail.societyProcess < 4" >
                    <li>{{ societyProcessList[detail.societyProcess] }}<label></label><img :src="shortLine" class="short-line rotate"/><i class="stepNum">2</i></li>
                    <li>处理意见：
                        <el-tooltip class="item" effect="dark" :content="detail.societyProcessOpinionReexamine" placement="top">
                            <label class="blue">{{ detail.societyProcessOpinionReexamine }}</label>
                        </el-tooltip>
                    </li>
                    <li>处理部门：人社审核</li>
                    <li>处理人：{{detail.societyProcessPeopleReexamine}}</li>
                    <li>处理时间：{{ detail.societyProcessTimeReexamine }}</li>
                </ul>

                <ul v-if="detail.societyProcess == 4">
                    <li>人社复申通过<label></label><i class="stepNum">2</i><img :src="shortLine" class="short-line rotate"/></li>
                    <li>处理意见：
                        <el-tooltip class="item" effect="dark" :content="detail.societyProcessOpinionReexamine" placement="top">
                            <label class="blue">{{ detail.societyProcessOpinionReexamine }}</label>
                        </el-tooltip>
                    </li>
                    <li>处理部门：人社审核</li>
                    <li>处理人：{{detail.societyProcessPeopleReexamine}}</li>
                    <li>处理时间：{{ detail.societyProcessTimeReexamine }}</li>
                </ul>

                <ul v-if="detail.societyProcess <= 1">
                    <li>{{societyProcessList[detail.societyProcess]}}<label></label><i class="stepNum">1</i><img :src="shortLine" class="short-line rotate"/></li>
                    <li>处理意见：
                        <el-tooltip class="item" effect="dark" :content="detail.societyProcessOpinion" placement="top">
                            <label class="blue">{{ detail.societyProcessOpinion }}</label>
                        </el-tooltip>
                    </li>
                    <li>处理部门：人社审核</li>
                    <li>处理人：{{detail.societyProcessPeople}}</li>
                    <li>处理时间：{{ detail.societyProcessTime }}</li>
                </ul>

                <ul v-if="detail.societyProcess >=2 ">
                    <li><img :src="shortLine" class="short-line"/><i class="stepNum">1</i><label></label>人社初审已通过</li>
                    <li>处理意见：
                        <el-tooltip class="item" effect="dark" :content="detail.societyProcessOpinion" placement="top">
                            <label class="blue">{{ detail.societyProcessOpinion }}</label>
                        </el-tooltip>
                    </li>
                    <li>处理部门：人社审核</li>
                    <li>处理人：{{detail.societyProcessPeople}}</li>
                    <li>处理时间：{{ detail.societyProcessTime }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.top li {
    height: 3.5rem;
    line-height: 3.5rem;
    border: 1px solid #E8EAED;
}

.top li span{
    background-color: #E8EAED;
    display: inline-flex;
    width: 15.81rem;
    padding: 0 1.06rem;
    justify-content: center;
    font-weight: 600;
}
.top li label{
    display: inline-flex;
    justify-content: center;
    width: 65%;
}
.h2-2{
    margin-top: 3.62rem;
}
.right h2 span{
    border-left: 4px solid #C30A05;
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
    margin-right: 0.87rem;
}
.right h2{
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #E8EAED;
    padding-bottom: 1.5rem;
    font-size: 1rem;
}

.blue{
    color: #C30A05;
    font-weight: 600;
    max-width: 9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.error{
    color: #E60707;
}

.success{
    color: #09A68A;
}
.done{
    color: #999999;
}

.line-border{
    background: linear-gradient( 180deg, rgba(195,10,5,0) 0%, rgba(195,10,5,0.3) 97%, #C30A05 99%, rgba(195,10,5,0.3) 100%);
    width: 0.75rem;
    height: 90rem;
    margin: 5rem auto;
}

.list-box{
    position: relative;
}
.list{
    position: absolute;
    top:0;
    width: 55%;
    left: 22.5%;
}

.list ul li {
    color: #666666;
    font-size: 0.87rem;
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.list ul:nth-child(even){
    margin: 12rem 0 0;
    width: 13.5rem;
    text-align: right;
}

.list ul:nth-child(odd){
    position: absolute;
    left: 55.5%;
}

.list ul li:first-child{
    color: #333333;
    font-weight: 600;
}
.list ul li:first-child label{
    border-radius: 50%;
    display: inline-block;
    height: 4px;
    width: 4px;
    background-color: #C30A05;
    margin: 0 0.5rem;
}
.short-line{
    width: 1.87rem;
    height: 0.37rem;
    vertical-align: middle;
    margin: 0 0.5rem;
}

.rotate {
    transform: rotate(180deg);
}

.stepNum{
    font-style: normal;
    background-image: url('../../../assets/personalCenter/img-112.png');
    width: 1.87rem;
    height: 1.87rem;
    text-align: center;
    line-height: 1.87rem;
    color: #ffffff;
    background-size: 100% 100%;
    background-position: top center;
    display: inline-block;
}
</style>