<script setup lang="ts">
    import { onMounted } from 'vue';
    import type { FormRules } from 'element-plus'
    import { 
        socialOrganizationHubeiList,
        getSocialOrganizationHubeiList
     } from '@/api/certificateList.js'
    const formLabelAlign = reactive({
        inspectAnnually:null,
        enterpriseDirectory: null,
        socialOrganization: null,
        existingEmployee:null,
        conformTo:null,
    })

    const rules = reactive<FormRules>({
        existingEmployee:[
            {required: false, message:'请输入在职职工人数',trigger:'blur'},
            { 
                pattern: /^([0-9]{1,6})$/, 
                message: '在职职工人数最多位6位数', 
                trigger: 'blur' 
            },
        ],
        conformTo:[
            {required: false, message:'请输入申请条件人数',trigger:'blur'},
            { 
                pattern: /^([0-9]{1,5})$/, 
                message: '申请条件人数最多5位数', 
                trigger: 'blur' 
            },
        ],
    })

    onMounted(() =>{
        getSocialOrganizationHubeiList()
    })

    defineExpose({
        formLabelAlign
    })

</script>

<template>
    <div class="box-right">
        <h2><span></span>当年吸纳符合创业担保贷款申请条件人员情况</h2>
        <el-form
            label-position="top"
            label-width="100px"
            :model="formLabelAlign"
            :rules="rules">

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="小微企业是否在工商局年检">
                        <el-select v-model="formLabelAlign.inspectAnnually"  placeholder="请选择">
                            <el-option label="是" value="0" />
                            <el-option label="否" value="1" />
                        </el-select>

                    </el-form-item>
                    <el-form-item label="湖北社会组织">
                        <el-select v-model="formLabelAlign.socialOrganization"  placeholder="请选择">
                            <el-option v-for="item in socialOrganizationHubeiList" :label="item.dictLabel" :value="item.dictValue" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="小微企业名录">
                        <el-select v-model="formLabelAlign.enterpriseDirectory"  placeholder="请选择">
                            <el-option label="有" value="0" />
                            <el-option label="无" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业现有在职职工人数" prop="existingEmployee">
                        <el-input v-model="formLabelAlign.existingEmployee" maxlength="6" placeholder="请输入在职职工人数">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="当年新招用符合创业担保贷款申请条件人数" prop="conformTo">
                        <el-input v-model="formLabelAlign.conformTo" maxlength="5" placeholder="当年新招用符合创业担保贷款申请条件人数">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped>
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
.avatar{
    width: 11.87rem;
    height: 7.68rem;
    margin-right: 3.68rem;
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 0 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}
</style>

<style>
.el-input-group__append, .el-input-group__prepend{
    background-color: #ffffff !important;
    margin-left: -5px;
}
</style>