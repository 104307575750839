<script setup lang="ts">
    import { onMounted, reactive, ref, watchEffect } from 'vue'
    import type { FormInstance, FormRules } from 'element-plus'
    import { ElMessageBox,ElMessage } from 'element-plus'
    import { uploadImgUrlPost,headers } from '@/api/upload.js'
    import ImgOne from '@/assets/stepTwo/id-1.png'
    import ImgTwo from '@/assets/stepTwo/id-2.png'
    import axios from 'axios';
    import { idTypeList, getDictList} from '@/api/certificateList.js'
    import { getToken } from "@/utils/auth";
    import { deleteEntrepreneurDoorDtoPerson } from '@/api/index.js'
    import { downloadByFileId } from "@/api/index.js";

    const uploadHeaders =  {
        Authorization: "Bearer " + getToken(),
        'Content-Type':'multipart/form-data'
    }
    let formLabelAlign = reactive([{
        employeeName: null,
        employeeIdNumber: null,
        idType: null,
        idNumberUrl: null,
        idNumberUrlRead: null,
        idNumberOpposite: null,
        idNumberOppositeRead: null
    }])

    const rules = reactive<FormRules>({
        employeeName: [{required: true,message: '请填写员工信息',trigger: 'change'}],
        idType: [{required: true,message: '请选择证件类型',trigger: 'change'}],
        employeeIdNumber: [
            {required: true,message: '请输入证件号码',trigger: ['blur']},
            {
                pattern: /^([a-zA-Z0-9-]{1,18})$/,
                message: '请输入1-18位的数字和字母，支持中划线',
                trigger: 'blur'
            },
        ],
    })

    // 图片上传
    // const idNumberUrlImg = ref()
    const changeImge = (res,f,item) =>{
        console.log("item", item)
        let aimg = URL.createObjectURL(res.raw)
        item.idNumberUrlRead = aimg
        let formData = new FormData()
        formData.append('file',res.raw)
        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                item.idNumberUrl =res.data.msg;
              console.log("这里正在刷新数据 item.idNumberUrl", item.idNumberUrl)
            }
        })
    }

    // 图片上传 身份证反面
    // const idNumberOppositeRead = ref('')
    const changeImgeRever = (res,f,item) =>{
        let aimg = URL.createObjectURL(res.raw)
        item.idNumberOppositeRead = aimg
        // const file = f[0];
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                item.idNumberOpposite = res.data.msg;
              console.log("这里正在刷新数据 item.idNumberOpposite", item.idNumberOpposite)
            }
        })
    }

    const addEmployee = () =>{
        formLabelAlign.push({
            employeeName: null,
            employeeIdNumber: null,
            idType: null,
            idNumberUrl:null,
            idNumberUrlRead:null,
            idNumberOpposite:null,
            idNumberOppositeRead:null
        })
    }

    // 删除员工
    const minus = (item,index) =>{
        ElMessageBox.confirm('请问确定要删除吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            }).then(async() => {
                if(item.id){
                    let ids = item.id
                    let idsList:any = []
                    idsList.push(ids)
                    await deleteEntrepreneurDoorDtoPerson(idsList).then((res:any) =>{
                        if(res.code == 200){
                            ElMessage.success('删除成功')
                            formLabelAlign.splice(index,1)
                        }else{
                            ElMessage.error('删除失败')
                        }
                    })

                }else{
                    formLabelAlign.splice(index,1)
                }

        }).catch((e) => {
            console.log(e,'this is error msg')
        })
    }

    const props = defineProps(['employeeList','idNumber'])
    watchEffect(() =>{
        if(props && props.employeeList && props.employeeList.length > 0){
            formLabelAlign = props.employeeList;
            console.log("??????????????????")
        }
    })

    const idNum = ref(0)
    const checkoutIdNumber = (e, index) =>{
        if(!!e.target.value){
            let reg = /^([a-zA-Z0-9]{1,18})$/;
            if(!reg.test(e.target.value)){
                ElMessage.error('身份证号码输入有误，请检查')
                return false
            }
            if(e.target.value == props.idNumber){
                ElMessage.error('员工身份证不能与主体身份证相同')
                return false
            }
        }

        for (let i = 0; i < formLabelAlign.length; i++) {
            if (formLabelAlign[i].employeeIdNumber == e.target.value && i != index) {
                ElMessage.error('已有相同的员工身份证号码，请检查')
                return false;
            }
        }
    }

    const ruleFormRefThree = ref();
    defineExpose({
        formLabelAlign,
        ruleFormRefThree
    })

    onMounted(() =>{
        idNum.value = props.idNumber
        getDictList()
    })
</script>

<template>
    <div class="box-right">
        <h2><span></span>员工信息</h2>
        <el-form
            label-position="top"
            label-width="100px"
            :model="formLabelAlign"
            :rules="rules"
            ref="ruleFormRefThree">
            <template v-for="(item,index) in formLabelAlign" :key="'box'+index">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="员工姓名" :props="'formLabelAlign.'+index+'.employeeName'" :rules="rules.employeeName">
                            <el-input v-model="item.employeeName" placeholder="请输入员工姓名"/>
                        </el-form-item>

                        <el-form-item label="员工证件号码" :props="'formLabelAlign.'+index+'.employeeIdNumber'" :rules="rules.employeeIdNumber">
                            <el-input v-model="item.employeeIdNumber" placeholder="请输入员工证件号码" @blur="checkoutIdNumber($event, index)"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证件类型" :props="'formLabelAlign.'+index+'.idType'" :rules="rules.idType">
                            <el-select  placeholder="请选择证件类型" v-model="item.idType">
                                <el-option  v-for="item in idTypeList" :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="title">上传员工身份证正反面照片 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
                <div class="id-img">
                    <el-form-item label="">
                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            v-model="item.idNumberUrl"
                            :on-change="(res,f) => changeImge(res,f,item)">
                            <img v-if="item.idNumberUrlRead" :src="item.idNumberUrlRead" class="avatar" />
                            <img :src="ImgOne" v-else class="avatar"/>
                        </el-upload>

                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            v-model="item.idNumberOpposite"
                            :on-change="(res,f) => changeImgeRever(res,f,item)">
                            <img v-if="item.idNumberOppositeRead" :src="item.idNumberOppositeRead" class="avatar" />
                            <img :src="ImgTwo" v-else class="avatar"/>
                        </el-upload>
                    </el-form-item>
                </div>
                <div class="add del">
                    <div class="btn" @click="minus(item,index)">- 删除员工信息</div>
                </div>
            </template>
            <div class="oper-list">
                <div class="add" @click="addEmployee">+ 新增员工信息</div>
            </div>
        </el-form>

    </div>
</template>

<style scoped lang="scss">
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
.line{
    border-top: 1px solid #E8EAED;
    margin: 3rem 0;
}

.avatar{
    width: 11.87rem;
    height: 7.68rem;
    margin-right: 3.68rem;
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 0 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}

.add{
    color: #065BFF;
    line-height: 4.37rem;
    text-align: center;
    font-weight: 600;
    width: 10rem;
    margin: 0 auto;
}
.del{
    border-bottom:1px solid #cccccc;
    width: 100%;
    text-align: right;
    margin: 0 0 3rem 0;
    .btn {
        display: inline-block;
        cursor: pointer;
    }
}

.oper-list{
    text-align: center;
    margin-top:3rem;
    .add {
        cursor: pointer;
    }
}
</style>
