<script setup>
    import { onMounted   } from 'vue';
    import Avator from "@/assets/personalCenter/icon-user.png"
    import IconOne from "@/assets/personalCenter/icon_img1.png"
    import IconTwo from "@/assets/personalCenter/icon_img2.png"

    import IconOneIns from "@/assets/personalCenter/icon_img1-1.png"
    import IconTwoINs from "@/assets/personalCenter/icon_img2-2.png"
    import { useRouter,useRoute } from 'vue-router'

    const currentRoute = ref('')
    const router = useRouter()
    const route = useRoute()

    const tabId = ref(1)
    const getTab = (n) =>{
        tabId.value = n
        if(n == 1){
            router.push({path:'/baseInfo'})
        }else{
            router.push({path:'/applyList'})
        }
    }

    import Cookies from "js-cookie";
    // type ==0 个人  1 企业
    let userInfo ={}
    if(Cookies.get('user')){
        userInfo = JSON.parse(Cookies.get('user'))
    }

    watchEffect(() =>{
        currentRoute.value = router.currentRoute.value.name
        if(currentRoute.value == '我的申请' || currentRoute.value == '贷款进度明细-个人' || currentRoute.value == '贷款进度明细-小微企业'){
            tabId.value = 2
        }else{
            tabId.value = 1
        }
    })
</script>

<template>
    <div class="layout">
        <div class="banner"><img src="/src/assets/personalCenter/banner2.png"/></div>
        
        <div class="all">
            <div class="position" v-show="tabId == 1">您当前的位置：<label @click="router.push('/')">首页</label> / <label @click="router.push('/baseInfo')">个人中心</label> / <i>基本信息</i></div>
            <div class="position" v-show="tabId == 2">您当前的位置：<label @click="router.push('/')">首页</label> / <label @click="router.push('/baseInfo')">个人中心</label> / <i @click="router.push('/applyList')" v-show="currentRoute != '我的申请'">我的申请 / </i><i>{{ currentRoute }}</i></div>
            <div class="cont">
                <div class="left">
                    <p class="avator"><img :src="Avator"/>{{ userInfo.userName }}</p>
                    <div :class=" `box-l img-1 ${ tabId==1?'active':''} `" @click="getTab(1)"><img :src="tabId==1?IconOne:IconOneIns"/>基本信息</div>
                    <div :class="` box-l  ${ tabId==2?'active':''}`" @click="getTab(2)"><img :src="tabId==2?IconTwoINs:IconTwo">我的申请</div>
                </div>

                <div class="right">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.all{
    position: relative;
    background:url('../../assets/security/security_bg.png');
    background-repeat: no-repeat;
    margin-top: -0.3rem;
    background-size: 100% 25rem;
    padding-top: 1.25rem;
    background-position: bottom;
}

.position label{
    cursor: pointer;
}
.cont{
    display: flex;
    width: 73rem;
    /* top: 3rem; */
    margin:1rem auto 0 auto
}
.left,.right{
    background-color: #ffffff;
    height: 25.37rem;
    border-radius: 5px;
}

.right{
    width: 65rem;
    padding: 1.25rem;
    background: #ffffff;
    height: 100%;
}

.left{
    width: 13rem;
    margin-right: 1rem;
}

.avator{
    text-align: center;
    padding-top: 2.5rem;
    margin-bottom: 2.5rem;
    font-weight: 600;
}
.avator img{
    width: 5rem;
    height: 5rem;
    display: block;
    margin: 0 auto;
}

.box-l{
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 0 0 1rem;
    font-size: 0.87rem;
    vertical-align: middle;
    cursor: pointer;
}

.active{
    background-color: #F8FBFF;
    color: #C30A05;
    border-right: 4px solid #C30A05;
}

.box-l img{
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.62rem;
    color: #333333;
    font-size: 0.87rem;
    vertical-align: middle;
}
.img-1 img {
    width: 0.75rem;
    margin-right: 0.745rem;
}
</style>
