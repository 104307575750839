<script setup>
    import { useRouter } from 'vue-router'
    import { onMounted } from 'vue';
    import { deptList, getDeptList } from "@/api/certificateList.js";
    import { getEnterprisePortalDetail  } from '@/api/applyList.js';
    import { ElMessage } from 'element-plus';
    const router = useRouter();
    const emit = defineEmits(['busNext', 'busSubmit'])
    const props = defineProps(['id']);
    const info = ref({});
    const count = ref(5);
    const getDetailInfo = () => {
        const id = props.id;
        console.log(id);
        getEnterprisePortalDetail(id).then(res => {
            if (res.code == 200) {
                info.value = res.data;
            } else {
                ElMessage.error(res.msg);
            }
        })
    }
    onMounted(() => {
        getDeptList();
        getDetailInfo();
        let timer = setInterval(() => {
            count.value--;
            if (count.value <= 0) {
                clearInterval(timer);
            }
        }, 1000);
    })
    const busNext = () => {
        emit('busNext', 1)
    };
    const busSubmit = () => {
        if (count.value > 0) return;
        emit('busSubmit', 2)
    };

    // 获取地址
    const getAddressForperson = (idList) => {
    if (!idList) return;
    let dList = deptList.value;
    let address = "";
    idList = idList.split(',');
    for (let i = 0; i < dList.length; i++) {
        if (idList[0] == dList[i].id) {
        address += dList[i].label;

        if (dList[i].children) {
            let c1 = dList[i].children;

            for (let i = 0; i < c1.length; i++) {
            if (idList[1] == c1[i].id) {
                address += c1[i].label;
                if (c1[i].children) {
                let c2 = c1[i].children;
                for (let i = 0; i < c2.length; i++) {
                    if (idList[2] == c2[i].id) {
                    address += c2[i].label;
                    }
                }
                }
            }
            }
        }
        }
    }
    return address;
    };
</script>

<template>
    <div class="layout">
        <div class="banner"><img src="/src/assets/personalCenter/banner1.png" /></div>
        
        <div class="all">
            <div class="position">您当前的位置：<label @click="router.push('/')">首页</label> / <i> 小微企业担保贷款承诺书</i></div>
            <div class="cont">
                <h2>个人担保贷款承诺书</h2>
                小微企业法人姓名姓名：{{ info.representative }} <span class="ml3">身份证号：{{ info.idNumber }}</span> <br>
                经营地址: {{ getAddressForperson(info.businessAddress) }}{{ info.businessAddressDetails == null ? '' : info.businessAddressDetails }} <br>
                现就办理创业担保贷款事宜作出如下承诺:  <br>
                1、企业创业项目真实有效，属于《统计上大中小微企业划分办法(2017)》(国统字[2017]213号)规定的小型、微型企业;  <br>
                <span v-if="info.existingEmployee">
                    2、本企业现有职工人数{{ info.employees }}人，一年内新招用符合创业担保贷款申请条件的人员人数3人，占职工总人数的30%并签订1年以上劳动合同;  <br>
                </span>
                {{ info.existingEmployee ? 3 : 2 }}、本企业无拖欠职工工资、欠缴社会保险费等严重违法违规信用记录;  <br>
                {{ info.existingEmployee ? 4 : 3 }}、本人非公职人员。  <br>
                {{ info.existingEmployee ? 5 : 4 }}、如第三方机构审核企业不是小微企业的，企业全额退息。<br>
                以上承诺事项属实，如有隐瞒，本人承担一切法律责任。

                <div class="btn-list">
                    <span @click="busNext">上一页</span>
                    <span @click="busSubmit">我已阅读并承诺 <template v-if="count > 0">({{ count }})</template></span>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.all{
    min-height: 80vh;
}

.position{
    color: rgba(0,0,0,0.45);
    font-size: 0.875rem;
    margin: 1.25rem auto;
    width: 77rem;
    position: static;
    left: 0;
    transform: none;
}

.position label{
    cursor: pointer;
}

.cont{
    width: 73.75rem;
    height: 36.93rem;
    margin: 0 auto;
    color: #333333;
    font-size: 0.87rem;
    line-height: 2.5rem;
    border: 10px solid rgba(195,10,5,0.1);
    padding: 1.25rem;
}

.cont h2{
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}

.btn-list{
    text-align: center;
    margin: 3rem 0;
    white-space: nowrap;
}

.btn-list span:last-child{
    padding:0 2rem
}
.ml3 {
    margin-left: 5rem;
}
</style>
