<script setup>
import { ref, watch } from 'vue';
import StepOone from './stepOne/index.vue';
import StepTwo from './stepTwo/index.vue';
import StepThree from './stepThree/index.vue';
import StepFour from './stepFour/index.vue';
import Letter from './letter/index.vue'
import { applicationApproval, companyPresent, downloadByFileId } from '@/api/index.js';
import { useRouter, useRoute } from 'vue-router';
import Done from '../../assets/stepFour/img-four.png';
import { ElMessage } from 'element-plus';
import { getEnterprisePortalDetail  } from '@/api/applyList.js';

const router = useRouter();
const route = useRoute();
const steps = ref(1);
const parterIdNumber = ref(0); // 获取第一个步骤里面的法人身份证号码
const reData = ref({});
const busNext = (e) => {
    showLetter.value = false;
    console.log(e);
}

// 上一页
const goBack = () => {
    if (steps.value <= 1) {
        return false;
    } else {
        steps.value --;
    };

   if (reData.value.id) {
        reData.value = reData.value;
        if (steps.value == 3) {
            getDetailInfoBeforeSteps(reData.value.id);
        };
    };
};

// 计时器
const count = ref(5);
const timeCount = () => {
    if (count.value === 0) {
        clearInterval(timeCount);
        router.push('/applyList');
        return false;
    } else {
        count.value--;
    };
};

const stepOneref = ref(null);
const stepTworef = ref(null);
const stepThreeref = ref(null);
const stepFourref = ref(null);

// 查询接口
const getDetailInfoBeforeSteps = async (id) => {
    try {
        const res = await getEnterprisePortalDetail(id);
        if (res.code === 200) {
            stepThreeref.value.formLabelAlign.length = 0;
            // stepThreeref?.value?.formLabelAlign?.push(...res.data.entrepreneurEmployee);
          if (res.data.entrepreneurEmployee) {
            console.log("33333333进来")
            //这里因为使用了异步获取图片，会导致多个员工的时候，顺序不一致的问题
            res.data.entrepreneurEmployee.forEach(async item => {
              if (item.idNumberUrl) {
                const response = await downloadByFileId(item.idNumberUrl);
                item.idNumberUrlRead = URL.createObjectURL(response);
              }
              if (item.idNumberOpposite) {
                const response = await downloadByFileId(item.idNumberOpposite);
                item.idNumberOppositeRead = URL.createObjectURL(response);
              }
              stepThreeref?.value?.formLabelAlign?.push(...[item]);
            })
            // stepThreeref?.value?.formLabelAlign?.push(...res.data.entrepreneurEmployee);
          };
        };
    } catch (e) {
        console.log(e, 'this is error~');
    };
};

// 暂存
const disBtn = ref(0);
const temporaryStorageData = async (t) => {
    parterIdNumber.value = stepOneref?.value?.formLabelAlign?.idNumber;
    let timer = setInterval(() => {
        disBtn.value++;
        if (disBtn.value > 2) {
            clearInterval(timer);
            disBtn.value = 0;
        };
    }, 1000);

    stepOneref?.value?.ruleFormRef?.validate((valid) => {
        if (valid) {
            staging(t);
        };
    });
};

const staging = async(t) => {
    let three = stepThreeref?.value?.formLabelAlign;
    let validateThree = three.some(item => !!item.employeeName || !!item.employeeIdNumber || !!item.idType || !!item.idNumberUrl || !!item.idNumberOpposite);
    if (steps.value === 3) {
        if (validateThree) {
            let theThird = three.every(item => !!item.employeeName && !!item.employeeIdNumber && !!item.idType && !!item.idNumberUrl && !!item.idNumberOpposite);
            if (!theThird) {
                ElMessage.error('请完善员工信息');
                return false;
            };
        };

        let reg = /^([a-zA-Z0-9-]{1,18})$/;
        for (let i = 0; i < three.length; i++) {
            if (!reg.test(three[i].employeeIdNumber)) {
                ElMessage.error('身份证号码输入有误，请检查');
                return false;
            }
            if (three[i].employeeIdNumber == stepOneref?.value?.formLabelAlign?.idNumber) {
                ElMessage.error('员工身份证号码不能与主体身份证号码相同，请检查');
                return false;
            }
            for (let j = 0; j < three.length; j++) {
                if (three[i].employeeIdNumber == three[j].employeeIdNumber && i != j) {
                    ElMessage.error('已有相同的员工身份证号码，请检查');
                    return false;
                };
            };
        };
    };
    let stepFour = stepFourref?.value?.formLabelAlign;
    if (steps.value == 4) {
        if(!stepFour.businessUrl || !stepFour.idNumberUrl || !stepFour.yearWagesOutside || !stepFour.taxPaymentCertificate || !stepFour.employeeRegistration ||  !stepFour.idNumberOpposite){
            ElMessage.error('有材料漏传，请检查');
            return false;
        };
    };

    let organizationCode = (stepOneref?.value?.formLabelAlign?.SOCode?.length == 9 || stepOneref?.value?.formLabelAlign?.SOCode?.length == 10) ? stepOneref?.value?.formLabelAlign?.SOCode : '';
    let params = {
        ...stepOneref?.value?.formLabelAlign,
        establishment: stepOneref?.value?.formLabelAlign.establishment.replaceAll('-', ''),
        businessAddress: stepOneref?.value?.formLabelAlign.businessAddress.join(),
        organizationCode: organizationCode,
        socialCode: stepOneref?.value?.formLabelAlign?.SOCode?.length == 18 ? stepOneref?.value?.formLabelAlign?.SOCode : '',
        id: reData.value.id ? reData.value.id : null
    };
    // params = Object.assign(params,stepTworef?.value?.formLabelAlign)
    // params = Object.assign(params, stepFourref?.value?.formLabelAlign)

    if (steps.value == 1) {
        params = Object.assign(params, { currentSubmissionStatus: 1 });
        params.currentSubmissionStatus = 1;
    };

    if (steps.value == 2) {
        params = Object.assign(params, { currentSubmissionStatus: 2 });
        params = Object.assign(params, stepTworef?.value?.formLabelAlign);
        params.currentSubmissionStatus = 2;
    };

    if (steps.value == 3) {
        params = Object.assign(params, { currentSubmissionStatus: 3, entrepreneurEmployee: stepThreeref?.value?.formLabelAlign });
        params.currentSubmissionStatus = 3;
    };

    if (steps.value == 4) {
        params = Object.assign(params, stepFourref?.value?.formLabelAlign);
        params = Object.assign(params, { currentSubmissionStatus: 4 });
        params.currentSubmissionStatus = 4;
    };

    try {
        const res = await applicationApproval(params);
        if (res.code == 200) {
            ElMessage.success('暂存成功');
            if (res.data?.id || !reData.value) {
                reData.value = { ...res.data };
                getDetailInfoBeforeSteps(reData.value.id);
            };

            if (t == 'save') {
                setTimeout(() => {
                    router.push({
                        path: '/applyList',
                        query: {
                            tabId: 0
                        }
                    });
                }, 1000);
            } else {
                if (steps.value >= 4) {
                    return false;
                } else {
                    steps.value++;
                };
            };
        } else {
            ElMessage.error(res.msg);
        }
    } catch (e) {
        console.log(e, 'this is error~');
    };
};

// 提交
const subCount = ref(0);
const showLetter = ref(false);
const submitCount = ref(5);
const submitData = async () => {
    let timer = setInterval(() => {
        subCount.value++;
        if (subCount.value > 2) {
            clearInterval(timer);
            subCount.value = 0;
        };
    }, 1000);

    // stepOneref?.value?.submitTemporaryStorage()
    let three = stepThreeref?.value?.formLabelAlign;
    console.log("three", three)
    let validateThree = three.some(item => item.employeeName != null || item.employeeIdNumber!= null ||  item.idType!= null );
    console.log("3333333333333333333333")
    if (steps.value === 3) {
      console.log("params", params.entrepreneurEmployee)
        if (validateThree) {
            let theThird = three.every(item => item.employeeName != null && item.employeeIdNumber != null && item.idType != null);
            if (!theThird) {
                ElMessage.error('请完善员工信息');
                return false;
            } else {
              console.log("params", params.entrepreneurEmployee)
                params.entrepreneurEmployee = stepThreeref?.value?.formLabelAlign;
            };
        };
    };

    if (steps.value == 4) {
        let stepFour = stepFourref?.value?.formLabelAlign;
        if (!stepFour.businessUrl || !stepFour.idNumberUrl || !stepFour.yearWagesOutside || !stepFour.taxPaymentCertificate ||
            !stepFour.employeeRegistration ||  !stepFour.idNumberOpposite) {
            ElMessage.error('有材料漏传，请检查');
            return false;
        }
    }

    let params = {
        ...stepOneref?.value?.formLabelAlign,
        establishment: stepOneref?.value?.formLabelAlign.establishment?.replaceAll('-',''),
        businessAddress: stepOneref?.value?.formLabelAlign.businessAddress?.join(),
        organizationCode: stepOneref?.value?.formLabelAlign?.SOCode?.length <= 9 ? stepOneref?.value?.formLabelAlign?.SOCode : '',
        socialCode: stepOneref?.value?.formLabelAlign?.SOCode?.length == 18 ? stepOneref?.value?.formLabelAlign?.SOCode : '',
        ...stepFourref?.value?.formLabelAlign,
        entrepreneurEmployee: null
    };
    params = Object.assign(params, stepTworef?.value?.formLabelAlign);
    params = Object.assign(params, stepFourref?.value?.formLabelAlign);
    if (!showLetter.value) {
        showLetter.value = true;
        return;
    } else {
        showLetter.value = false;
    }
    try {
        const res = await companyPresent(params);
        if (res.code === 200) {
            ElMessage.success('提交成功');
            let timer = setInterval(() => {
                submitCount.value--;
                if (submitCount.value == 0) {
                    router.push('/applyList');
                }
                if (submitCount <= 0) {
                    clearInterval(timer);
                }
            }, 1000);
            count.value = 0;
        } else {
            ElMessage.error(res.msg);
        };
    } catch (e) {
        console.log(e, 'this is error~');
    };
};


// 获取详情-
const employeeList = ref([]);
const getDetailInfo = async () => {
    const id = route.query.id;
    let a = [];
    try {
        const res = await getEnterprisePortalDetail(id);
        if (res.code === 200) {
            reData.value = Object.assign({},res.data);
            steps.value = res.data.currentSubmissionStatus;
            stepOneref.value.formLabelAlign.SOCode = res.data.organizationCode== null ? res.data.socialCode : res.data.organizationCode;
            a = res.data.establishment?.toString().substr(0,4) + '-' + res.data.establishment?.toString().substr(4, 2) + '-' + res.data.establishment?.toString().substr(6, 2);

            let busId = res.data.businessAddress?.split(',');
            let newNum = [];
            busId?.map(item => {
                newNum.push(Number(item));
            });
            let newpId = new Proxy(newNum, {});
            Object.assign(stepOneref.value.formLabelAlign, res.data,{establishment: a, businessAddress: newpId, exemptionGuarantee: res.data.exemptionGuarantee?.toString()});
            Object.assign(stepTworef.value.formLabelAlign, res.data,{ inspectAnnually: res.data.inspectAnnually?.toString(), socialOrganization: res.data.socialOrganization?.toString(), enterpriseDirectory: res.data.enterpriseDirectory?.toString()});

            stepThreeref.value.formLabelAlign.length = 0;
            if (res.data.entrepreneurEmployee) {
              console.log("33333333进来")
              //这里因为使用了异步获取图片，会导致多个员工的时候，顺序不一致的问题
              res.data.entrepreneurEmployee.forEach(async item => {
                if (item.idNumberUrl) {
                  const response = await downloadByFileId(item.idNumberUrl);
                  item.idNumberUrlRead = URL.createObjectURL(response);
                }
                if (item.idNumberOpposite) {
                  const response = await downloadByFileId(item.idNumberOpposite);
                  item.idNumberOppositeRead = URL.createObjectURL(response);
                }
                stepThreeref?.value?.formLabelAlign?.push(...[item]);
              })
                // stepThreeref?.value?.formLabelAlign?.push(...res.data.entrepreneurEmployee);
            };

            //4页面读取图片
            if (res.data.businessUrl) {
              const response = await downloadByFileId(res.data.businessUrl);
              res.data.businessUrlRead = URL.createObjectURL(response);
            }
          if (res.data.idNumberUrl) {
            const response = await downloadByFileId(res.data.idNumberUrl);
            res.data.idNumberUrlRead = URL.createObjectURL(response);
          }
          if (res.data.idNumberOpposite) {
            const response = await downloadByFileId(res.data.idNumberOpposite);
            res.data.idNumberOppositeRead = URL.createObjectURL(response);
          }
          if (res.data.yearWagesOutside) {
            const response = await downloadByFileId(res.data.yearWagesOutside);
            res.data.yearWagesOutsideRead = URL.createObjectURL(response);
          }
          if (res.data.taxPaymentCertificate) {
            const response = await downloadByFileId(res.data.taxPaymentCertificate);
            res.data.taxPaymentCertificateRead = URL.createObjectURL(response);
          }
          if (res.data.employeeRegistration) {
            const response = await downloadByFileId(res.data.employeeRegistration);
            res.data.employeeRegistrationRead = URL.createObjectURL(response);
          }
            Object.assign(stepFourref.value.formLabelAlign,res.data);
        }
    } catch (e) {
        console.log(e, 'this is error~');
    };
};

// 设置当前高亮样式
let clsTwo = (e) => {
    if (e == 2) {
        return 'active active_two';
    };
    if (e > 2) {
        return 'done';
    };
};

let clsThree = (e) => {
    if (e < 3) {
        return '';
    };
    if (e == 3) {
        return 'active active_two';
    };
    if (e > 3) {
        return 'done';
    };
};

onMounted(() => {
    if (route.query.id) {
        setTimeout(() => {
            getDetailInfo();
        }, 1000);
    }
    parterIdNumber.value = stepOneref?.value?.formLabelAlign?.idNumber;
});

</script>

<template>
    <div class="layout" v-show="!showLetter">
        <div class="banner">
            <img src="/src/assets/personalCenter/banner.png" />
        </div>

        <div class="all">
            <div class="position">您当前的位置：<label @click="router.push('/')">首页</label> / <i> 小微企业创业担保贷款申请表</i></div>
            <div class="cont">
                <ul>
                    <li :class="`one ${steps == 1 ? ' active ' : 'active_one done'}`">
                        <label class="circle"><i>1</i></label>
                        <span>小微企业基本信息</span>
                        <span class="sub">请填写基本信息</span>
                    </li>
                    <li :class="`two ${clsTwo(steps)}`">
                        <label class="circle"><i>2</i></label>
                        <span>当年吸纳符合创业担保贷款申请条件人员情况</span>
                    </li>
                    <li :class="`two ${clsThree(steps)}`">
                        <label class="circle"><i>3</i></label>
                        <span>员工信息</span>
                        <span class="sub">请填写员工信息</span>
                    </li>
                    <li :class="`two ${steps == 4 ? ' active active_two' : ''}`">
                        <label class="circle"><i>4</i></label>
                        <span>相关证件材料</span>
                        <span class="sub">请上传申请材料</span>
                    </li>
                </ul>

                <template v-if="count != 0">
                    <StepOone v-show="steps == 1" ref="stepOneref"></StepOone>
                    <StepTwo v-show="steps == 2" ref="stepTworef"></StepTwo>
                    <StepThree v-show="steps == 3" ref="stepThreeref" :employeeList="employeeList" :idNumber="parterIdNumber"></StepThree>
                    <StepFour v-show="steps == 4" ref="stepFourref"></StepFour>
                </template>

                <template v-if="count == 0">
                    <div class="submit-done-box">
                        <img :src="Done"/>
                        <div>恭喜，贷款申请提交成功，我们将会在X个工作日内审核完成，请您及时关注我的申请页面</div>
                        <div class="count"><i>{{ submitCount }}</i>秒后自动跳转到我的申请页面</div>
                        <div class="line"></div>
                        <div class="btn-list">
                            <span @click="router.push('/applyList')">进入我的申请</span>
                            <span @click="router.push('/')">返回首页</span>
                        </div>
                    </div>
                </template>
            </div>

            <div class="bottom-box">
                <div class="bottom" v-if="count != 0">
                    <div class="line"></div>
                    <template v-if="steps == 1">
                        <div class="btn-list">
                            <span @click="router.push('/')" v-if="steps == 1">返回首页</span>
                            <span @click="disBtn == 0?temporaryStorageData('save'):''">暂存</span>
                            <span @click="disBtn == 0?temporaryStorageData('next'):''">下一页</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="btn-list">
                            <span @click="goBack">上一页</span>
                            <span @click="disBtn == 0?temporaryStorageData('save'):''">暂存</span>
                            <span @click="disBtn == 0?temporaryStorageData('next'):''" v-if="steps != 4">下一页</span>
                            <span @click="subCount ==0?submitData():''" v-if="steps == 4">提交贷款申请</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <Letter @busNext="busNext" @busSubmit="submitData" v-if="showLetter" :id="reData.id"></Letter>
</template>

<style  src="./index.css" scoped></style>
