
import request from '../utils/request'

// 个人暂存
export function temporaryStorage(data) {
	return request({
		url: '/manager/entrepreneurInfoGateway/temporaryStorage',
		method: "post",
		data
	})
}


// 个人提交
export function submit(data) {
	return request({
		url: '/manager/entrepreneurInfoGateway/submit',
		method: "post",
		data
	})
}

// 字典
export function dictList(query) {
	return request({
		headers: { 'content-type': 'application/json' },
		url: `/manager/enterprisePortal/list`,
		method: "get",
		params: query
	})
}

// 获取地址
export function deptTree() {
	return request({
		url: `/manager/enterprisePortal/deptTree`,
		method: "get",
	})
}


// 查询对应地区银行数据
export function selectBankList(id) {
	return request({
		url: '/manager/enterpriseSecurity/selectBankList',
		method: 'post',
		data: {
			deptId: id
		}
	})
}
// 查询对应地区担保机构数据
export function selectGuarantorsList(id) {
	return request({
		url: '/manager/enterpriseSecurity/selectGuarantorsList',
		method: 'post',
		data: {
			deptId: id
		}
	})
}

// 查询对应地区银行数据
export function selectGatewayBankList(id) {
	return request({
		url: '/manager/enterpriseSecurity/selectGatewayBankList',
		method: 'post',
		data: id
	})
}
// 查询对应地区担保机构数据
export function selectGatewayGuarantorsList(id) {
	return request({
		url: '/manager/enterpriseSecurity/selectGatewayGuarantorsList',
		method: 'post',
		data: id
	})
}

// 小微企暂存
export function applicationApproval(data) {
	return request({
		url: '/manager/enterprisePortal/applicationApproval',
		method: "post",
		data
	})
}

// 小微企提交
export function companyPresent(data) {
	return request({
		url: '/manager/enterprisePortal/present',
		method: "post",
		data
	})
}

// 小微企业信息修改
export function companyPresentEdit(data) {
	return request({
		url: '/manager/enterprisePortal/resubmit',
		method: "post",
		data
	})
}

// 个人--删除员工接口
export function deleteEntrepreneurDoorDtoPerson(ids) {
	return request({
		url: `/manager/entrepreneurEmployee/gatewayRemove/${ids}`,
		method: "delete",
	})
}

// 个人--删除和合伙人接口
export function deletePersonalPartnerInfo(ids) {
	console.log(typeof ids);
	if (typeof ids == 'object') {
		ids = ids.join();
	}
	return request({
		url: `/manager/personalPartnerInfo/gatewayRemove/${ids}`,
		method: "delete",
	})
}


// 个人-- 检测身份证已经在3年内注册过了
export function getIdentityDuplicationInThreee(id) {
	return request({
		url: `/manager/entrepreneurInfoGateway/getIdentityDuplication/${id}`,
		method: "get",
	})
}

/** 修改额度提升状态 */
export function changeAmountRaiseStatus(id) {
	return request({
		url: `/manager/entrepreneurInfoGateway/getIdentityDuplication/${id}`,
		method: "get",
	})
}

// 下载图片
export function downloadByFileId(fileId) {
	return request({
		url: `/manager/file/down/` + fileId,
		method: "get",
		responseType: 'blob'
	})
}
