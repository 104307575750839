import { dictList,selectBankList,selectGuarantorsList,deptTree, selectGatewayBankList, selectGatewayGuarantorsList } from '@/api/index.js'
import { ElMessage } from 'element-plus'

 // 获取证件类型
 const idTypeList = ref([])
 const getDictList = async() =>{
      await dictList({dictType:'id_type'}).then((res) =>{
         if(res.code == 200){
             idTypeList.value = res.rows
         }else{
             ElMessage.error(res.msg)
         }
      })
 }

 // 获取政治面貌
 const politicaltList= ref([])
 const getPoliticaltList = async() =>{
      await dictList({dictType:'political_outlook'}).then((res) =>{
         if(res.code == 200){
             politicaltList.value = res.rows
         }else{
             ElMessage.error(res.msg)
         }
      })
 }

  // 获取创业担保贷款人员类别
  const personCateList= ref([])
  const getPersonCateList = async() =>{
       await dictList({dictType:'loan_person_category'}).then((res) =>{
          if(res.code == 200){
              personCateList.value = res.rows
          }
       })
  }

  // 获取婚姻状况
  const maritalStatus= ref([])
  const getMaritalStatusList = async() =>{
       await dictList({dictType:'marital_status'}).then((res) =>{
          if(res.code == 200){
              maritalStatus.value = res.rows
          }else{
              ElMessage.error(res.msg)
          }
       })
  }


   // 获取与借款人关系
   const entreRelationList= ref([])
   const getEntreRelationList = async() =>{
        await dictList({dictType:'entrepreneur_relation'}).then((res) =>{
           if(res.code == 200){
               entreRelationList.value = res.rows
           }else{
               ElMessage.error(res.msg)
           }
        })
   }

 
    // 获取创业扶持人员类别
    const entrepreneurList= ref([])
    const getEntrepreneurList = async() =>{
        await dictList({dictType:'entrepreneur_category'}).then((res) =>{
            if(res.code == 200){
                entrepreneurList.value = res.rows
            }else{
                ElMessage.error(res.msg)
            }
        })
    }

    // 获取担保增信方式
    const guaranWayList= ref([])
    const getGuaranWayList = async() =>{
         await dictList({dictType:'guarantee_way'}).then((res) =>{
            if(res.code == 200){
                guaranWayList.value = res.rows
            }else{
                ElMessage.error(res.msg)
            }
         })
    }


// 银行数据 贷款银行
const bankList= ref([])
const getBankList = async() =>{
    await selectBankList().then((res) =>{
        if(res.code == 200){
            bankList.value = res.data
        }else{
            ElMessage.error(res.msg)
        }
    })
}

const getBankList1 = async(id) =>{
    await selectGatewayBankList(id).then((res) =>{
        if(res.code == 200){
            bankList.value = res.data
        }else{
            ElMessage.error(res.msg)
        }
    })
}

// 创业担保贷款合伙人人员类别
const categoryList = ref([])
const geCategoryList = async() =>{
    await dictList({dictType:'partner_category'}).then((res) =>{
        if(res.code == 200){
            categoryList.value = res.rows
        }else{
            ElMessage.error(res.msg)
        }
    })
}

// 查询对应地区担保机构数据
const guarantorsList = ref([])
const getGuarantorsList = async (type) =>{
     await selectGuarantorsList(type).then((res) =>{
        if(res.code == 200){
            guarantorsList.value = res.data
        }else{
            ElMessage.error(res.msg)
        }
     })
}

const getGuarantorsList1 = async (type) =>{
    await selectGatewayGuarantorsList(type).then((res) =>{
       if(res.code == 200){
           guarantorsList.value = res.data
       }else{
           ElMessage.error(res.msg)
       }
    })
}

// 获取企业类型
const enterpriseTypeList = ref([])
const getenterpriseTypeList = async () => {
    await dictList({ dictType: 'enterprise_type' }).then((res) => {
        if (res.code == 200) {
            enterpriseTypeList.value = res.rows
        } else {
            ElMessage.error(res.msg)
        }
    })
}

// 获取企业类型
const personnelCategoryList = ref([])
const getpersonnelCategoryList = async () => {
    await dictList({ dictType: 'personnel_category' }).then((res) => {
        if (res.code == 200) {
            personnelCategoryList.value = res.rows
        } else {
            ElMessage.error(res.msg)
        }
    })
}


//贷款创业类型
const loanEntrepreneurTypeList = ref([])
const getEntrepTypeList = async () => {
    await dictList({ dictType: 'loan_entrepreneur_type' }).then((res) => {
        if (res.code == 200) {
            loanEntrepreneurTypeList.value = res.rows
        } else {
            ElMessage.error(res.msg)
        }
    })
}

// 湖北社会组织
const socialOrganizationHubeiList = ref([])
const getSocialOrganizationHubeiList = async () => {
    await dictList({ dictType: 'social_organization' }).then((res) => {
        if (res.code == 200) {
            socialOrganizationHubeiList.value = res.rows
        } else {
            ElMessage.error(res.msg)
        }
    })
}

// 获取地址
const deptList= ref([])
const getDeptList = async() =>{
     await deptTree({dictType:'entrepreneur_relation'}).then((res) =>{
        if(res.code == 200){
            deptList.value = res.data
        }else{
            ElMessage.error(res.msg)
        }
     })
}


 export {
    idTypeList,
    getDictList,
    politicaltList,
    getPoliticaltList,
    personCateList,
    getPersonCateList,
    maritalStatus,
    getMaritalStatusList,
    entreRelationList,
    getEntreRelationList,
    entrepreneurList,
    getEntrepreneurList,
    guaranWayList,
    getGuaranWayList,
    categoryList,
    geCategoryList,
    bankList,
    getBankList,
    getBankList1,
    guarantorsList,
    getGuarantorsList,
    getGuarantorsList1,
    enterpriseTypeList,
    getenterpriseTypeList,
    personnelCategoryList,
    getpersonnelCategoryList,
    loanEntrepreneurTypeList,
    getEntrepTypeList,
    socialOrganizationHubeiList,
    getSocialOrganizationHubeiList,
    deptList,
    getDeptList
 }