<!--
 * @Author: cxl 250806523@qq.com
 * @Date: 2024-03-28 09:26:44
 * @LastEditors: cxl 250806523@qq.com
 * @LastEditTime: 2024-03-28 19:03:53
 * @FilePath: /front/src/view/personCenter/baseInfo/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script setup>
    import Cookies from "js-cookie";
    // type ==0 个人  1 企业
    let userInfo ={}
    if(Cookies.get('user')){
        userInfo = JSON.parse(Cookies.get('user'))
        console.log(userInfo);
    }
   
</script>

<template>
        <h2><span></span>基本信息</h2>
         <el-form
            ref="ruleFormRef"
            label-width="150px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon>
            <el-form-item label="姓名：">
                <span>{{ userInfo.userName }}</span>
            </el-form-item>
            <el-form-item label="性别：">
                <span>{{ userInfo.code == 0 ? '男' : '女' }}</span>
            </el-form-item>
            <el-form-item label="身份证号：">
                <span>{{ userInfo.code || '-' }}</span>
            </el-form-item>
            <el-form-item label="手机号：">
                <span>{{ userInfo.phonenumber || '-' }}</span>
            </el-form-item>
        </el-form>
        <!-- <el-form
            ref="ruleFormRef"
            label-width="150px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon
            v-if="userInfo?.type == 0">
            <el-form-item label="姓名：">
                <span>{{ userInfo.userName }}</span>
            </el-form-item>
            <el-form-item label="身份证号：">
                <span>**************1234</span>
            </el-form-item>
            <el-form-item label="出生年月：">
                <span>19990-1-10</span>
            </el-form-item>
            <el-form-item label="手机号：" required>
                <span>{{ userInfo.phonenumber }}</span>
            </el-form-item>
        </el-form> -->

        <!-- 法人 -->
        <!-- <el-form
            ref="ruleFormRef"
            label-width="150px"
            class="demo-ruleForm"
            :size="formSize"
            status-icon v-if="userInfo?.type == 1"> 
            <el-form-item label="法人代表姓名：">
                <span>{{ userInfo.userName}}</span>
            </el-form-item>
            <el-form-item label="法人身份证号：">
                <span>**************1234</span>
            </el-form-item>
            <el-form-item label="出生年月：">
                <span>19990-1-10</span>
            </el-form-item>
            <el-form-item label="法人手机号：">
                    <span>{{ userInfo.phonenumber }}</span>
            </el-form-item>
            <el-form-item label="企业名称：" >
                    <span>136****1234</span>
            </el-form-item>
            <el-form-item label="联系人姓名：">
                    <span>136****1234</span>
            </el-form-item>
            <el-form-item label="身份证号：">
                    <span>136****1234</span>
            </el-form-item>
            <el-form-item label="联系人手机号：">
                    <span>136****1234</span>
            </el-form-item>
        </el-form> -->
</template>

<style scoped>
.active{
    background-color: #F8FBFF;
    color: #C30A05;
}

.right h2 span{
    border-left: 4px solid #C30A05;
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
    margin-right: 0.87rem;
}
.right h2{
    font-weight: 500;
    color: #333333;
    border-bottom: 1px solid #E8EAED;
    padding-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 600;
}
</style>
