<script setup>
import { useRouter } from "vue-router";
import { register, captchaImage } from "@/api/login.js";
import { onMounted, reactive, ref } from "vue";
import { ElMessage } from "element-plus";

const router = useRouter();

const getRes = ref();
// 获取验证码
const getImage = () => {
  try {
    captchaImage().then((res) => {
      getRes.value = res;
    });
  } catch (e) {
    console.log(e, "this is e~");
  }
};

//  确认密码
const checkPsd = (rule, value, callback) => {
  if (value != loginForm.password) {
    callback(new Error("两次密码输入不一致"));
  } else {
    callback();
  }
};

const loginForm = reactive({
  username: "",
  password: "",
  psdConfirm: "",
  code: "",
  uuid: "",
});

const rules = reactive({
  username: [{ required: true, message: "请输入登录名", trigger: "change" }],
  password: [
    { required: true, message: "请输入密码", trigger: "change" },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
      message: "需包含大小写字母和数字且长度为8至20位",
      trigger: "blur"
    }
  ],
  psdConfirm: [
    { required: true, message: "请确认密码", trigger: "change" },
    { validator: checkPsd, trigger: "blur" },
  ],
  code: [{ required: true, message: "请输入验证码", trigger: "change" }],
});
const loginFormRef = ref(null);

//重置
const resetForm = (el) => {
  loginFormRef.value.resetFields();
};

// 登录
const goRegister = () => {
  try {
    loginFormRef.value.validate((valid) => {
      if (valid) {
        if (getRes.value.answer != loginForm.code) {
            ElMessage.error("验证码输入有误");
            return;
        }
        let params = {
          ...loginForm,
          code: getRes.value.answer,
          uuid: getRes.value.uuid,
        };
        register(params).then((res) => {
          if (res.code === 200) {
            ElMessage.success("注册成功");
            setTimeout(() => {
              router.push("/login");
            }, 1500);
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    });
  } catch (e) {
    console.log(e, "this is e~");
  }
};

onMounted(() => {
  getImage();
});
</script>
<template>
  <div class="content-layout">
    <img src="/src/assets/login/bg.png" style="width: 100%" />
    <div class="cont">
      <div class="login">
        <img src="/src/assets/login/img-left.png" class="l-left" />
        <div class="l-right">
          <span class="l-right-title">
            <img src="/src/assets/login/img-logo-1.png" class="logo"/>
            创业贷信息监管平台
          </span>
          <el-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="rules"
            label-position="left"
            label-width="100px"
            class="login-class"
          >
            <el-form-item label="登录名" prop="username">
              <el-input
                v-model="loginForm.username"
                placeholder="请输入登录名"
              />
            </el-form-item>
            <el-form-item label="密码" prop="password" placeholder="请输入">
              <el-input
                v-model="loginForm.password"
                type="password"
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item label="确认密码" prop="psdConfirm">
              <el-input
                v-model="loginForm.psdConfirm"
                type="password"
                placeholder="请确认密码"
              />
            </el-form-item>
            <el-form-item label="验证码" prop="code" placeholder="请输入" class="code_box">
              <el-input
                v-model="loginForm.code"
                style="width: 7.5rem; margin-right: 1rem"
                @keyup.enter="goRegister"
                placeholder="请输入验证码"
              />
              <img
                :src="'data:image/png;base64,' + getRes?.img"
                style="width: 5rem"
                @click="getImage"
              />
            </el-form-item>
          </el-form>
          <div class="btn-list">
            <span class="register-btn" @click="router.go(-1)">返回</span>
            <span class="reset-btn" @click="resetForm(loginFormRef)">重置</span>
            <span class="login-btn" @click="goRegister">注册</span>
          </div>
          <!-- <span class="btn-login" @click="goLogin">使用湖北省统一身份认证平台登录</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-layout {
  position: relative;
  background-color: rgba(20, 26, 33, 1);
}
.cont {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login {
  width: 45.62rem;
  height: 28.75rem;
  display: flex;
  text-align: center;
  margin: 0 auto;
}

.l-left {
  width: 22.81rem;
  height: 28.75rem;
}

.logo {
  width: 6.87rem;
  height: 6.87rem;
  margin: 2.18rem 0;
}
.l-right {
  background: #ffffff;
  width: 100%;
  padding: 5rem 0;
}

.l-right span {
  display: block;
  color: #333333;
  font-weight: 500;
  line-height: 1.25rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.l-right .l-right-title {
  font-size: 1.6rem;
  display: inline-flex;
  align-items: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  img {
    width: 3.125rem;
    height: 3.125rem;
    margin: 0 1.25rem 0 0;
  }
}

.l-right .btn-login {
  background-color: rgba(67, 119, 254, 1);
  color: #ffffff;
  width: 18rem;
  margin: 0 auto;
  font-size: 1rem;
  height: 2.75rem;
  line-height: 2.75rem;
  border-radius: 5px;
  color: #ffffff;
}

.l-right span.login-btn {
  background-color: rgba(67, 119, 254, 1);
  width: 6rem;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
}

.l-right span.reset-btn {
  border: 1px solid #e3e6eb;
  width: 6rem;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  margin-right: 1.5rem;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
}

.l-right span.register-btn {
  background-color: #fba504;
  width: 6rem;
  height: 2rem;
  line-height: 2rem;
  display: inline-block;
  margin-right: 1.5rem;
  cursor: pointer;
  text-align: center;
  flex-shrink: 0;
  color: #fff;
}

.login-class {
  padding: 1.5rem;
}
.btn-list {
  /* width: 13.55rem; */
  text-align: right;
  padding: 0 1.5rem;
  float: right;
  display: flex;
}

.code_box :deep(.el-form-item__content) {
    justify-content: space-between;
    .el-input {
        flex: 1;
    }
}
</style>
