<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getEnterprisePortalDetail } from "@/api/applyList.js";
import { deleteEntrepreneurDoorDtoPerson, downloadByFileId } from '@/api/index.js'
import {
  bankList,
  getBankList,
  idTypeList,
  getDictList,
  guarantorsList,
  getGuarantorsList,
  socialOrganizationHubeiList,
  getSocialOrganizationHubeiList,
  deptList,
  getDeptList,
  getGuarantorsList1,
  getBankList1,
  entrepreneurList,
  getEntrepreneurList,
  enterpriseTypeList,
  getenterpriseTypeList,
} from "@/api/certificateList.js";
import { companyPresentEdit } from "@/api/index.js";
import { ElMessageBox, ElMessage } from "element-plus";
import { uploadImgUrlPost, headers } from "@/api/upload.js";
import axios from "axios";
import ImgOne from "@/assets/stepTwo/id-1.png";
import ImgTwo from "@/assets/stepTwo/id-2.png";
import { getToken } from "@/utils/auth";
const uploadHeaders = {
  Authorization: "Bearer " + getToken(),
  "Content-Type": "multipart/form-data",
};

const router = useRouter();
const route = useRoute();
const loading = ref(true);

const currentidType = ref(""); // 证件类型
const curententrEpreneur = ref(""); //创业扶持人员类别
const curententrEnterpriseType = ref(""); //企业类型
const curentbank = ref(""); // 贷款银行
const currentguarantors = ref(""); // 担保机构
const currentOrgan = ref(""); // 湖北社会组织
const currentBusinessAddress = ref(""); // 经营地址

// 获取详情-
const detail = ref({});
let originData = {};
const getDetailPersonall = async () => {
  const id = route.query.id;
  try {
    const res = await getEnterprisePortalDetail(id);
    if (res.code === 200) {
      detail.value = res.data;
      //2024-06-20 所有图片入口已经改成由通过id调用后台接口获取
      if (detail.value.idNumberUrl) {
        const response = await downloadByFileId(detail.value.idNumberUrl);
        detail.value.idNumberUrlRead = URL.createObjectURL(response);
      }
      if (detail.value.idNumberOpposite) {
        const response = await downloadByFileId(detail.value.idNumberOpposite);
        detail.value.idNumberOppositeRead = URL.createObjectURL(response);
      }
      if (detail.value.businessUrl) {
        const response = await downloadByFileId(detail.value.businessUrl);
        detail.value.businessUrlRead = URL.createObjectURL(response);
      }
      if (detail.value.yearWagesOutside) {
        const response = await downloadByFileId(detail.value.yearWagesOutside);
        detail.value.yearWagesOutsideRead = URL.createObjectURL(response);
      }
      if (detail.value.taxPaymentCertificate) {
        const response = await downloadByFileId(detail.value.taxPaymentCertificate);
        detail.value.taxPaymentCertificateRead = URL.createObjectURL(response);
      }
      if (detail.value.employeeRegistration) {
        const response = await downloadByFileId(detail.value.employeeRegistration);
        detail.value.employeeRegistrationRead = URL.createObjectURL(response);
      }
      //员工需要遍历
      if (detail.value.entrepreneurEmployee) {
        detail.value.entrepreneurEmployee.forEach(async item => {
          if (item.idNumberUrl) {
            const response = await downloadByFileId(item.idNumberUrl);
            item.idNumberUrlRead = URL.createObjectURL(response);
          }
          if (item.idNumberOpposite) {
            const response = await downloadByFileId(item.idNumberOpposite);
            item.idNumberOppositeRead = URL.createObjectURL(response);
          }
        })
      }

      let establishment = detail.value.establishment;
      establishment = establishment.toString();
      let year = establishment.slice(0, 4);
      let month = establishment.slice(4, 6);
      let day = establishment.slice(6, 8);
      establishment = year + "-" + month + "-" + day;
      detail.value.establishment = establishment;
      currentidType.value = idTypeList.value.filter((item) => {
        return item.dictValue == detail.value.idType ? item.dictLabel : "";
      });
      curententrEpreneur.value = entrepreneurList.value.filter((item) => {
        return item.dictValue == detail.value.personnelCategory
          ? item.dictLabel
          : "";
      });
      curententrEnterpriseType.value = enterpriseTypeList.value.filter(
        (item) => {
          return item.dictValue == detail.value.enterpriseType
            ? item.dictLabel
            : "";
        }
      );
      let businessAddress = detail.value.businessAddress;
      if (businessAddress && businessAddress != "NaN") {
        businessAddress = detail.value.businessAddress.split(",");
        let busArr = [];
        businessAddress.map((v) => {
          let data = Number(v);
          busArr.push(data);
        });
        detail.value.businessAddress = busArr;
        getGuarantorsList1(businessAddress[2]).then(() => {
          for (let i in guarantorsList.value) {
            if (i == res.data.guaranteeOrganizationName) {
              currentguarantors.value = guarantorsList.value[i];
            }
          }
        });

        getBankList1(businessAddress[2]).then(() => {
          for (const [key, value] of Object.entries(bankList.value)) {
            if (key == parseInt(res.data.bankId)) {
              curentbank.value = value;
            }
          }
        });
      }
      if (detail.value.entrepreneurEmployee == null) {
        detail.value.entrepreneurEmployee = [];
      }

      for (const [key, value] of Object.entries(
        socialOrganizationHubeiList.value
      )) {
        if (key == parseInt(res.data.socialOrganization)) {
          currentOrgan.value = value;
        }
      }
      currentBusinessAddress.value = getAddressForperson(
        detail.value.businessAddress
      ); // 经营地址
      loading.value = false;
      localStorage.setItem("APPLY_DETAIL", JSON.stringify(detail.value));
    }
  } catch (e) {
    console.log(e, "this is error~");
  }
};

// 获取地址
const getAddressForperson = (idList) => {
  let dList = deptList.value;
  let address = "";

  for (let i = 0; i < dList.length; i++) {
    if (idList[0] == dList[i].id) {
      address += dList[i].label;

      if (dList[i].children) {
        let c1 = dList[i].children;

        for (let i = 0; i < c1.length; i++) {
          if (idList[1] == c1[i].id) {
            address += c1[i].label;
            if (c1[i].children) {
              let c2 = c1[i].children;
              for (let i = 0; i < c2.length; i++) {
                if (idList[2] == c2[i].id) {
                  address += c2[i].label;
                }
              }
            }
          }
        }
      }
    }
  }
  return address;
};

const canEdit = ref(false);
const isEditing = ref(false);

const toEdit = () => {
  isEditing.value = !isEditing.value;
};

const toCancel = () => {
  ElMessageBox.confirm("当前修改的内容将不会保存，确认取消吗?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then((ret) => {
      if (ret == "confirm") {
        isEditing.value = false;
        let APPLY_DETAIL = JSON.parse(localStorage.getItem("APPLY_DETAIL"));
        detail.value = APPLY_DETAIL;
      }
    })
    .catch((err) => {
      console.log("err:", err);
    });
};

const submitFlag = ref(false);
const submit = async () => {
  let params = detail.value;
  validateData(params);
  if (!submitFlag.value) return;
  console.log(typeof params.businessAddress);
  if (typeof params.businessAddress != 'string') {
    params.businessAddress = params?.businessAddress?.join();
  }
  params.establishment = Number(params.establishment.replace(/-/g, ''));
  companyPresentEdit(params).then((res) => {
    if (res.code == "200") {
      ElMessage.success("修改成功!");
      isEditing.value = false;
      getDetailPersonall();
      router.go(-1);
    } else {
      ElMessage.error("修改失败!");
      isEditing.value = false;
    }
  });
};

const changeBusinessAddress = (e) => {
  getBankList1(e[2]);
  getGuarantorsList1(e[2]);
  detail.bankId = null;
  detail.guaranteeOrganizationName = null;
};

const addEmployee = () => {
  detail.value.entrepreneurEmployee.push({
    employeeName: null,
    employeeIdNumber: null,
    idType: null,
    idNumberUrl: null,
    idNumberUrlRead: null,
    idNumberOpposite: null,
    idNumberOppositeRead: null
  });
};

const delEmployee = (item) => {
  ElMessageBox.confirm("确认删除该员工信息吗?一经删除，该条数据将无法恢复，请谨慎操作!", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then((ret) => {
      if (ret == "confirm") {
        detail.value.entrepreneurEmployee.map((v, i) => {
          if (v.id == item.id) {
            if (item.id) {
              deleteEntrepreneurDoorDtoPerson(item.id).then(res => {
                if (res.code == '200') {
                  ElMessage.success("员工信息删除成功!");
                  detail.value.entrepreneurEmployee.splice(i, 1);
                  getDetailPersonall();
                };
              });
            } else {
              ElMessage.success("员工信息删除成功!");
              detail.value.entrepreneurEmployee.splice(i, 1);
            }
          };
        });
      }
    })
    .catch((err) => {
      console.log("err:", err);
    });
};

// 图片上传
const   changeImge = (res, f, item) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  item.idNumberUrlRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        item.idNumberUrl = res.data.msg;
      }
    });
};

// 图片上传 身份证反面
const changeImgeRever = (res, f, item) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  item.idNumberOppositeRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        item.idNumberOpposite = res.data.msg;
      }
    });
};

const changeBusiImge = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.businessUrlRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
          detail.value.businessUrl = res.data.msg;
      }
    });
};

// 图片上传 本人身份证正
const changeImgeIdm = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.idNumberUrlRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        detail.value.idNumberUrl = res.data.msg;
      }
    });
};

// 身份证反面
const changeImgeOpposite = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.idNumberOppositeRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        detail.value.idNumberOpposite = res.data.msg;
      }
    });
};

// 图片上传 本年度工资表
const changeImgeYear = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.yearWagesOutsideRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        detail.value.yearWagesOutside = res.data.msg;
      }
    });
};

// 图片上传 完税证明支持格式
const changeImgeTax = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.taxPaymentCertificateRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        detail.value.taxPaymentCertificate = res.data.msg;
      }
    });
};

// 员工登记
const changeImgeEmp = (res, f) => {
  let formData = new FormData();
  let aimg = URL.createObjectURL(res.raw)
  detail.value.employeeRegistrationRead = aimg
  formData.append("file", res.raw);
  axios
    .post(uploadImgUrlPost, formData, { headers: uploadHeaders })
    .then((res) => {
      if (res.data.code == 200) {
        detail.value.employeeRegistration = res.data.msg;
      }
    });
};

const validateData = (params) => {
  if (!params.unitName.trim()) {
    ElMessage.error("请输入企业名称");
    return;
  }
  // organizationCode
  if (!params.socialCode && !params.organizationCode) {
    ElMessage.error("请输入统一社会信用代码/组织机构代码");
    return;
  }
  let codeTest = /^([a-zA-Z0-9-]{9}|[a-zA-Z0-9-]{10}|[a-zA-Z0-9-]{18})$/;
  if (!codeTest.test(params.socialCode) && !!params.socialCode) {
    ElMessage.error(
      "统一社会信用代码/组织机构代码长度只能是9位、10位、18位的数字和字母，支持中划线"
    );
    return;
  }
  if (!codeTest.test(params.organizationCode) && !!params.organizationCode) {
    ElMessage.error(
      "统一社会信用代码/组织机构代码长度只能是9位、10位、18位的数字和字母，支持中划线"
    );
    return;
  }
  if (!params.establishment) {
    ElMessage.error("请选择成立日期");
    return;
  }
  if (!params.employees) {
    ElMessage.error("请输入员工总数");
    return;
  }
  let employeesTest = /^\d{1,6}$/;
  if (!employeesTest.test(params.employees)) {
    ElMessage.error("员工总数必须是1到6位数字");
    return;
  }
  if (!params.representative.trim()) {
    ElMessage.error("请输入小微企业法人代表人姓名");
    return;
  }
  if (!params.idNumber.trim()) {
    ElMessage.error("请输入小微企业法人代表人证件号码");
    return;
  }
  let idNumbertest = /^([a-zA-Z0-9-]{1,18})$/;
  if (!idNumbertest.test(params.idNumber)) {
    ElMessage.error(
      "小微企业法人代表人证件号码为1-18位的数字和字母，支持中划线"
    );
    return;
  }
  let telTest = /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/;
  if (!telTest.test(params.telephone) && !!params.telephone) {
    ElMessage.error(
      "小微企业法人代表人联系电话格式不正确"
    );
    return;
  }
  let registeredCapitalTest = /^\d{1,8}(\.\d{1,2})?$/;
  if (
    params.registeredCapital &&
    !registeredCapitalTest.test(params.registeredCapital)
  ) {
    ElMessage.error("注册资本格式不正确,最大8位数,最多保留2位小数");
    return;
  }
  if (params.turnover && !registeredCapitalTest.test(params.turnover)) {
    ElMessage.error("当年营业额格式不正确,最大8位数,最多保留2位小数");
    return;
  }
  if (
    params.totalLiabilities &&
    !registeredCapitalTest.test(params.totalLiabilities)
  ) {
    ElMessage.error("企业总负债金额格式有误,最大8位数,最多保留2位小数");
    return;
  }
  if (params.totalAssets && !registeredCapitalTest.test(params.totalAssets)) {
    ElMessage.error("企业总负债金额格式有误,最大8位数,最多保留2位小数");
    return;
  }
  if (!params.loanAmount.trim()) {
    ElMessage.error("请输入申请贷款金额");
    return;
  }
  if (params.loanAmount > 500) {
    ElMessage.error("申请贷款金额不能大于500万");
    return;
  }
  if (!params.loanTerm.trim()) {
    ElMessage.error("请输入申请贷款期限");
    return;
  }
  if (params.loanTerm > 24 || params.loanTerm < 1) {
    ElMessage.error("申请贷款期限在1-24个月之间");
    return;
  }
  let existingEmployeeTest = /^([0-9]{1,6})$/;
  if (
    params.existingEmployee &&
    !existingEmployeeTest.test(params.existingEmployee)
  ) {
    ElMessage.error("企业现有在职职工人数最多位6位数");
    return;
  }
  let conformToTest = /^([0-9]{1,5})$/;
  if (params.conformTo && !conformToTest.test(params.conformTo)) {
    ElMessage.error("当年新招用符合创业担保贷款申请条件人数最多5位数");
    return;
  }
  let employeeFlag = true;
  if (params?.entrepreneurEmployee?.length > 0) {
    let arr = params.entrepreneurEmployee;
    arr.map((v, i) => {
      if (!v.employeeName || !v.employeeIdNumber || !v.idType) {
        ElMessage.error("请完善员工信息");
        employeeFlag = false;
      };
      if (!idNumbertest.test(v.employeeIdNumber)) {
        ElMessage.error("员工证件号码格式有误，请核查");
        employeeFlag = false;
      }
      if (v.employeeIdNumber == params.idNumber) {
          ElMessage.error('员工身份证号码不能与主体身份证号码相同，请检查');
          employeeFlag = false;
      }
      for (let j = 0; j < arr.length; j++) {
          if (v.employeeIdNumber == arr[j].employeeIdNumber && i != j) {
              ElMessage.error('已有相同的员工身份证号码，请检查');
              employeeFlag = false;
              break;
          };
      };
    });
  };
  if (!employeeFlag) return;
  if (!params.businessUrl || !params.idNumberUrl || !params.idNumberOpposite || !params.yearWagesOutside || !params.taxPaymentCertificate || !params.employeeRegistration) {
    ElMessage.error("相关证件材料有遗漏，请核查");
    return;
  }
  submitFlag.value = true;
};

onMounted(() => {
  getDictList();
  getDeptList();
  // getBankList()
  // getGuarantorsList()
  getEntrepreneurList();
  getenterpriseTypeList();
  getSocialOrganizationHubeiList();
  if (route.query.canEdit == "1") {
    canEdit.value = true;
  }

  setTimeout(() => {
    if (route.query.id && route.query.type) {
      getDetailPersonall();
    }
  }, 1000);
});

onUnmounted(() => {
  localStorage.removeItem("APPLY_DETAIL");
});
</script>
<template>
  <div class="layout">
    <div class="banner">
      <img src="/src/assets/personalCenter/banner2.png" />
    </div>

    <div class="all" v-loading="loading">
      <div class="position">
        您当前的位置：<label @click="router.push('/')">首页</label> /
        <label @click="router.push('/baseInfo')">个人中心</label> /
        <i @click="router.push('/applyList')">我的申请</i> /
        <i> 贷款申请数据</i>
      </div>

      <div class="cont">
        <h2 class="page-title">
          <span></span>小微企业基本信息
          <el-button
            v-if="canEdit && !isEditing"
            class="btn-edit"
            type="primary"
            @click="toEdit"
            >修改</el-button
          >
        </h2>
        <el-form ref="ruleFormRef" :rules="rules" :model="formLabelAlign" class="formData">
          <table>
            <tr>
              <td width="25%">企业名称</td>
              <td width="25%">
                <el-input
                  v-if="isEditing"
                  v-model="detail.unitName"
                  placeholder="请输入企业名称"
                ></el-input>
                <div v-else>{{ detail.unitName }}</div>
              </td>
              <td width="25%">企业类型</td>
              <td width="25%">
                <el-select
                  v-model="detail.enterpriseType"
                  placeholder="请选择企业类型"
                  v-if="isEditing"
                >
                  <el-option
                    v-for="item in enterpriseTypeList"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :key="item.id"
                  />
                </el-select>
                <div v-else>{{ curententrEnterpriseType[0]?.dictLabel }}</div>
              </td>
            </tr>
            <tr>
              <td>统一社会信用代码/组织机构代码</td>
              <td colspan="3">
                <el-input
                  v-if="isEditing && detail.socialCode"
                  v-model="detail.socialCode"
                  placeholder="请输入统一社会信用代码/组织机构代码"
                ></el-input>
                <el-input
                  v-if="isEditing && detail.organizationCode"
                  v-model="detail.organizationCode"
                  placeholder="请输入统一社会信用代码/组织机构代码"
                ></el-input>
                <div v-if="!isEditing">
                  {{
                    detail.socialCode
                      ? detail.socialCode
                      : detail.organizationCode
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <td>成立日期</td>
              <td>
                <el-date-picker
                  v-if="isEditing"
                  v-model="detail.establishment"
                  type="date"
                  placeholder="请选择成立日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                  <template #default="cell">
                    <div class="cell" :class="{ current: cell.isCurrent }">
                      <i class="text">{{ cell.text }}</i>
                    </div>
                  </template>
                </el-date-picker>
                <div v-else>{{ detail.establishment }}</div>
              </td>
              <td>员工总数</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.employees"
                  placeholder="请输入员工总数"
                >
                  <template #append>人</template>
                </el-input>
                <div v-else>{{ detail.employees }}人</div>
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td width="25%">创业扶持人员类别</td>
              <td width="25%">
                <el-select
                  v-if="isEditing"
                  v-model="detail.personnelCategory"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in entrepreneurList"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :key="item.id"
                  />
                </el-select>
                <div v-else>{{ curententrEpreneur[0]?.dictLabel }}</div>
              </td>
              <td width="25%">是否首次创业</td>
              <td width="25%">
                <el-select
                  v-if="isEditing"
                  v-model="detail.initiate"
                  placeholder="请选择"
                >
                  <el-option label="是" value="0" />
                  <el-option label="否" value="1" />
                </el-select>
                <div v-else>{{ detail.initiate == 0 ? "是" : "否" }}</div>
              </td>
            </tr>
            <tr>
              <td>小微企业法人代表人姓名</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.representative"
                  placeholder="请输入小微企业法人代表人姓名"
                ></el-input>
                <div v-else>{{ detail.representative }}</div>
              </td>
              <td>小微企业法人代表人证件类型</td>
              <td>
                <el-select
                  v-if="isEditing"
                  v-model="detail.idType"
                  placeholder="请选择证件类型"
                >
                  <el-option
                    v-for="item in idTypeList"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :key="item.id"
                  />
                </el-select>
                <div v-else>{{ currentidType[0]?.dictLabel }}</div>
              </td>
            </tr>
            <tr>
              <td>小微企业法人代表人证件号码</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.idNumber"
                  placeholder="请输入小微企业法人代表人证件号码"
                ></el-input>
                <div v-else>{{ detail.idNumber }}</div>
              </td>
              <td>小微企业法人代表人联系电话</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.telephone"
                  placeholder="请输入小微企业法人代表人联系电话"
                ></el-input>
                <div v-else>{{ detail.telephone || "-" }}</div>
              </td>
            </tr>
            <tr>
              <td>经营地址</td>
              <td colspan="3">
                <div
                  v-if="isEditing"
                  style="display: flex; justify-content: flex-start"
                >
                  <el-cascader
                    v-model="detail.businessAddress"
                    :options="deptList"
                    placeholder="请选择经营地址"
                    disabled
                    :props="{
                      value: 'id',
                      label: 'label',
                      children: 'children',
                    }"
                    style="width: 100%"
                    @change="changeBusinessAddress"
                  />
                  <el-input
                    style="margin-left: 1rem"
                    v-model="detail.businessAddressDetails"
                    placeholder="请输入详情地址"
                  ></el-input>
                </div>
                <div v-else>
                  {{ currentBusinessAddress }}
                  {{ detail.businessAddressDetails }}
                </div>
              </td>
            </tr>
            <tr>
              <td>注册资本</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.registeredCapital"
                  placeholder="请输入注册资本"
                >
                  <template #append>万元</template>
                </el-input>
                <div v-else>{{ detail.registeredCapital ? detail.registeredCapital + '万元' : '-' }}</div>
              </td>
              <td>当年营业额</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.turnover"
                  placeholder="请输入当年营业额"
                >
                  <template #append>万元</template>
                </el-input>
                <div v-else>{{ detail.turnover ? detail.turnover + '万元' : '-' }}</div>
              </td>
            </tr>
            <tr>
              <td>企业资产总额</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.totalAssets"
                  placeholder="请输入企业资产总额"
                >
                  <template #append>万元</template>
                </el-input>
                <div v-else>{{ detail.totalAssets ? detail.totalAssets + '万元' : '-' }}</div>
              </td>
              <td>企业总负债</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.totalLiabilities"
                  placeholder="请输入企业总负债"
                >
                  <template #append>万元</template>
                </el-input>
                <div v-else>{{ detail.totalLiabilities ? detail.totalLiabilities + '万元' : '-' }}</div>
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td width="25%">申请贷款金额</td>
              <td width="25%">
                <el-input
                  v-if="isEditing"
                  v-model="detail.loanAmount"
                  placeholder="请输入申请贷款金额"
                >
                  <template #append>万元</template>
                </el-input>
                <div v-else>{{ detail.loanAmount }}万元</div>
              </td>
              <td width="25%">贷款期限</td>
              <td width="25%">
                <el-input
                  v-if="isEditing"
                  v-model="detail.loanTerm"
                  placeholder="请输入贷款期限"
                >
                  <template #append>个月</template>
                </el-input>
                <div v-else>{{ detail.loanTerm }}个月</div>
              </td>
            </tr>

            <tr>
              <td>申请贷款用途</td>
              <td colspan="3">
                <el-input
                  v-if="isEditing"
                  type="textarea"
                  :rows="3"
                  v-model="detail.loanPurpose"
                  placeholder="请输入申请贷款用途"
                  style="width: 98% !important; margin: 10px 0"
                ></el-input>
                <div v-else>{{ detail.loanPurpose || "-" }}</div>
              </td>
            </tr>

            <tr>
              <td>担保机构</td>
              <td>
                <el-select
                  v-if="isEditing"
                  v-model="detail.guaranteeOrganizationName"
                  placeholder="请选择担保机构"
                  disabled
                >
                  <el-option
                    v-for="(item, index) in guarantorsList"
                    :value="index"
                    :label="item"
                    :key="index"
                  />
                </el-select>
                <div v-else>{{ currentguarantors }}</div>
              </td>
              <td>贷款银行</td>
              <td>
                <el-select
                  v-if="isEditing"
                  v-model="detail.bankId"
                  placeholder="请选择贷款银行"
                  disabled
                >
                  <el-option
                    v-for="(item, index) in bankList"
                    :value="index"
                    :label="item"
                    :key="index"
                  />
                </el-select>
                <div v-else>{{ curentbank }}</div>
              </td>
            </tr>
            <tr>
              <td>是否免除反担保</td>
              <td colspan="3">
                <el-select
                  v-if="isEditing"
                  v-model="detail.exemptionGuarantee"
                  placeholder="请选择是否免除反担保"
                >
                  <el-option label="是" :value="0" />
                  <el-option label="否" :value="1" />
                </el-select>
                <div v-else>
                  {{ detail.exemptionGuarantee == 0 ? "是" : "否" }}
                </div>
              </td>
            </tr>
          </table>

          <h2><span></span>当年吸纳符合创业担保贷款申请条件人员情况</h2>
          <table>
            <tr>
              <td width="25%">小微企业是否在工商局年检</td>
              <td width="25%">
                <el-select
                  v-if="isEditing"
                  v-model="detail.inspectAnnually"
                  placeholder="请选择"
                >
                  <el-option label="是" :value="0" />
                  <el-option label="否" :value="1" />
                </el-select>
                <div v-else>
                  {{ detail.inspectAnnually == 0 ? "是" : "否" }}
                </div>
              </td>
              <td width="25%">小微企业名录</td>
              <td width="25%">
                <el-select
                  v-if="isEditing"
                  v-model="detail.enterpriseDirectory"
                  placeholder="请选择"
                >
                  <el-option label="有" :value="0" />
                  <el-option label="无" :value="1" />
                </el-select>
                <div v-else>
                  {{ detail.enterpriseDirectory == 0 ? "有" : "无" }}
                </div>
              </td>
            </tr>
            <tr>
              <td>湖北社会组织</td>
              <td>
                <el-select
                  v-if="isEditing"
                  v-model="detail.socialOrganization"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in socialOrganizationHubeiList"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :key="item.value"
                  />
                </el-select>
                <div v-else>{{ currentOrgan.dictLabel ? currentOrgan.dictLabel : '-' }}</div>
              </td>
              <td>企业现有在职职工人数</td>
              <td>
                <el-input
                  v-if="isEditing"
                  v-model="detail.existingEmployee"
                  placeholder="请输入企业现有在职职工人数"
                >
                  <template #append>人</template>
                </el-input>
                <div v-else>
                  {{
                    detail.existingEmployee || detail.existingEmployee != null
                      ? detail.existingEmployee + "人"
                      : "-"
                  }}
                </div>
              </td>
            </tr>
            <tr>
              <td>当年新招用符合创业担保贷款申请条件人数</td>
              <td colspan="3">
                <el-input
                  v-if="isEditing"
                  v-model="detail.conformTo"
                  placeholder="请输入当年新招用符合创业担保贷款申请条件人数"
                >
                  <template #append>人</template>
                </el-input>
                <div v-else>
                  {{
                    detail.conformTo || detail.conformTo != null
                      ? detail.conformTo + "人"
                      : "-"
                  }}
                </div>
              </td>
            </tr>
          </table>

          <h2><span></span>员工信息</h2>
          <div
            v-if="
              detail.entrepreneurEmployee &&
              detail.entrepreneurEmployee.length == 0
            "
          >
            暂无员工信息
          </div>
          <template
            v-for="(item, index) in detail.entrepreneurEmployee"
            :key="index"
          >
            <table>
              <tr class="del-emp" v-if="isEditing">
                <div class="del-emp-btn" @click="delEmployee(item)">
                  <img src="@/assets/ic_del.png" />
                  删除该员工信息
                </div>
              </tr>
              <tr>
                <td width="25%">员工姓名</td>
                <td width="25%">
                  <el-input
                    v-if="isEditing"
                    v-model="item.employeeName"
                    placeholder="请输入员工姓名"
                  ></el-input>
                  <div v-else>{{ item.employeeName }}</div>
                </td>
                <td width="25%">证件类型</td>
                <td width="25%">
                  <el-select
                    v-if="isEditing"
                    placeholder="请选择证件类型"
                    v-model="item.idType"
                  >
                    <el-option
                      v-for="item in idTypeList"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                      :key="item.dictValue"
                    />
                  </el-select>
                  <div v-else>
                    {{
                      idTypeList.filter((e) => {
                        return e.dictValue == item.idType ? e.dictLabel : "";
                      })[0]?.dictLabel
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>证件号码</td>
                <td colspan="3">
                  <el-input
                    v-if="isEditing"
                    v-model="item.employeeIdNumber"
                    placeholder="请输入员工证件号码"
                  ></el-input>
                  <div v-else>{{ item.employeeIdNumber }}</div>
                </td>
              </tr>
              <tr>
                <td>员工身份证正反面照片</td>
                <td colspan="2">
                  <div v-if="isEditing" style="display: flex">
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      v-model="item.idNumberUrl"
                      :on-change="(res, f) => changeImge(res, f, item)"
                    >
                      <img
                        v-if="item.idNumberUrlRead"
                        :src="item.idNumberUrlRead"
                        class="avatar"
                      />
                      <img :src="ImgOne" v-else class="avatar" />
                    </el-upload>

                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      v-model="item.idNumberOpposite"
                      :on-change="(res, f) => changeImgeRever(res, f, item)"
                    >
                      <img
                        v-if="item.idNumberOppositeRead"
                        :src="item.idNumberOppositeRead"
                        class="avatar"
                      />
                      <img :src="ImgTwo" v-else class="avatar" />
                    </el-upload>
                  </div>
                  <div v-else>
                    <span
                      ><img
                        :src="item.idNumberUrlRead"
                        :onerror="
                          () => {
                            item.idNumberUrlRead = '';
                          }
                        "
                    /></span>
                    <span
                      ><img
                        :src="item.idNumberOppositeRead"
                        :onerror="
                          () => {
                            item.idNumberOppositeRead = '';
                          }
                        "
                    /></span>
                  </div>
                </td>
              </tr>
            </table>
          </template>
          <div class="add-emp" v-if="isEditing">
            <span @click="addEmployee">+ 新增员工信息</span>
          </div>

          <h2><span></span>相关证件材料</h2>
          <table>
            <tr>
              <td>营业执照</td>
              <td>
                <el-upload
                  v-if="isEditing"
                  class="avatar-uploader"
                  :show-file-list="false"
                  v-model="detail.businessUrl"
                  :on-change="(res, f) => changeBusiImge(res, f, item)"
                >
                  <img
                    v-if="detail.businessUrlRead"
                    :src="detail.businessUrlRead"
                    class="avatar"
                  />
                  <img :src="ImgOne" v-else class="avatar" />
                </el-upload>
                <span v-else
                  ><img
                    :src="detail.businessUrlRead"
                    :onerror="
                      () => {
                        detail.businessUrlRead = '';
                      }
                    "
                /></span>
              </td>
            </tr>
            <tr>
              <td>本人身份证正反面照片</td>
              <td>
                <div v-if="isEditing" style="display: flex">
                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    v-model="detail.idNumberUrl"
                    :on-change="(res, f) => changeImgeIdm(res, f, item)"
                  >
                    <img
                      v-if="detail.idNumberUrlRead"
                      :src="detail.idNumberUrlRead"
                      class="avatar"
                    />
                    <img :src="ImgOne" v-else class="avatar" />
                  </el-upload>

                  <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    v-model="detail.idNumberOpposite"
                    :on-change="(res, f) => changeImgeOpposite(res, f, item)"
                  >
                    <img
                      v-if="detail.idNumberOppositeRead"
                      :src="detail.idNumberOppositeRead"
                      class="avatar"
                    />
                    <img :src="ImgTwo" v-else class="avatar" />
                  </el-upload>
                </div>
                <div v-else>
                  <span
                    ><img
                      :src="detail.idNumberUrlRead"
                      :onerror="
                        () => {
                          detail.idNumberUrlRead = '';
                        }
                      "
                  /></span>
                  <span
                    ><img
                      :src="detail.idNumberOppositeRead"
                      :onerror="
                        () => {
                          detail.idNumberOppositeRead = '';
                        }
                      "
                  /></span>
                </div>
              </td>
            </tr>
            <tr>
              <td>年度工资表</td>
              <td>
                <el-upload
                  v-if="isEditing"
                  class="avatar-uploader"
                  :show-file-list="false"
                  v-model="detail.yearWagesOutside"
                  :on-change="(res, f) => changeImgeYear(res, f, item)"
                >
                  <img
                    v-if="detail.yearWagesOutsideRead"
                    :src="detail.yearWagesOutsideRead"
                    class="avatar"
                  />
                  <img :src="ImgOne" v-else class="avatar" />
                </el-upload>
                <span v-else
                  ><img
                    :src="detail.yearWagesOutsideRead"
                    :onerror="
                      () => {
                        detail.yearWagesOutsideRead = '';
                      }
                    "
                /></span>
              </td>
            </tr>
            <tr>
              <td>完税证明</td>
              <td>
                <el-upload
                  v-if="isEditing"
                  class="avatar-uploader"
                  :show-file-list="false"
                  v-model="detail.taxPaymentCertificate"
                  :on-change="(res, f) => changeImgeTax(res, f, item)"
                >
                  <img
                    v-if="detail.taxPaymentCertificateRead"
                    :src="detail.taxPaymentCertificateRead"
                    class="avatar"
                  />
                  <img :src="ImgOne" v-else class="avatar" />
                </el-upload>
                <span v-else
                  ><img
                    :src="detail.taxPaymentCertificateRead"
                    :onerror="
                      () => {
                        detail.taxPaymentCertificateRead = '';
                      }
                    "
                /></span>
              </td>
            </tr>

            <tr>
              <td>员工登记表</td>
              <td>
                <el-upload
                  v-if="isEditing"
                  class="avatar-uploader"
                  :show-file-list="false"
                  v-model="detail.employeeRegistration"
                  :on-change="(res, f) => changeImgeEmp(res, f, item)"
                >
                  <img
                    v-if="detail.employeeRegistrationRead"
                    :src="detail.employeeRegistrationRead"
                    class="avatar"
                  />
                  <img :src="ImgOne" v-else class="avatar" />
                </el-upload>
                <span v-else
                  ><img
                    :src="detail.employeeRegistrationRead"
                    :onerror="
                      () => {
                        detail.employeeRegistrationRead = '';
                      }
                    "
                /></span>
              </td>
            </tr>
          </table>
        </el-form>

        <div class="botton" v-if="!isEditing">
          <span @click="router.push('/applyList')">返回上一页</span>
        </div>
      </div>
    </div>
    <div class="submitBtn" v-if="isEditing">
      <el-button type="default" size="large" @click="toCancel">取消</el-button>
      <el-button type="primary" size="large" @click="submit">提交</el-button>
    </div>
  </div>
</template>
<style scoped lang="scss">
:deep(.el-input) {
  width: 250px !important;
}
:deep(.el-select) {
  width: 250px !important;
}
:deep(.el-cascader) {
  width: 250px !important;
}
.all {
  position: relative;
  background-image: url("../../../assets/security/bg.png");
  background-repeat: no-repeat;
  margin-top: -0.3rem;
  background-size: 100% 50%;
  padding-top: 1.25rem;
  background-color: #f9fbff;
  padding-bottom: 2rem;
}

.cont {
  width: 73rem;
  margin: 1rem auto 0 auto;
  background-color: #ffffff;
  padding: 1rem 1rem 2rem 1rem;
}

.page-title {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 1.125rem;
  padding-left: 0.625rem;
  font-size: 1rem;
  border-bottom: 1px solid #ebebeb;
  .btn-edit {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }
}
.page-title::before {
  content: '';
  width: 0.25rem;
  height: 1rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
  background: #C30A05;
  border-radius: 0.125rem;
}

.right h2 span {
  border-left: 4px solid #065bff;
  display: inline-block;
  height: 1rem;
  vertical-align: middle;
  margin-right: 0.87rem;
}
.right h2 {
  font-weight: 500;
  color: #333333;
  border-bottom: 1px solid #e8eaed;
  padding-bottom: 1.5rem;
  font-size: 1rem;
}
.formData h2 {
  position: relative;
  padding-bottom: 1.125rem;
  padding-left: 0.625rem;
  font-size: 1rem;
  border-bottom: 1px solid #ebebeb;
  font-size: 1rem;
}
.formData h2::before {
  content: '';
  width: 0.25rem;
  height: 1rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
  background: #C30A05;
  border-radius: 0.125rem;
}

table {
  border: 1px solid #e8eaed;
  border-collapse: collapse;
  margin: 2rem 0;
  width: 100%;
}

table td {
  border: 1px solid #e8eaed;
  color: #333333;
  font-size: 0.87rem;
  height: 3.43rem;
  line-height: 3.43rem;
  padding-left: 1.31rem;
}

table td span {
  background: #eef1f5;
  width: 12.5rem;
  height: 7.87rem;
  display: block;
  margin: 1rem 0;
  display: inline-block;
  margin-right: 3rem;
}

table td span img {
  width: 100%;
  height: 100%;
}

table td:nth-child(odd) {
  background-color: #fafafa;
}

table tr td img[src=""],
img:not([src]) {
  opacity: 0;
}
.botton {
  text-align: center;
  border-top: 1px solid #e8eaed;
  padding: 2.5rem 0 0 0;
  margin: 3.75rem 0 0 0;
}

.botton span {
  display: block;
  border: 1px solid #e8eaed;
  width: 7.12rem;
  height: 2.12rem;
  line-height: 2.12rem;
  margin: 0 auto;
}

.submitBtn {
  width: 100vw;
  height: 2.5rem;
  background-color: #fff;
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  box-shadow: 0px 6px 20px rgba(126, 119, 103, 0.15);
  .el-button {
    width: 10rem;
  }
}
.avatar-uploader {
  margin: 1rem 3.68rem 1rem 0;
  height: 7.68rem;
}
.avatar {
  width: 11.87rem;
  height: 7.68rem;
}
.del-emp {
  height: 57.88px;
  line-height: 57.88px;
  text-align: right;
  position: relative;
  .del-emp-btn {
    position: absolute;
    top: 0;
    right: 30px;
    cursor: pointer;
    color: #ff0000;
    display: flex;
    align-items: center;
    font-weight: 500;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
.add-emp {
  text-align: center;
  color: #065bff;
  font-weight: 600;
  span {
    cursor: pointer;
  }
}
</style>
