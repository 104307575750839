
import request from '@/utils/request'

export function login( data ){
	return request({
		url:'/loginDoor',
		method:"post",
		data
	})
}

export function register( data ){
	return request({
		url:'/register',
		method:"post",
		data
	})
}

// 获取登录验证码
export function captchaImage(data){
	return request({
		url:'/captchaImage',
		method:"get",
		data
	})
}

// 获取个人信息
export function getInfo(){
	return request({
		url:'/getInfo',
		method:"get",
	})
}

// 登录
export function logout(){
	return request({
		url:'/logout',
		method:"post",
	})
}
