import { createRouter, createWebHistory } from 'vue-router';
import Home from '../view/index.vue'
import Login from '../view/login/login.vue'
import Register from '../view/register/index.vue'
import Security from '../view/security/index.vue'
import BussiessPersonInfo from '../view/businessPersonInfo/index.vue'
import Letter from '../view/businessPersonInfo/letter/index.vue'
import CLetter from '../view/companyInfo/letter/index.vue'
import CompanyInfo from '../view/companyInfo/index.vue'
import PersonalCenter from '../view/personCenter/index.vue'

import BaseInfo from '../view/personCenter/baseInfo/index.vue'
import ApplyList from '../view/personCenter/applyList/index.vue'
import ProcessDetail from '../view/personCenter/progressDetails/index.vue'
import ProcessDetailEntri from '@/view/personCenter/progressDetailsForCompany/index.vue'
import DataList from '../view/personCenter/dataListForApply/index.vue'
import DataListCompany from '../view/personCenter/dataListForApplyCompany/index.vue'

const routes = [{
    path: '/',
    component: Home,
},{ 
    path: '/login',
    component: Login,
},{ 
    path: '/register',
    component: Register,
},{
    path: '/security',
    component: Security,
},{
    path: '/busInfo',
    component: BussiessPersonInfo,
},{
    path:'/busInfo/letter',
    component:Letter
},{
    path:'/company',
    component:CompanyInfo,
   },{
    path:'/company/CLetter',
    component:CLetter
},{
    path:'/personCenter',
    component:PersonalCenter,
    children:[{
        path:'/baseInfo',
        name:'基本信息',
        component:BaseInfo
    },{
        path:'/applyList',
        name:'我的申请',
        component:ApplyList
    },{
        path:'/pDetail',
        name:'贷款进度明细-个人',
        component:ProcessDetail
    },{
        path:'/pDetailEntri',
        name:'贷款进度明细-小微企业',
        component:ProcessDetailEntri
    }]
},{
    path:'/dataList',
    name:"贷款申请数据-个人",
    component:DataList
},{
    path:'/dataListCompany',
    name:"贷款申请数据-小微企",
    component:DataListCompany
}]

const router = createRouter({
    // history:createWebHistory(process.env.BASE_URL),
    history:createWebHistory(),
    routes
})

export default router