
<script setup>
    import { onMounted, reactive, ref, watch } from 'vue';
    import { ElMessage, ElMessageBox } from 'element-plus';
    import { categoryList, geCategoryList ,idTypeList, getDictList, personCateList} from '@/api/certificateList.js';
    import { useRouter } from 'vue-router';
    import { deletePersonalPartnerInfo, getIdentityDuplicationInThreee } from '@/api/index.js';

    const router = useRouter();
    let formLabelAlign = reactive([{
        partnerName: null,
        idNumber: null,
        partnerPhoneNumber: null,
        partnerCategory:null,
        partnerAddress:null,
        idType:null,
        partnerLoanAmount: null,
        partnerLoanTerm: null,
    }]);

    const props = defineProps(['idNumber', 'amountRaiseStatus']);
    const raiseFlag = ref('');
    watch(() => props.amountRaiseStatus, val => {
        raiseFlag.value = val;
    })

    const checkLoanAmount = (rule, value, callback) => {
      if (value > 30 && raiseFlag.value == '0') {
        callback(new Error("申请金额不能大于30万"));
      } else if (value > 33 && raiseFlag.value == '1') {
        callback(new Error("申请金额不能大于33万"));
      } else {
        callback();
      };
    };

    const checkAmount = (e) => {
        if(e.target.value > 30 && raiseFlag.value == '0') {
            ElMessage.error('申请金额不能大于30万');
            return false;
        } else if (e.target.value > 33 && raiseFlag.value == '1') {
            ElMessage.error('申请金额不能大于33万');
            return false;
        };
    };

    const checkIdNumber = (e) => {
        let id = e.target.value;
        let reg = /^([a-zA-Z0-9-]{1,18})$/;
        if (!id) return;
        if (!reg.test(e.target.value)) {
            ElMessage.error('身份证号码输入有误，请检查');
            return false;;
        };
        if (e.target.value == props.idNumber) {
            ElMessage.error('合伙人身份证不能与主体身份证相同');
            return false;
        };
        let arr = formLabelAlign;
        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr.length; j++) {
                if (arr[i].idNumber == arr[j].idNumber && i != j) {
                    ElMessage.error('已有相同的合伙人身份证号，请检查');
                    return false;
                };
            };
        };
    };

    const checkPhoneNumber = (e) => {
        let reg = /^1\d{10}$/;
        if (!reg.test(e.target.value) && !!e.target.value) {
            ElMessage.error('手机号输入有误，请检查');
            return false;
        };
    };

    const checkDateLimit = (e) => {
        let reg = /^\d{1,2}$/;
        let value = Number(e.target.value);
        if (!reg.test(value)) {
            ElMessage.error('贷款期限必须是1到2位数字')
            return false;
        };
        if ( value > 36 || value < 1) {
            ElMessage.error('贷款期限在1-36个月之间');
            return false;
        };
    };

    // 贷款期限
    const checkLoanTerm = (rule, value, callback) => {
      let num = Number(value)
      if ( num > 36 || num < 1) {
        callback(new Error("贷款期限在1-36个月之间"));
      } else {
        callback();
      }
    };

    const rules = reactive({
        idType:[{ required: true, message: '请选择证件类型', trigger: 'blur' }],
        idNumber: [ 
            { required: true, message: "请输入合伙人公民身份号码",trigger: 'blur' },
            {
                pattern: /^([a-zA-Z0-9-]{1,18})$/, 
                message: '请输入1-18位的数字和字母，支持中划线', 
                trigger: 'blur' 
            },
        ],
        partnerCategory: [{ required: true, message: "创业担保贷款人员类别不能为空", trigger: "blur" }],
        partnerName: [ 
            { required: true, message: "请输入合伙人姓名" },
            { required: true, pattern: /^.{1,150}$/, message: "合伙人姓名长度不正确" }
        ],
        loanAmount:[
            {required: true, message: '请输入申请金额',trigger: 'change'},
            { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: "申请金额格式不正确", trigger: "change" },
            { validator: checkLoanAmount, trigger: "change" }
        ],
        partnerPhoneNumber: [
            {required: true,message: '请输入紧急联系人电话',trigger: 'change'},
            {
                pattern: /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/, 
                message: '手机号码格式不正确', 
                trigger: ['change', 'blur'] 
            },
        ],
        partnerAddress: [{ required: false, pattern: /^.{1,150}$/, message: "合伙人住址长度不正确" }],
        partnerLoanTerm:[
          {required: true,message: '请输入贷款期限',trigger: 'change'},
          { pattern: /^\d{1,2}$/, message: "贷款期限必须是1到2位数字" },
          {validator:checkLoanTerm, trigger: "change" }
        ]

    });

    const addPanter = () => {
        formLabelAlign.push({
            partnerName: null,
            idNumber: null,
            partnerPhoneNumber: null,
            partnerCategory: null,
            partnerAddress: null,
            idType: null,
            partnerLoanAmount: null,
            partnerLoanTerm: null,

        });
    };

    // 删除
    const minus = (item,index) => {
        ElMessageBox.confirm('请问确定要删除吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(async() => {
            if (item.id) {
                let ids = item.id;
                let idsList = [];
                idsList.push(ids);
                await deletePersonalPartnerInfo(idsList).then((res) =>{
                    if (res.code == 200) {
                        ElMessage.success('删除成功');
                        formLabelAlign.splice(index,1);
                    } else {
                        ElMessage.error('删除失败');
                    };
                });
            } else {
                formLabelAlign.splice(index, 1);
            }
        }).catch((e) => {
            console.log(e,'this is error msg');
        });
    };

    const emit = defineEmits(['setSteps']);
    const ruleFormRef = ref();
    defineExpose({
        formLabelAlign
    });

  onMounted(() => {
    getDictList();
    geCategoryList();
  });

</script>

<template>
    <div class="box-right">
        <h2><span></span>创业人员基本信息</h2>
       
            <el-form
                label-position="top"
                label-width="100px"
                :model="formLabelAlign"
                :rules="rules"
                ref="ruleFormRef">
                <template v-for="(item,index) in formLabelAlign" :key="'box'+index">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="合伙人姓名"  :props="'formLabelAlign.'+index+'.partnerName'" :rules="rules.partnerName">
                                <el-input v-model="item.partnerName" placeholder="请输入合伙人姓名"/>
                            </el-form-item>
                            <el-form-item label="证件号码" :props="'formLabelAlign.'+index+'.idNumber'" :rules="rules.idNumber">
                                <el-input v-model="item.idNumber" placeholder="请输入证件号码" @blur="checkIdNumber"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="证件类型" :props="'formLabelAlign.'+index+'.idType'" :rules="rules.idType">
                                <el-select  placeholder="请选择证件类型" v-model="item.idType" :props="'formLabelAlign.'+index+'.idType'">
                                    <el-option  v-for="item in idTypeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="创业担保贷款合伙人人员类别" :props="'formLabelAlign.'+index+'.partnerCategory'" :rules="rules.partnerCategory">
                                <el-select  placeholder="请选择创业担保贷款合伙人人员类别" v-model="item.partnerCategory">
                                    <el-option  v-for="item in categoryList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="申请贷款金额" :props="item.partnerLoanAmount" :rules="rules.loanAmount">
                                <el-input v-model="item.partnerLoanAmount" placeholder="请输入申请贷款金额" @blur="checkAmount">
                                    <template #append>万元</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="合伙人电话" :props="'formLabelAlign.'+index+'.partnerPhoneNumber'" :rules="rules.partnerPhoneNumber">
                                <el-input v-model="item.partnerPhoneNumber" placeholder="请输入合伙人电话" @blur="checkPhoneNumber"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="合伙人地址" required>
                                <el-input v-model="item.partnerAddress" placeholder="请输入合伙人地址" :props="'formLabelAlign.'+index+'.partnerAddress'" :rules="rules.employeeIdNumber"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="贷款期限" required>
                                <el-input v-model="item.partnerLoanTerm" placeholder="请输入贷款期限" :props="'formLabelAlign.'+index+'.partnerLoanTerm'" :rules="rules.partnerLoanTerm" />
                                <template #append>个月</template>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class="add del">
                        <div class="btn" @click="minus(item,index)">- 删除合伙人</div>
                    </div>
                </template>
            </el-form>
       

        <div class="oper-list">
            <div class="add" @click="addPanter">+ 新增合伙人信息</div>
        </div>

    </div>
</template>

<style scoped lang="scss">
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
.avatar{
    width: 11.87rem;
    margin-right: 3.68rem;
    height: 7.68rem;
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 0 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}

.add{
    color: #065BFF;
    line-height: 4.37rem;
    text-align: center;
    font-weight: 600;
    width: 10rem;
    margin: 0 auto;
}
.del{
    border-bottom:1px solid #cccccc;
    width: 100%; 
    text-align: right;
    margin: 0 0 3rem 0;
    .btn {
        display: inline-block;
        cursor: pointer;
    }
}

.oper-list{
    text-align: center;
    .add {
        cursor: pointer;
    }
}
</style>