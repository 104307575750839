<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router'
import { 
    bankList,
    getBankList,
    getBankList1,
    guarantorsList,
    getGuarantorsList,
    getGuarantorsList1,
    idTypeList,
    getDictList,
    enterpriseTypeList,
    getenterpriseTypeList,
    entrepreneurList,
    getEntrepreneurList,
    deptList,
    getDeptList
} from '@/api/certificateList.js';
import { getEnterprisePortalDetail  } from '@/api/applyList.js';

const route = useRoute();

const formLabelAlign = reactive({
    unitName: null,
    enterpriseType: null,
    SOCode:null,
    socialCode: null,
    organizationCode:null,
    establishment: null,
    employees: null,
    businessScope: null,
    personnelCategory: null,
    initiate: null,
    idType: null,
    idNumber: null,
    representative: null,
    telephone: null,
    businessAddress: null,
    businessAddressDetails: null,
    registeredCapital: null,
    turnover: null,
    totalAssets: null,
    totalLiabilities: null,
    loanAmount: null,
    loanTerm: null,
    loanPurpose: null,
    guaranteeOrganizationName: null,
    bankId: null,
    exemptionGuarantee: null,
});

// 校验金额
const checkLoanAmount = (rule, value, callback) => {
    if (value > 500) {
        callback(new Error("申请金额不能大于500万"));
    } else {
        callback();
    }
};

// 贷款期限
const checkLoanTerm = (rule, value, callback) => {
    let num = Number(value)
    if ( num > 24 || num < 1) {
        callback(new Error("贷款期限在1-24个月之间"));
    } else {
        callback();
    }
};

const changeBusinessAddress = (e) => {
    getBankList1(e[2]);
    getGuarantorsList1(e[2]);
    formLabelAlign.bankId = null;
    formLabelAlign.guaranteeOrganizationName = null;
};

const rules = reactive({
    unitName: [{ required: true, message: '请输入企业名称', trigger: 'blur'}],
    enterpriseType: [{required: true,message: '请选择企业类型',trigger: 'change',},],
    SOCode: [
        {required: true, message:'请输入统一社会信用代码/组织机构代码',trigger:'blur'},
        { 
            pattern: /^([a-zA-Z0-9-]{9}|[a-zA-Z0-9-]{10}|[a-zA-Z0-9-]{18})$/, 
            message: '长度只能是9位、10位、18位的数字和字母，支持中划线', 
            trigger: 'blur' 
        },
    ],
    establishment: [{required: true, message:'请选择成立日期',trigger:'change'}],
    employees: [
        {required: true, message:'请输入员工总数',trigger:'change'},
        { pattern: /^\d{1,6}$/, message: "员工总数必须是1到6位数字" }
    ],
    businessScope: [{required: true, message:'请输入经营范围及项目',trigger:'change'}],
    personnelCategory: [{required: true,message: '请选择创业扶持人员类别',trigger: 'change',}],
    initiate: [{required: true,message: '请选择是否首次创业',trigger: 'change',}],
    telephone: [
        {required: false,message: '请输入小微企业法人代表人联系电话',trigger: 'change'},
        {
            pattern: /^((0\d{2,3}(-)?\d{7,8})|(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8})$/, 
            message: '手机号码格式不正确', 
            trigger: ['change', 'blur'] 
        },
    ],
    businessAddress: [{required: true,message: '请选择经营地址',trigger: 'change' }],
    guaranteeOrganizationName: [{ required: true,message: '请选择担保机构',trigger: 'change' }],
    bankId: [{ required: true,message: '请选择贷款银行',trigger: 'change' }],
    registeredCapital: [{ pattern: /^\d{1,8}(\.\d{1,2})?$/, message: "注册资本格式不正确", trigger: "blur" }],
    turnover: [{ pattern: /^\d{1,8}(\.\d{1,2})?$/, message: "当年营业额格式不正确", trigger: "blur" }],
    totalLiabilities: [
        { required: false, message: '请输入企业总负债', trigger: 'blur'},
        { pattern: /(^-?[0-9]{1,8}$)|(^-?[0-9]{1,8}[\.]{1}[0-9]{1,2}$)/, message: "企业总负债金额格式有误,最大8位数,最多保留2位小数", trigger: "blur" },
    ],
    totalAssets: [
      { pattern: /^\d{1,8}(\.\d{1,2})?$/, message: "企业资产总额格式不正确", trigger: "blur" },
    ],
    loanTerm: [
        { required: true,message: '请输入贷款期限',trigger: 'blur'},
        { pattern: /^\d{1,2}$/, message: "贷款期限必须是1到2位数字",trigger: 'blur' },
        {validator:checkLoanTerm, trigger: "blur" }
    ],
    loanAmount: [
        {required: true,message: '请输入申请金额',trigger: 'change'},
        { pattern: /^\d{1,5}(\.\d{1,2})?$/, message: "申请金额格式不正确", trigger: "blur" },
        { validator:checkLoanAmount, trigger: "blur" }
    ],
    idNumber: [
        {required: true,message: '请输入正确的小微企业法人代表人证件号码',trigger: 'blur'},
        { 
            pattern: /^([a-zA-Z0-9-]{1,18})$/, 
            message: '请输入1-18位的数字和字母，支持中划线', 
            trigger: 'blur' 
        },
    ],
    idType: [{required: true,message: '请选择小微企业法人代表人证件类型',trigger: 'blur'}],
    representative: [{required: true,message: '请输入小微企业法人代表人姓名',trigger: 'blur'}]
    
})

const loading = ref(false)
const ruleFormRef = ref();
const validPass = ref(false)
const submitTemporaryStorage = () =>{
    loading.value = true
    ruleFormRef.value.validate((valid) =>{
        if(valid){
            validPass.value = true
        }
    })
}

defineExpose({
    formLabelAlign,
    validPass,
    submitTemporaryStorage,
    ruleFormRef
})

onMounted(() => {
    let id = route.query.id;
    if (id) {
        getEnterprisePortalDetail(route.query.id).then(res => {
            if (res.code == '200') {
                let businessAddress = res.data.businessAddress;
                if (businessAddress) {
                    businessAddress = businessAddress.split(',');
                    changeBusinessAddress(businessAddress);
                }
            }
        })
    }
    
    getEnterprisePortalDetail();
    getDictList()
    getenterpriseTypeList()
    getEntrepreneurList()
    getDeptList()
})

</script>

<template>
    <div class="box-right">
        <h2><span></span>小微企业基本信息</h2>
        <el-form label-position="top" label-width="100px" :model="formLabelAlign" :rules="rules" ref="ruleFormRef">

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="企业名称" prop="unitName">
                        <el-input v-model="formLabelAlign.unitName" placeholder="请输入企业名称" />
                    </el-form-item>
                    <el-form-item label="统一社会信用代码/组织机构代码" prop="SOCode">
                        <el-input v-model="formLabelAlign.SOCode" placeholder="请输入代码" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="企业类型" prop="enterpriseType">
                        <el-select v-model="formLabelAlign.enterpriseType" placeholder="请选择企业类型">
                            <el-option v-for="item in enterpriseTypeList" :label="item.dictLabel" :value="item.dictValue" :key="item.id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="成立日期" prop="establishment">
                        <el-date-picker v-model="formLabelAlign.establishment" type="date" placeholder="请选择成立日期"
                            format="YYYY/MM/DD" value-format="YYYY-MM-DD">
                            <template #default="cell">
                                <div class="cell" :class="{ current: cell.isCurrent }">
                                    <span class="text">{{ cell.text }}</span>
                                </div>
                            </template>
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="员工总数" prop="employees">
                        <el-input v-model="formLabelAlign.employees" placeholder="请输入员工总数">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="经营范围及项目" prop="businessScope">
                        <el-input v-model="formLabelAlign.businessScope" maxlength="100" placeholder="请输入经营范围"
                            type="textarea" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="创业扶持人员类别" prop="personnelCategory">
                        <el-select v-model="formLabelAlign.personnelCategory" placeholder="请选择">
                            <el-option v-for="item in entrepreneurList" :label="item.dictLabel" :value="item.dictValue"  :key="item.id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小微企业法人代表人证件类型" prop="idType">
                        <el-select v-model="formLabelAlign.idType" placeholder="请选择证件类型">
                            <el-option v-for="item in idTypeList" :label="item.dictLabel" :value="item.dictValue"  :key="item.id"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小微企业法人代表人姓名" prop="representative">
                        <el-input v-model="formLabelAlign.representative" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="经营地址" prop="businessAddress">
                        <el-cascader v-model="formLabelAlign.businessAddress" :options="deptList"  placeholder="请选择经营地址"    
                            :props="{ value: 'id',label: 'label',children: 'children'}" style="width: 100%;" @change="changeBusinessAddress"/>
                    </el-form-item>

                    <el-form-item label="注册资本" prop="registeredCapital">
                        <el-input v-model="formLabelAlign.registeredCapital" placeholder="请输入注册资本">
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="企业资产总额" prop="totalAssets">
                        <el-input v-model="formLabelAlign.totalAssets" placeholder="请输入企业资产总额">
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="申请贷款金额" prop="loanAmount">
                        <el-input v-model="formLabelAlign.loanAmount" placeholder="请输入申请贷款金额">
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否首次创业" prop="initiate">
                        <el-select v-model="formLabelAlign.initiate" placeholder="请选择">
                            <el-option label="是" value="0" />
                            <el-option label="否" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小微企业法人代表人证件号码" prop="idNumber">
                        <el-input v-model="formLabelAlign.idNumber" placeholder="请输入请输入证件号码" />
                    </el-form-item>
                    <el-form-item label="小微企业法人代表人联系电话" prop="telephone">
                        <el-input v-model="formLabelAlign.telephone" placeholder="请输入电话号码" />
                    </el-form-item>
                    <el-form-item>
                        <div style="height:3.8rem;line-height:5.5rem;width:100%">
                            <el-input v-model="formLabelAlign.businessAddressDetails" placeholder="请输入详细地址" />
                        </div>
                    </el-form-item>
                    <el-form-item label="当年营业额" prop="turnover">
                        <el-input v-model="formLabelAlign.turnover" placeholder="请输入当年营业额">
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="企业总负债" prop="totalLiabilities">
                        <el-input v-model="formLabelAlign.totalLiabilities" maxlength="11" placeholder="请输入企业总负债">
                            <template #append>万元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="申请贷款期限" prop="loanTerm">
                        <el-input v-model="formLabelAlign.loanTerm" placeholder="请输入申请贷款期限"  :min="1" :max="24">
                            <template #append>月</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="申请贷款用途">
                        <el-input v-model="formLabelAlign.loanPurpose" maxlength="100" placeholder="请输入申请贷款用途"
                            type="textarea" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="担保机构" prop="guaranteeOrganizationName">
                        <el-select v-model="formLabelAlign.guaranteeOrganizationName" placeholder="请选择担保机构">
                            <el-option v-for="(item, index) in guarantorsList" :value="index" :label="item"  :key="index"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="贷款银行" prop="bankId">
                        <el-select v-model="formLabelAlign.bankId" placeholder="请选择贷款银行">
                            <el-option v-for="(item, index) in bankList" :value="index" :label="item"  :key="index"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item label="是否免除反担保">
                        <el-select v-model="formLabelAlign.exemptionGuarantee" placeholder="请选择是否免除反担保">
                            <el-option label="是" value="0" />
                            <el-option label="否" value="1" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<style scoped>
.box-right {
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
</style>

<style>
.el-input-group__append,
.el-input-group__prepend {
    background-color: #ffffff !important;
    margin-left: -5px;
}
</style>