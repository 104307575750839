<script setup>
    import { useRouter } from 'vue-router'
    import {watch, onMounted, ref} from 'vue';
    const router = useRouter()
    import  axios from 'axios'
    import { uploadImgUrlPost,headers } from '@/api/upload.js'
    import { downloadByFileId } from "@/api/index.js";
    import { getToken } from "@/utils/auth";
    import { ElMessage } from 'element-plus'


    const props = defineProps({
        detailInfo: {
            type: Object,
            default: ''
        },
        steps: {
            type: Number,
            default: 0
        }
    })

    const uploadHeaders =  {
        Authorization: "Bearer " + getToken(),
        'Content-Type':'multipart/form-data'
    }

    const formLabelAlign = reactive({
        businessUrl: null,
        businessUrlRead: null,
        idNumberUrl: null,
        idNumberUrlRead: null,
        guaranteeCertificateUrl:null,
        guaranteeCertificateUrlRead:null,
        householdPhoto: null,
        householdPhotoRead: null,
        marriageCertificateUrl:null,
        marriageCertificateUrlRead:null,
        idNumberReverseUrl:null,
        idNumberReverseUrlRead:null,
        spouseIdNumberUrl:null,
        spouseIdNumberUrlRead:null,
        spouseIdNumberReverseUrl:null,
        spouseIdNumberReverseUrlRead:null,
        documentClass: [],
        documentClassRead: []
    })

    watch(() => props.steps, value => {
        if (value == 4) {
            console.log('在这里展示弹窗');
            console.log(formLabelAlign)
            if (formLabelAlign.businessUrl) {
              downloadByFileId(formLabelAlign.businessUrl).then(response => {
                formLabelAlign.businessUrlRead = URL.createObjectURL(response);
              })
            }
          if (formLabelAlign.idNumberUrl) {
            downloadByFileId(formLabelAlign.idNumberUrl).then(response => {
              formLabelAlign.idNumberUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.idNumberReverseUrl) {
            downloadByFileId(formLabelAlign.idNumberReverseUrl).then(response => {
              formLabelAlign.idNumberReverseUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.guaranteeCertificateUrl) {
            downloadByFileId(formLabelAlign.guaranteeCertificateUrl).then(response => {
              formLabelAlign.guaranteeCertificateUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.householdPhoto) {
            downloadByFileId(formLabelAlign.householdPhoto).then(response => {
              formLabelAlign.householdPhotoRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.spouseIdNumberUrl) {
            downloadByFileId(formLabelAlign.spouseIdNumberUrl).then(response => {
              formLabelAlign.spouseIdNumberUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.spouseIdNumberReverseUrl) {
            downloadByFileId(formLabelAlign.spouseIdNumberReverseUrl).then(response => {
              formLabelAlign.spouseIdNumberReverseUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.marriageCertificateUrl) {
            downloadByFileId(formLabelAlign.marriageCertificateUrl).then(response => {
              formLabelAlign.marriageCertificateUrlRead = URL.createObjectURL(response);
            })
          }
          if (formLabelAlign.documentClass) {
            formLabelAlign.documentClass.forEach(item => {
                downloadByFileId(item).then(response => {
                formLabelAlign.documentClassRead.push(URL.createObjectURL(response));
              })
            })
          }
        }
        formLabelAlign.value = {
            businessUrl: null,
            businessUrlRead: null,
            idNumberUrl: null,
            idNumberUrlRead: null,
            guaranteeCertificateUrl: null,
            guaranteeCertificateUrlRead: null,
            householdPhoto: null,
            householdPhotoRead: null,
            marriageCertificateUrl: null,
            marriageCertificateUrlRead: null,
            idNumberReverseUrl: null,
            idNumberReverseUrlRead: null,
            spouseIdNumberUrl: null,
            spouseIdNumberUrlRead: null,
            spouseIdNumberReverseUrl: null,
            spouseIdNumberReverseUrlRead: null,
            documentClass: [],
            documentClassRead: []
        };
        businessUrl.value = null;
        businessUrlRead.value = null;
        idNumberUrl.value = null;
        idNumberUrlRead.value = null;
        guaranteeCertificateUrl.value = null;
        guaranteeCertificateUrlRead.value = null;
        householdPhoto.value = null;
        householdPhotoRead.value = null;
        marriageCertificateUrl.value = null;
        marriageCertificateUrlRead.value = null;
        idNumberReverseUrl.value = null;
        idNumberReverseUrlRead.value = null;
        spouseIdNumberUrl.value = null;
        spouseIdNumberUrlRead.value = null;
        spouseIdNumberReverseUrl.value = null;
        spouseIdNumberReverseUrlRead.value = null;
        documentClass.value = [];
        documentClassRead.value = [];
    })

    import ImgOne from '@/assets/stepTwo/id-1.png'
    import ImgTwo from '@/assets/stepTwo/id-2.png'
    const businessUrl = ref('')
    const businessUrlRead = ref('')
    const guaranteeCertificateUrl = ref('')
    const guaranteeCertificateUrlRead = ref('')
    const householdPhoto = ref('')
    const householdPhotoRead = ref('');
    const spouseIdNumberUrl = ref('')
    const spouseIdNumberUrlRead = ref('')
    const marriageCertificateUrl = ref('')
    const marriageCertificateUrlRead = ref('')
    const idNumberUrl = ref('');
    const idNumberUrlRead = ref('');
    const idNumberReverseUrlRead = ref('');
    const documentClass = ref([]);
    const documentClassRead = ref([]);

    // 图片上传
    const changeImge = (res,f) => {
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        businessUrlRead.value = aimg

        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                formLabelAlign.businessUrl = res.data.msg
            }
        })
    }

    // 图片上传 本人身份证正
    const changeImgeIdm = (res,f) =>{

        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        idNumberUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                    formLabelAlign.idNumberUrl =  res.data.msg
            }
        })
    }

    const idNumberReverseUrl = ref('')
     // 图片上传 本人身份证 反面
     const changeImgeReverseUrl = (res,f) =>{

        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }
        let aimg = URL.createObjectURL(res.raw)
        idNumberReverseUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                formLabelAlign.idNumberReverseUrl =  res.data.msg
            }
        })
    }

    // 图片上传 担保证明
    const changeImgeGua = (res,f) =>{
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }
        let aimg = URL.createObjectURL(res.raw)
        guaranteeCertificateUrlRead.value = aimg

        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                formLabelAlign.guaranteeCertificateUrl =  res.data.msg
            }
        })
    }

    // 图片上传 户口本照片
    const changeImgeHouse = (res,f) =>{
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        householdPhotoRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                formLabelAlign.householdPhoto =  res.data.msg
            }
        })
    }

    // 图片上传 配偶身份证正
    const changeImgeSpouse = (res,f) =>{
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        spouseIdNumberUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        console.log(spouseIdNumberUrlRead.value,'spouseIdNumberUrlRead.value')

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                formLabelAlign.spouseIdNumberUrl =  res.data.msg
            }
        })
    }

    // 图片上传 配偶身份证反
    const spouseIdNumberReverseUrl = ref('')
    const spouseIdNumberReverseUrlRead = ref('')
    const changeImgeSpouReverseUrl = (res,f) =>{
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        spouseIdNumberReverseUrlRead.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost, formData, { headers:uploadHeaders }).then(res => {
            if(res.data.code == 200){
                formLabelAlign.spouseIdNumberReverseUrl =  res.data.msg
            }
        })
    }

    // 图片上传 配偶结婚证照片
    const changeImgeMar = (res,f) =>{
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        marriageCertificateUrlRead.value = aimg
        const file = f[0];
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost, formData, { headers:uploadHeaders }).then(res => {
            formLabelAlign.marriageCertificateUrl =  res.data.msg
        })
    }

    const documentClassArr = ref([]);
    const changeImgePerType = (res,f) => {
        let type = res.raw.name.substring(',').split('.')[1]
        let size = res.raw.size / 1024 / 1024

        if(size > 5 ){
            ElMessage.error('文件超出大小,请重新选择')
            return false
        }

        if(type != 'jpg' && type != 'png'){
            ElMessage.error('文件格式错误,请重新选择')
            return false
        }

        let aimg = URL.createObjectURL(res.raw)
        formLabelAlign.documentClassRead.push(aimg)
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost, formData,{ headers:uploadHeaders }).then(res => {
            if(res.data.code == 200){
                console.log("++++++++++++++++++++++")
                let arr = formLabelAlign.documentClass;
                arr.push(res.data.msg);
                formLabelAlign.documentClass = arr;
                console.log('documentClass', formLabelAlign.documentClass)
            }
        })
    }

    const delImg = (index) => {
        formLabelAlign.documentClass.splice(index, 1);
        formLabelAlign.documentClassRead.splice(index, 1);

    }

    defineExpose({
        formLabelAlign
    })

    const documentClassS = ref("");

    // 类型提示消息
    const documentClassf = () => {
      console.log("formLabelAlign",formLabelAlign);
      // 开启弹窗
      if (formLabelAlign.loanPersonCategory === "01") {
        documentClassS.value = "";
      }
      else if (formLabelAlign.loanPersonCategory === "02") {
        documentClassS.value = "化解过剩产能企业工作证明（包括社保缴费记录、劳动合同、解除劳动合同通知\n" +
            "书等），或化解过剩产能企业出具证明"
      }
      else if (formLabelAlign.loanPersonCategory === "03") {
        documentClassS.value = "就业困难人员（含残疾人）相关证明材料：通过“湖北智慧就业服务\n" +
            "平台”查验就业困难人员登记信息（残疾人提交残疾证）";
      }
      else if (formLabelAlign.loanPersonCategory === "04") {
        documentClassS.value = "需要提供复员、转业、退役等证件(提供其中一项即可)";
      }
      else if (formLabelAlign.loanPersonCategory === "05") {
        documentClassS.value = "需要提供司法部门出具的刑满释放相关材料";
      }
      else if (formLabelAlign.loanPersonCategory === "06") {
        documentClassS.value = "需要提供普通高等院校专科（高职高专）及以上学历毕业证书或者（境）外学历学位认证书（以上证书可由学信网学历证明代替）";
      }
      else if (formLabelAlign.loanPersonCategory === "07") {
        //脱贫人口
        documentClassS.value = "";
      }
      else if (formLabelAlign.loanPersonCategory === "08") {
        documentClassS.value = "需要提供营业执照材料,在第三方平台（网络）实名注册信息或自主创办的平台（网站）注册信息，以及平台（网站）近 3 个月交易记录";
      }
      else if (formLabelAlign.loanPersonCategory === "09") {
        //返乡创业农民工
        documentClassS.value = "需要提供户口簿，或土地承包经营权证等，外\n" +
            "地就业、创业、就学、服兵役等佐证材料（不论户口是否迁出，\n" +
            "原籍在本县（市/区）行政区域范围的人员回到本县（市/区）等佐证材料";
      }
      else if (formLabelAlign.loanPersonCategory === "10") {
        //农村自主创业农民
        documentClassS.value = "需要提供户口簿，或土地承包经营权证";
      }
    };
    documentClassf();
</script>

<template>
    <div class="box-right">
        <h2><span></span>相关证件材料</h2>
        <el-form
            label-position="top"
            label-width="100px"
            :model="formLabelAlign"
            ref="ruleFormRef"
            :rules="rules">


            <div class="title"><i class="red-tip">*</i>营业执照 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="" prop="businessUrl">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImge"
                    :auto-upload="true"
                    v-model="formLabelAlign.businessUrl"
                    accept=".jpg,.png">
                    <img v-if="businessUrlRead || formLabelAlign.businessUrlRead" :src="businessUrlRead?businessUrlRead:formLabelAlign.businessUrlRead" class="avatar"/>
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>


            <div class="title"><i class="red-tip">*</i>本人身份证正反面 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <div class="id-img">
                <el-form-item label="">
                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeIdm"
                        :auto-upload="true"
                        v-model="formLabelAlign.idNumberUrl"
                        accept=".jpg,.png">
                        <img v-if="idNumberUrlRead || formLabelAlign.idNumberUrlRead" :src="idNumberUrlRead?idNumberUrlRead:formLabelAlign.idNumberUrlRead" class="avatar"/>
                        <img :src="ImgOne" v-else class="avatar"/>
                    </el-upload>

                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeReverseUrl"
                        :auto-upload="true"
                        v-model="formLabelAlign.idNumberReverseUrlRead"
                        accept=".jpg,.png">
                        <img v-if="idNumberReverseUrlRead || formLabelAlign.idNumberReverseUrlRead" :src="idNumberReverseUrlRead?idNumberReverseUrlRead:formLabelAlign.idNumberReverseUrlRead" class="avatar"/>
                        <img :src="ImgTwo" v-else class="avatar"/>
                    </el-upload>

                </el-form-item>
            </div>

            <div class="title"><i class="red-tip">*</i>担保证明 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeGua"
                        :auto-upload="true"
                        v-model="formLabelAlign.guaranteeCertificateUrl"
                        accept=".jpg,.png">
                        <img v-if="guaranteeCertificateUrlRead || formLabelAlign.guaranteeCertificateUrlRead" :src="guaranteeCertificateUrlRead?guaranteeCertificateUrlRead:formLabelAlign.guaranteeCertificateUrlRead" class="avatar"/>
                        <div class="normal-avatar" v-else>
                            <img src="@/assets/stepFour/icon_add.png" />
                        </div>
                </el-upload>
            </el-form-item>

            <div class="title"><i class="red-tip">*</i>本人户口本照片 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImgeHouse"
                    :auto-upload="true"
                    v-model="formLabelAlign.householdPhoto"
                    accept=".jpg,.png">
                    <img v-if="householdPhotoRead || formLabelAlign.householdPhotoRead" :src="householdPhotoRead?householdPhotoRead:formLabelAlign.householdPhotoRead" class="avatar"/>
                    <div class="normal-avatar" v-else>
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
            </el-form-item>

            <template v-if="props.detailInfo.maritalStatus == '1'">
                <div class="title"><i class="red-tip">*</i>配偶身份证正反面照片（已婚必传） <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
                <div class="id-img">
                    <el-form-item label="">
                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            :headers="headers"
                            :on-change="changeImgeSpouse"
                            :auto-upload="true"
                            v-model="formLabelAlign.spouseIdNumberUrl"
                            accept=".jpg,.png">
                            <img v-if="spouseIdNumberUrlRead || formLabelAlign.spouseIdNumberUrlRead" :src="spouseIdNumberUrlRead?spouseIdNumberUrlRead:formLabelAlign.spouseIdNumberUrlRead" class="avatar"/>
                            <img :src="ImgOne" v-else class="avatar"/>
                        </el-upload>

                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            :headers="headers"
                            :on-change="changeImgeSpouReverseUrl"
                            :auto-upload="true"
                            v-model="formLabelAlign.spouseIdNumberReverseUrl"
                            accept=".jpg,.png">
                            <img v-if="spouseIdNumberReverseUrlRead || formLabelAlign.spouseIdNumberReverseUrlRead" :src="spouseIdNumberReverseUrlRead?spouseIdNumberReverseUrlRead:formLabelAlign.spouseIdNumberReverseUrlRead" class="avatar"/>
                            <img :src="ImgTwo" v-else class="avatar"/>
                        </el-upload>
                    </el-form-item>
                </div>




                <div class="title"><i class="red-tip">*</i>配偶结婚证照片（已婚必传） <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
                <el-form-item label="">
                    <el-upload
                        class="avatar-uploader"
                        :show-file-list="false"
                        :headers="headers"
                        :on-change="changeImgeMar"
                        :auto-upload="true"
                        v-model="formLabelAlign.marriageCertificateUrl"
                        accept=".jpg,.png">
                        <img v-if="marriageCertificateUrlRead || formLabelAlign.marriageCertificateUrlRead" :src="marriageCertificateUrlRead?marriageCertificateUrlRead:formLabelAlign.marriageCertificateUrlRead" class="avatar"/>
                        <div class="normal-avatar" v-else>
                            <img src="@/assets/stepFour/icon_add.png" />
                        </div>
                    </el-upload>
                </el-form-item>
            </template>

            <div class="title">人员类别相关证件照片(需要提供复员、转业、退役等证件，提供其中一项即可)  <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
            <el-form-item label="">
                <el-upload
                    class="avatar-uploader"
                    :show-file-list="false"
                    :headers="headers"
                    :on-change="changeImgePerType"
                    :auto-upload="false"
                    multiple
                    :file-list="fileList"
                    accept=".jpg,.png">
                    <div class="normal-avatar">
                        <img src="@/assets/stepFour/icon_add.png" />
                    </div>
                </el-upload>
                <template v-if="formLabelAlign.documentClassRead && formLabelAlign.documentClassRead.length > 0">
                    <div class="avatar img-avatar" v-for="(item, index) in formLabelAlign.documentClassRead" :key="index">
                        <el-image :src="item" :preview-src-list="formLabelAlign.documentClassRead" class="avatar img-avatar"></el-image>
                        <div class="img-del" @click="delImg(index)">×</div>
                    </div>
                </template>
            </el-form-item>
        </el-form>
    </div>
</template>

<style scoped lang="scss">
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}
.avatar{
    width: 11.87rem;
    height: 7.68rem;
    /* margin-right: 3.68rem; */
}
.normal-avatar {
    width: 7.68rem;
    height: 7.68rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8EAED;
    img {
        width: 3rem;
        height: 3rem;
    }
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 1rem 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}

.upload{
    width: 7.5rem;
    height: 7.5rem;
    text-align: center;
    line-height: 7.5rem;
    background-color: #E8EAED;
    font-size: 2rem;
}

.red-tip{
    color: red;
    font-style: normal;
    margin-right: 0.2rem;
}
.avatar-uploader {
    margin-right: 3.68rem;
    margin-bottom: 2rem;
}
.img-avatar {
    margin-right: 1rem;
    position: relative;
    margin-bottom: 2rem;
}

.img-del {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    text-align: center;
    line-height: 1.5rem;
    color: #fff;
}
</style>
