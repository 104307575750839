<script setup lang="ts">
    import { onMounted, reactive, ref,watchEffect } from 'vue'
    import { ElMessage,ElMessageBox, type FormRules,type UploadProps  } from 'element-plus'
    import { dictList,deleteEntrepreneurDoorDtoPerson } from '@/api/index.js'
    import { useRouter } from 'vue-router'

    import  axios from 'axios'
    import { uploadImgUrlPost,headers } from '@/api/upload.js'

    import ImgOne from '@/assets/stepTwo/id-1.png'
    import ImgTwo from '@/assets/stepTwo/id-2.png'
    import { getToken } from "@/utils/auth";

    const imageUrl = ref('')
    const imageUrlRever = ref('')
    const router = useRouter()

    const uploadHeaders =  {
        Authorization: "Bearer " + getToken(),
        'Content-Type':'multipart/form-data'
    }

    const rules = reactive<FormRules>({
        employeeName: [
            {required: true, message: "请输入姓名",trigger: 'blur' },
            {required: true, pattern: /^.{1,150}$/, message: "姓名长度必须在1到150之间",trigger: 'blur' }
        ],
        idType: [{required: true,message: '请选择证件类型',trigger: 'change'}],
        employeeIdNumber: [
            { required: true, message: "请输入员工身份号码",trigger: 'blur'  },
            {
                pattern: /^([a-zA-Z0-9-]{1,18})$/,
                message: '请输入1-18位的数字和字母，支持中划线',
                trigger: 'blur'
            },
        ],
    })

    let list= reactive([{
        employeeName:null,
        employeeIdNumber:null,
        idType:null,
        idNumberUrl:null,
        idNumberUrlRead:null,
        idNumberOppositeRead:null,
        idNumberOpposite:null
    }])

    const add = () =>{
        list.push({
            employeeName:null,
            employeeIdNumber:null,
            idType:null,
            idNumberUrl:null,
            idNumberUrlRead:null,
            idNumberOppositeRead:null,
            idNumberOpposite:null
        })
    }

    // 图片上传
    const changeImge = (res,f,item) =>{
        let aimg = URL.createObjectURL(res.raw)
        imageUrl.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)
        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                item.idNumberUrl = res.data.msg
                item.idNumberUrlRead = aimg
            }
        })
    }
    // 图片删除
    const deleteImge = (item:any) =>{
        item.idNumberUrl = ""
    }
    // 图片上传 身份证反面
    const changeImgeRever = (res,f,item) =>{
        let aimg = URL.createObjectURL(res.raw)
        imageUrlRever.value = aimg
        let formData = new FormData()
        formData.append('file',res.raw)

        axios.post(uploadImgUrlPost,formData,{headers:uploadHeaders}).then(res =>{
            if(res.data.code == 200){
                item.idNumberOpposite =res.data.msg
                item.idNumberOppositeRead = aimg
            }
        })
    }

     // 获取证件类型
     const idTypeList:any= ref([])
    const getDictList = async() =>{
         await dictList({dictType:'id_type'}).then((res:any) =>{
            if(res.code == 200){
                idTypeList.value = res.rows
            }
         })
    }

    // 删除员工
    const minus = (item,index) =>{
        ElMessageBox.confirm('请问确定要删除吗?','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(async() => {
                if(item.id){
                    let ids = item.id
                    let idsList:any = []
                    idsList.push(ids)

                    await deleteEntrepreneurDoorDtoPerson(idsList).then((res:any) =>{
                        if(res.code == 200){
                            ElMessage.success('删除成功')
                            list.splice(index,1)
                        }else{
                            ElMessage.error('删除失败')
                        }
                    })
            }else{
                list.splice(index,1)
            }

        }).catch((e) => {
            console.log(e,'this is error msg')
        })
    }

    // 校验 身份证号码不能跟第一步输入的证件号码一样
    const getIdNumFromOne = ref(0)
    const checkoutIdNumber = (e) =>{
        if(e.target.value){
            let reg = /^([a-zA-Z0-9-]{1,18})$/;
            if(e.target.value == getIdNumFromOne.value){
                ElMessage.error('员工身份证不能与主体身份证相同')
                return false
            }

            if(!reg.test(e.target.value)){
                ElMessage.error('身份证号码输入有误，请检查')
                return false
            }
        }
    }

    defineExpose({
        list
    })

    const props = defineProps(['idNumber'])
    onMounted(() =>{
        getDictList()
        setTimeout(() =>{
            getIdNumFromOne.value = props.idNumber
        },1500)

    })

</script>

<template>
    <div class="box-right">
        <h2><span></span>创业人员基本信息</h2>
        <el-form
            label-position="top"
            label-width="100px"
            :rules="rules"
            :model="list">
            <template  v-for="(item,index) in list" :key="'box'+index">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="员工姓名" :props="'list.'+index+'.employeeName'" :rules="rules.employeeName">
                            <el-input v-model="item.employeeName" placeholder="请输入员工姓名"/>
                        </el-form-item>

                        <el-form-item label="员工证件号码" :props="'list.'+index+'.employeeIdNumber'" :rules="rules.employeeIdNumber">
                            <el-input  v-model="item.employeeIdNumber"  placeholder="请输入员工证件号码" @blur="checkoutIdNumber"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证件类型" :props="'list.'+index+'.idType'" :rules="rules.idType">
                            <el-select  placeholder="请选择证件类型" v-model="item.idType">
                                <el-option  v-for="item in idTypeList" :label="item.dictLabel" :value="item.dictValue" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="title">上传员工身份证正反面照片 <span>支持格式：jpg、png，单个文件不能超过5MB</span></div>
                <div class="id-img">

                    <el-form-item label="">
                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            :headers="headers"
                            :on-change="(res,f) => changeImge(res,f,item)"
                            :on-remove="() => deleteImge(item)"
                            :auto-upload="true"
                            v-model="item.idNumberUrl">
                                <img v-if="item.idNumberUrlRead" :src="item.idNumberUrlRead" class="avatar" />
                                <img :src="ImgOne" v-else class="avatar"/>
                        </el-upload>

                        <el-upload
                            class="avatar-uploader"
                            :show-file-list="false"
                            :headers="headers"
                            :on-change="(res,f) => changeImgeRever(res,f,item)"
                            :auto-upload="true"
                            v-model="item.idNumberOpposite">
                            <img v-if="item.idNumberOppositeRead" :src="item.idNumberOppositeRead" class="avatar" />
                            <img :src="ImgTwo" v-else class="avatar"/>
                        </el-upload>
                    </el-form-item>
                </div>
                <div class="add del" @click="minus(item,index)">- 删除员工信息</div>
            </template>
        </el-form>
        <div class="oper-list">
            <div class="add" @click="add">+ 新增员工信息</div>
        </div>
    </div>
</template>

<style scoped>
.box-right{
    background-color: #ffffff;
    padding: 1rem 1rem 2rem;
    width: 80%;
}

.line{
    border-top: 1px solid #E8EAED;
    margin: 3rem 0;
}

.avatar{
    width: 11.87rem;
    height: 7.68rem;
    margin-right: 3.68rem;
}

.title{
    color: #333333;
    font-size: 0.87rem;
    margin: 0 0 0.62rem 0;
}
.title span{
    color: #666666;
    font-size: 0.75rem;
}
.id-img{
    border: 1px solid #E8EAED;
    padding: 2.5rem 0 0 2.5rem;
}

.add{
    color: #065BFF;
    line-height: 4.37rem;
    text-align: center;
    font-weight: 600;
    width: 10rem;
    margin: 0 auto;
}
.del{
    border-bottom:1px solid #cccccc;
    width: 100%;
    text-align: right;
    margin: 0 0 3rem 0
}
.el-form{
    margin-bottom: 2rem;
}

.oper-list{
    text-align: center;
}
</style>
