<script setup>
import { useRouter,useRoute } from 'vue-router'
import IconUser from '@/assets/icon_user.png'
import {ref,watchEffect } from 'vue';
import { logout } from '@/api/login.js'
import { removeToken } from '@/utils/auth'
import Cookies from "js-cookie";
import { ElMessage, ElMessageBox } from 'element-plus'

const router = useRouter()
const route = useRoute()
const userInfo = ref()
// 退出登录
const logOut = () =>{
    ElMessageBox.confirm('请问确定要退出吗?','提示',{
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
        logout().then((res) =>{
        if(res.code === 200){
            router.push({path:'/login'})
            removeToken("token");
            removeToken("user");
            removeToken("roles");
            removeToken("Admin-Token_institution")
        }
    }).catch((e) => {
            ElMessage({
                type: 'error',
                message: e.message,
            })
        })
    })
}

const goCenter = () =>{
    router.push({path:'/baseInfo'})
}
const currentRoute = ref('')
watchEffect(() =>{
    currentRoute.value = router.currentRoute.value.fullPath;
    if (Cookies.get("user")) {
        userInfo.value = JSON.parse(Cookies.get("user"))
    } else {
        userInfo.value = null;
    }
})
</script>
<template>

<header>
        <div class="block_1 flex-row">
            <div>
                <div class="box_1">
                    <img src="/src/assets/login/img-logo-1.png" alt="">
                </div>
                <span :class="`text_1 ${ route.path == '/'?'':'noPointer'}`" @click="router.push('/')">湖北省创业担保贷款门户系统</span>
            </div>

            <template  v-if="currentRoute == '/login' && userInfo">
                <div></div>
            </template>
            <template v-else>
                <div v-if="!userInfo?.userName">
                    <a class="login" @click="router.push('/login')">登录</a>
                </div>

                <div class="login" v-else>
                    <span class="text_2">
                        <img :src="userInfo?.avatar ? userInfo?.avatar : IconUser"/>
                        {{userInfo?.userName}}
                    </span>
                    <span class="text_3" @click="goCenter">
                        <img src="/src/assets/index/ic_person.png" alt="">
                        个人中心
                    </span> 
                    <span class="text_4" @click="logOut">
                        <img src="/src/assets/index/ic_exit.png" />
                        退出
                    </span>
                </div>
            </template>
        </div>
      </header>
</template>

<style scoped lang="scss">
header a.login {
    background-color: #C30A05;
    color: white;
    font-size: 0.8rem;
    width: 5rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 0;
    cursor: pointer;
    border-radius: 2px;
}

header{
    height: 5.25rem;
    line-height: 5.25rem;
    font-size: 1.25rem;
}

.login {
    display: flex;
    align-items: center;
}

.block_1 {
  background-color: rgba(255, 255, 255, 1);
  justify-content: space-between;
  display: flex;
  width: 73.75rem;
  margin: 0 auto;
}

.text_1{
    font-size: 1.25rem;
    color: #333333;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
}
.noPointer{
    cursor: pointer;
}

.box_1{
    width:2.12rem;
    height:2.12rem;
    background-color: #C30A05;
    border-radius:50%;
    overflow: hidden
    img {
        width: 100%;
        height: 100%;
    }
}
.box_1 ,.text_1{
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.5rem;
}
.text_2,.text_3{
    font-size: 0.75rem;
    margin-left: 2.5rem;
    cursor: pointer;
}
.text_3{
    color: #C30A05;
    display: inline-flex;
    align-items: center;

    img {
        width: 0.75rem;
        height: 0.875rem;
        margin-right: 0.25rem;
    }

}

.text_2 img{
    vertical-align:middle;
    width:1.87rem;
    height:1.87rem;
    margin-right:0.62rem
}
.text_4 {
    width: 5rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: #fff;
    background-color: #C30A05;
    border-radius: 2px;
    margin-left: 3.125rem;
    cursor: pointer;
    img {
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.25rem;
    }
}
</style>